import React, { useEffect, useState } from "react";
import { Jeux, GameMap } from "../api/data";
import ContinuousAudioPlayer from "../components/ContinuousAudioPlayer";
import GameBoardUnit from "./GameBoardUnit";
import { useSelector } from "react-redux";
import { selectArticleValidated } from "../features/articleValidatedSlice";

function GameBoard() {
    const [loading, setLoading] = useState(false);
    const [buttonActivated, setButtonActivated] = useState(false);
    const articleValidated = useSelector(selectArticleValidated);
   

    useEffect(()=>{
   
    },[])

	return (
		<div>
			{loading && <p>Chargement .... </p>}
			<ContinuousAudioPlayer />
			<div className="flex flex-col">
                
				<div className="flex justify-center font-bold  text-white border border-white  text-base sm:text-xl rounded-md py-2">
					Vous pouvez rejouer les Sessions déjà validées. Pour les sessions non validées, cliquez sur la session  
				</div>
                <div  className="grid md:grid-cols-4 lg:grid-cols-5 gap-1 ">
                    {GameMap&&GameMap.map((data)=>(<>
                    <div key={data.id} >
                        <GameBoardUnit 
                        id={data.id} 
                        jeuPosition={data.jeuPosition}  
                        jeuName={data.jeuName}  
                        stagImgSrc={data.stagImgSrc}
                        buttonActivated={false}
                        />
                    </div>
                    </>))}
                </div>
			</div>
		</div>
	);
}

export default GameBoard;
