import React, { useEffect, useState } from "react";
import { Jeux, GameMap } from "../api/data";
import { Toaster, toast } from "react-hot-toast";
import Loading from "../img/myloading.gif";
import Load from "../img/bible1.gif";
import "semantic-ui-css/semantic.min.css";
import Celebrate from "../lotties/celebrations.json";
import ContinuousAudioPlayer from "../components/ContinuousAudioPlayer";
import {
	Timestamp,
	addDoc,
	collection,
	doc,
	getDocs,
	getFirestore,
	setDoc,
} from "firebase/firestore";
import { Button, Form } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { auth, db } from "../firebase";
import MyGame from "../components/MyGame";
import { motion } from "framer-motion";
import Unhappy from "../lotties/unhappy.json";
import Lottie from "lottie-react";
import Modal from "../components/Modal";
import { selectOpenArticle } from "../features/articleSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const initialState3 = {
	jeuNiveau: "",
	jeuTitre: "",
};

function Dashboard() {
	const [loading, setLoading] = useState(false);
	const [gameBoard, setGameBoard] = useState([]);
	const [isMainData, setMainData] = useState([]);

	const selectedArticle = useSelector(selectOpenArticle);
	const navigate = useNavigate();

	////conditioning variable for suivant to create palmares
	const [data3, setData3] = useState(initialState3);
	const { jeuNiveau, jeuTitre } = data3;

	const handleChange = (e) => {
		setData3({ ...data3, [e.target.name]: e.target.value });
	};




	const variants = {
		hidden: { opacity: 0, scale: 0.8 },
		visible: { opacity: 1, scale: 1 },
	};

	////////open page variables////////

	const [dashboardStart, SetDashboardStart] = useState(true);
	const [stageStart, SetStageStart] = useState(false);
	const [questionStart, SetQuestionStart] = useState(false);
	const [resultSart, SetResultSart] = useState(false);

	/////////////////game Liste////////////////

	useEffect(() => {
		setLoading(true);
		const fetchBooks = async () => {
			try {
				const firestore = getFirestore();
				let currentUser = auth.currentUser.uid;
				const gameBoardCollectionRef = collection(
					firestore,
					`users/${currentUser}/gameBoard`
				);
				const snapshot = await getDocs(gameBoardCollectionRef);
				const gameList = snapshot.docs.map((doc) => doc.data());

				setGameBoard(gameList);
			} catch (error) {
				console.error("Error fetching gameBoard:", error.message);
			}
		};

		fetchBooks();
		setLoading(false);
	}, [auth.currentUser.uid]);
	///////////////////// start game /////////////
	const openGame = (gameId) => {
		setMainData(Jeux.filter((element) => element.gameId === gameId));
		SetDashboardStart(false);
		SetStageStart(true);
	};

	///////////end dashboard wellcome page/////////////////

	///////////////////////open questions////
	const questionOpen = () => {
		SetStageStart(false);
		SetQuestionStart(true);
	};

	const [score, setScore] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showResults, setShowResults] = useState(false);
	const [openQuestion, setOpenQuestion] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const optionClicked = (isCorrect) => {
		// Increment the score
		if (isCorrect) {
			const refreshToast = toast.loading("Vérification...");
			toast.success("Bravo !", {
				id: refreshToast,
			});
			setScore(score + 1);
		} else {
			const refreshToast = toast.loading("Vérification...");
			toast.success("Hooo Nonn, Soyez attentif !", {
				id: refreshToast,
			});
		}

		if (currentQuestion + 1 < 40) {
			setCurrentQuestion(currentQuestion + 1);
		} else {
			setIsModalOpen(true);
			setShowResults(true);
		}
	};

	///////results //////
	const closeModal = () => {
		setIsModalOpen(false);
		setShowResults(false);
		setCurrentQuestion(0);
		setScore(0);
	};

	const reprise = () => {
		setIsModalOpen(false);
		setShowResults(false);
		setCurrentQuestion(0);
		setScore(0);
	};

	/////////continuer à jouer//////

	

	const [niveauJeu, setNiveauJeu]= useState(selectedArticle?.jeuPosition)
	const [titreJeu, setTitreJeu]= useState(selectedArticle?.titre)

	const [niveau, setNiveau]= useState(selectedArticle?.jeuPosition)
	const [titre, setTitre]= useState(selectedArticle?.titre)

	const suivant = async (e) => {
		e.preventDefault();

		let userId = auth.currentUser.uid;
		const userDocRef = doc(db, "users", userId);
		const palmaresCollectionRef = collection(userDocRef, "palmares");

		try {
			await addDoc(palmaresCollectionRef, {
				score: score,
				
				
			});
			console.log(
				"Palmares subcollection created successfully for user",
				userId
			);
			console.log(niveauJeu)
			console.log(titreJeu)

		} catch (error) {
			console.error("Error creating palmares subcollection:", error);
		}
		SetDashboardStart(true);
		SetStageStart(false);
		SetQuestionStart(false);
		SetResultSart(false);
		setIsModalOpen(false);
		setShowResults(false);
		setCurrentQuestion(0);
		setScore(0);
		navigate("/resset");


	};

	return (
		<div>
			{loading ? (
				<div className="flex flex-col w-screen h-screen justify-between border border-white text-white text-base sm:text-3xl rounded-md py-2">
					<div className="flex flex-1 justify-center  py-2">
						Bienvenue sur 1000 Questions Bibliques Pour Moi
					</div>
					<div className="flex flex-1 justify-center  py-2">Faire un don</div>

					<div className="flex  items-center justify-center ">
						<img
							src={Loading}
							alt=""
							className="w-[100px] h-[100px] rounded-full bg-transparent"
						/>
					</div>
				</div>
			) : (
				<div>
					{/*-----------------------Start dashboard wellcome ---------------------------------*/}
					{dashboardStart && (
						<div className="flex flex-1 flex-col">
							<div className="flex justify-center border border-white text-white text-base sm:text-3xl rounded-md py-2">
								Bienvenue sur 1000 Questions Bibliques Pour Moi
							</div>

							<div className="grid gap-4 sm:grid-cols-5 grid-cols-2 mt-4">
								{gameBoard &&
									gameBoard.map((item) => (
										<div
											key={item.id}
											hidden={!item.statutJeu}
											className=" cursor-pointer  hover:bg-green-100 w-fit px-2 py-1 rounded-xl"
											onClick={() => openGame(item.gameId)}>
											<MyGame
												gameId={item.gameId}
												jeuName={item.jeuName}
												jeuPosition={item.jeuPosition}
												sectQuest={item.sectQuest}
												statutJeu={item.statutJeu}
											/>
										</div>
									))}
							</div>
						</div>
					)}
					{/*-----------------------End dashboard wellcome---------------------------------*/}

					{/*-----------------------Start stage ---------------------------------*/}
					{stageStart && (
						<div>
							{isMainData &&
								isMainData.map((data) => (
									<div key={data.gameId} className="cursor-pointer">
										<div className="flex flex-col  h-full">
											<div className="border border-white rounded-md p- sm:p-4 justify-center text-[14px] sm:text-3xl mb-4 text-center font-extrabold text-white">
												Bienvenue sur 1.000 Questions Bibliques Pour Moi
											</div>

											<div className="flex items-center border border-white rounded-md p-2">
												<div className="hidden p-4 justify-center h-full sm:flex">
													<motion.img
														initial="hidden"
														animate="visible"
														variants={variants}
														transition={{ duration: 0.5 }}
														src={data.stagImgSrc}
														alt={data.stageTitle}
														className="  h-[350px] w-[400px] object-contain overflow-hidden rounded-md"
													/>
												</div>
												<div className="flex flex-col w-full">
													<div className="flex sm:hidden justify-center object-center items-center w-full">
														<motion.img
															initial="hidden"
															animate="visible"
															variants={variants}
															transition={{ duration: 0.5 }}
															src={data.stagImgSrc}
															alt={data.stageTitle}
															className=" object-contain overflow-hidden  w-32 mx-auto my-2"
														/>
													</div>

													<div className="flex justify-center text-white text-sm sm:text-xl font-bold sm:py-4 mb-6 items-center bg-cyan-500 px-6 py-3">
														{data.jeuTitle}
													</div>
													<div className="flex space-y-4  -mt-5">
														<motion.div
															initial="hidden"
															animate="visible"
															variants={variants}
															transition={{ duration: 0.5 }}
															className="flex flex-col p-4 text-sm sm:text-lg">
															<p>{data.paraf1}</p>
															<br />
															<p>{data.paraf2}</p>
															<br />
															<p>{data.paraf3}</p>
															<br />
															<p>{data.paraf4}</p>
														</motion.div>
													</div>
													<div className="flex justify-between px-4">
														<p></p>
														<p
															onClick={() => questionOpen()}
															className="sm:px-4 sm:py-1 px-2 py-0 border-2 border-cyan-500 rounded-xl bg-cyan-500 text-white font-bold cursor-pointer -mt-4 text-sm sm:text-xl mb-1">
															Commençons !
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					)}
					{/*-----------------------End stage ---------------------------------*/}

					{questionStart && (
						<div>
							<div className="flex items-center  justify-center font-bold border border-white text-sky-800 text-base sm:text-3xl rounded-md py-2 space-x-4">
								<p> Jouer – Apprendre – Mémoriser</p>{" "}
								<img src={Load} alt="" className=" w-16 h-12 rounded-full" />
							</div>
							<div>
								{isMainData &&
									isMainData.map((data) => (
										<>
											<div className=" mt-2 flex flex-col justify-center border border-white text-xs sm:text-xl text-blue-900 rounded-md py-2">
												<p className="flex justify-between items-center px-4">
													<p className="flex items-center space-x-2">
														<p className=" font-bold text-black">Niveau:</p>
														<p className=" font-bold text-blue-800">
															{data?.jeuPosition}
														</p>
														<p className=" font-bold text-blue-800">
															( {data?.sectionTitle} )
														</p>
													</p>
													<p className="flex items-center space-x-2">
														<p className=" font-bold text-black">
															Question N°{" "}
														</p>
														<p className="text-blue-700 font-bold">
															{currentQuestion + 1}
														</p>
													</p>
													<p className="flex items-center space-x-2">
														<p className=" font-bold text-black">Score:</p>
														<p className=" font-bold text-blue-800">
															{score}/{data.questions.length}
														</p>
													</p>
												</p>
											</div>
											<div className=" mt-2 flex justify-between sm:border border-white rounded-md p-4 items-center mb-6  space-x-3">
												<p className="flex justify-center sm:w-1/3">
													<motion.img
														initial="hidden"
														animate="visible"
														variants={variants}
														transition={{ duration: 0.5 }}
														src={data.sectionImgSrc}
														alt=""
														className="  h-[350px] w-[400px] object-contain overflow-hidden rounded-md hidden sm:flex"
													/>{" "}
												</p>
												<motion.p
													initial="hidden"
													animate="visible"
													variants={variants}
													transition={{ duration: 0.5 }}
													className="flex flex-col  sm:w-2/3">
													<p>
														<img
															src={data.sectionImgSrc}
															alt=""
															className="  h-[350px] w-[400px] object-contain overflow-hidden rounded-md sm:hidden"
														/>{" "}
													</p>

													{openQuestion && (
														<>
															<div className="flex w-full p-4 rounded-md flex-col mb-4">
																<Toaster />

																{data.questions &&
																	data.questions.map((question, index) => {
																		return (
																			<>
																				{index === currentQuestion && (
																					<>
																						<motion.div
																							initial={{ opacity: 0, y: 50 }}
																							animate={{ opacity: 1, y: 0 }}
																							transition={{ duration: 1 }}
																							key={question.id}
																							className=" flex flex-1  justify-between">
																							<div className="flex flex-col flex-1 ml-4 justify-center ">
																								<p className="text-lg font-bold sm:text-2xl text-center mb-4">
																									{question.text}
																								</p>
																								<p className="flex justify-between px-4 ">
																									{question.options.map(
																										(option) => {
																											return (
																												<p
																													key={option.id}
																													onClick={() =>
																														optionClicked(
																															option.isCorrect
																														)
																													}
																													className="mt-1 text-[10px] mr-2 text-center sm:text-lg py-1 px-1 rounded-lg items-center justify-center shadow-md bg-white/80  cursor-pointer hover:scale-110 ease-in-out duration-200 w-fit">
																													{option.text}
																												</p>
																											);
																										}
																									)}
																								</p>
																							</div>
																						</motion.div>
																					</>
																				)}
																			</>
																		);
																	})}
															</div>
														</>
													)}
												</motion.p>
											</div>
										</>
									))}
							</div>
						</div>
					)}
					{/*-----------------------End Question ---------------------------------*/}
					{/*-----------------------Start Result ---------------------------------*/}
					<Modal isOpen={isModalOpen} closeModal={closeModal}>
						{showResults && (
							<>
								{score < 10 ? (
									<>
										<div className="flex flex-col bg-slate-100 rounded-md shadow-lg p-2">
											<p className="text-center sm:text-xl font-bold">
												{" "}
												Votre score pour cette Session
											</p>
											<div className="flex text-sm sm:text-xl flex-col ml-6">
												<ul className="flex ">
													<li className="font-bold">Nombre de questions:</li>
													<li className="flex ml-4 font-bold text-red-600">
														40
													</li>
												</ul>
												<ul className="flex ">
													<li className="font-bold">
														Minimume réquis pour passer:
													</li>
													<li className="flex ml-4 font-bold text-green-600">
														30
													</li>
												</ul>
												<ul className="flex ">
													<li className="font-bold">
														Nombre de points obtenus:
													</li>
													<li className="flex ml-4 font-bold text-red-600">
														{score}
													</li>
												</ul>
											</div>
											<div className="flex flex-col items-center w-full">
												<p className="text-xl sm:text-2xl bg-orange-200 px-2 py-1 my-2 rounded-md">
													Désolé 😔
												</p>
												<p className="text-xs sm:text-2xl text-justify text-blue-700 sm:text-md px-2">
													« Si tu te laisses abattre au jour de l'adversité, ta
													force est bien peu de chose. » (Proverbe 24 : 10);
													donc persévère….
												</p>
											</div>
											<div className="flex relative justify-between px-4  items-center ">
												<div className=" justify-center right-30 ">
													<Lottie
														animationData={Unhappy}
														loop={true}
														className=" object-cover "
													/>
												</div>
												<div
													onClick={reprise}
													className=" bg-orange-300 px-4 py-2 cursor-pointer rounded-md shadow-md shadow-green-200">
													Reprendre
												</div>
											</div>
										</div>
									</>
								) : (
									<>
										<div className="flex flex-col bg-slate-100 rounded-md shadow-lg p-2 text-xs">
											<p className="text-center sm:text-xl font-bold">
												{" "}
												Votre score pour cette Session
											</p>
											{/*Winner Game status*/}

											{isMainData &&
												isMainData.map((data) => (
													<div onClick={suivant}>
														<div>
															<div className="flex text-sm sm:text-lg flex-col ml-6">
																<ul className="flex ">
																	<li className="font-bold">Niveau</li>
																	<li className="flex ml-4 font-bold text-green-600">
																		{data.jeuPosition}
																	</li>
																</ul>
																<ul className="flex ">
																	<li className="font-bold">Titre:</li>
																	<li className="flex ml-4 font-bold text-green-600">
																		{data.sectionTitle}
																	</li>
																</ul>
																<ul className="flex ">
																	<li className="font-bold">
																		Nombre de questions:
																	</li>
																	<li className="flex ml-4 font-bold text-red-600">
																		{data.questions.length}
																	</li>
																</ul>
																<ul className="flex ">
																	<li className="font-bold">
																		Minimume réquis pour passer:
																	</li>
																	<li className="flex ml-4 font-bold text-green-600">
																		30
																	</li>
																</ul>

																<ul className="flex ">
																	<li className="font-bold">
																		Nombre de points obtenus:
																	</li>
																	<li className="flex ml-4 font-bold text-orange-600 text-2xl animate-bounce">
																		{score}
																	</li>
																</ul>
															</div>
															<div className="flex flex-col items-center w-full">
																<p className="text-lg sm:text-2xl bg-orange-200 px-2 py-1 my-4 rounded-md">
																	Félicitations ! ! !
																</p>
																<p className="text-xs sm:text-xl text-justify text-blue-700 sm:text-md px-2 text-[10px]">
																	«Elisée ajouta: Prends maintenant d'autres
																	flèches! Il les prit. Frappe contre le sol! Le
																	roi d'Israël frappa trois coups et s'arrêta. »
																	( 2 Rois 13 : 18) ; Ne t’arrête pas…
																</p>
															</div>
															<div className="flex relative justify-between px-4  items-center ">
																<div className=" justify-center right-30 ">
																	<Lottie
																		animationData={Celebrate}
																		loop={true}
																		className=" object-cover w-28"
																	/>
																</div>
																<div className=" ">
																	<Form>
																		

																		<Form.Field>
																			
																			<Form.Input
																				type="hidden"
																				name="Niveau"
																				value={niveauJeu}
																				onChange={niveauJeu}
																			/>
																		</Form.Field>

																		<Form.Field>
																			
																			<Form.Input
																				type="hidden"
																				name="Niveau"
																				value={titreJeu}
																				onChange={titreJeu}
																			/>
																		</Form.Field>

																		

																		<Button
																			primary
																			type="submit"
																			onClick={suivant}>
																			Continuer
																		</Button>
																	</Form>
																</div>
															</div>
														</div>
													</div>
												))}
										</div>
									</>
								)}
							</>
						)}
					</Modal>

					{/*-----------------------End result stage ---------------------------------*/}
				</div>
			)}
		</div>
	);
}

export default Dashboard;
