import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { selectArticleBoard } from "../features/articleBoardSlice";
import { useDispatch } from "react-redux";

function GameBoardUnit({
	id,
	jeuPosition,
	jeuName,
	stagImgSrc,
	buttonActivated,
}) {
	const variants = {
		hidden: { opacity: 0, scale: 0.8 },
		visible: { opacity: 1, scale: 1 },
	};

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			selectArticleBoard({
				id,
				jeuPosition,
				jeuName,
				stagImgSrc,
				buttonActivated,
			})
		);
	}, []);

	return (
		<motion.button
			initial="hidden"
			animate="visible"
			variants={variants}
			transition={{ duration: 0.5 }}
			disabled={!buttonActivated}
			className={
				buttonActivated
					? "flex items-center w-fit px-1 py-1 space-x-1 border relative border-white m-3 rounded-lg bg-slate-50 cursor-pointer"
					: "flex items-center w-fit px-2 py-1 space-x-1 border relative border-white m-3 rounded-lg bg-slate-50 cursor-not-allowed "
			}>
			<div
				className={
					buttonActivated
						? "w-3 h-3 rounded-full bg-lime-600 absolute right-0 top-0 z-10"
						: "w-3 h-3 rounded-full bg-red-400 absolute right-0 top-0 z-10"
				}></div>
			<img
				src={stagImgSrc}
				alt=""
				className=" w-14 h-10 object-contain overflow-hidden rounded-xl"
			/>

			<div className="flex flex-col  text-xs">
				<p>{jeuPosition}</p>
				<p>{jeuName}</p>
			</div>
		</motion.button>
	);
}

export default GameBoardUnit;
