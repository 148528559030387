import { createSlice } from '@reduxjs/toolkit';

export const articleSlice = createSlice({
  name: 'article',
  initialState: {
    selectedArticle: null,
    articleIsOpen: false,
  },

  reducers: {
    selectArticle: (state, action) => {
      state.selectedArticle = action.payload;
    },
    //2 actions for the same initial state
    openArticle: (state) => {
      state.articleIsOpen = true;
    },
    closeArticle: (state) => {
      state.articleIsOpen = false;
    },
  },
});

export const {
  selectArticle,
  openArticle,
  closeArticle,
} = articleSlice.actions;
// our selectors
export const selectOpenArticle = (state) => state.article.selectedArticle;
export const selectArticleIsOpen = (state) => state.article.articleIsOpen;

export default articleSlice.reducer;
