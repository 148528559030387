import React, { useState } from "react";

function ContinuousAudioPlayer() {
	

	return (
		<div>
			<audio autoPlay loop>
				<source src="/music1.mp3" type="audio/mp3" />
			</audio>
		</div>
	);
}

export default ContinuousAudioPlayer;
