import React from 'react'
import Footer from './Footer'
import Header from './Header'

function Layout(props) {

    const { children } = props

  return (
    <div className='relative flex items-center justify-center h-screen border-spacing-x-1.5'>
        <div className=' NovaCut text-slate-900 absolute flex-col justify-between  appBackground p-1 sm:px-4 pm:py-6  h-[98%] w-[95%] shadow-lg  shadow-sky-600'>
            <Header />
            <main className='h-[88%] flex-col p-4 overflow-scroll scrollbar-hide '>
                {children}
            </main>
            <Footer />
        </div>
    </div>
  )
}

export default Layout

