import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import { Route, Routes } from "react-router-dom"
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import GameGround from "./pages/GameGround";
import GameBoard from "./pages/GameBoard";
import GamePlay from "./pages/GamePlay";
import Resset from "./pages/Resset";


function App() {

  const [user, setUser] = useState(null);
	const [authState, setAuthState] = useState(null);

	useEffect(() => {
		const unSubscribeAuth = onAuthStateChanged(
			auth,
			async (authenticatedUser) => {
				if (authenticatedUser) {
					setUser(authenticatedUser.email);
					setAuthState("dashboard");
				} else {
					setUser(null);
					setAuthState("login");
				}
			}
		);

		return unSubscribeAuth;
	}, [user]);


  return (
    <>
			{!auth.currentUser ? (
				<Home />
			) : (
				<Layout>
					<Routes>
						<Route path="/" element={<Dashboard />} />
						<Route path="/resset" element={<Resset />} />
						<Route path="/gamePlay" element={<GamePlay />} />
						<Route path="/gameGround" element={<GameGround />} />
						<Route path="/gameBoard" element={<GameBoard />} />
					</Routes>
				</Layout>
			)}
		</>
  );
}

export default App;
