import React from "react";
import Celebrate from "../lotties/celebrations.json";
import Lottie from "lottie-react";
import { useDispatch } from "react-redux";
import { selectArticle } from "../features/articleSlice";

function GameStatus({ id, niveau, titre, nombre, score, statutJeu, gameId }) {
	
    const dispatch = useDispatch();

	const continuer = () => {
		dispatch(
			selectArticle({
				id,
				niveau,
				titre,
				nombre,
				score,
                statutJeu,
				gameId,
			})
		);
	};

	return (
		<div>
			<div onClick={continuer}>
				<div className="flex text-sm sm:text-lg flex-col ml-6">
					<ul className="flex ">
						<li className="font-bold">Niveau</li>
						<li className="flex ml-4 font-bold text-green-600">{niveau}</li>
					</ul>
					<ul className="flex ">
						<li className="font-bold">Titre:</li>
						<li className="flex ml-4 font-bold text-green-600">{titre}</li>
					</ul>
					<ul className="flex ">
						<li className="font-bold">Nombre de questions:</li>
						<li className="flex ml-4 font-bold text-red-600">{nombre}</li>
					</ul>
					<ul className="flex ">
						<li className="font-bold">Minimume réquis pour passer:</li>
						<li className="flex ml-4 font-bold text-green-600">30</li>
					</ul>

					<ul className="flex ">
						<li className="font-bold">Nombre de points obtenus:</li>
						<li className="flex ml-4 font-bold text-orange-600 text-2xl animate-bounce">{score}</li>
					</ul>
				</div>
				<div className="flex flex-col items-center w-full">
					<p className="text-lg sm:text-2xl bg-orange-200 px-2 py-1 my-4 rounded-md">
						Félicitations ! ! !
					</p>
					<p className="text-xs sm:text-xl text-justify text-blue-700 sm:text-md px-2 text-[10px]">
						«Elisée ajouta: Prends maintenant d'autres flèches! Il les prit.
						Frappe contre le sol! Le roi d'Israël frappa trois coups et
						s'arrêta. » ( 2 Rois 13 : 18) ; Ne t’arrête pas…
					</p>
				</div>
				<div className="flex relative justify-between px-4  items-center ">
					<div className=" justify-center right-30 ">
						<Lottie
							animationData={Celebrate}
							loop={true}
							className=" object-cover w-28"
						/>
					</div>
					<div
						onClick={continuer}
						className=" bg-orange-300 text-lg px-4 py-2 cursor-pointer rounded-md shadow-md shadow-green-200">
						Continuer
					</div>
				</div>
			</div>
		</div>
	);
}

export default GameStatus;
