import { createSlice } from '@reduxjs/toolkit';

export const articleBoardSlice = createSlice({
  name: 'articleBoard',
  initialState: {
    selectedArticleBoard: null,
    articleBoardIsOpen: false,
  },

  reducers: {
    selectArticleBoard: (state, action) => {
      state.selectedArticleBoard = action.payload;
    },
    //2 actions for the same initial state
    openArticleBoard: (state) => {
      state.articleBoardIsOpen = true;
    },
    closeArticleBoard: (state) => {
      state.articleBoardIsOpen = false;
    },
  },
});

export const {
  selectArticleBoard,
  openArticleBoard,
  closeArticleBoard,
} = articleBoardSlice.actions;
// our selectors
export const selectOpenArticleBoard = (state) => state.articleBoard.selectedArticleBoard;
export const selectArticleBoardIsOpen = (state) => state.articleBoard.articleBoardIsOpen;

export default articleBoardSlice.reducer;
