import React, { useEffect, useState } from 'react'
import { selectOpenArticle } from '../features/articleSlice';
import { Jeux } from "../api/data";
import Load from '../img/bibleop.gif'
import { useSelector } from "react-redux";
//import { initializeUseSelector } from 'react-redux/es/hooks/useSelector';


function GamePlay() {

	const [loading, setLoading] = useState(true);
	const [isMainData, setMainData] = useState([]);

	const selectedArticle = useSelector(selectOpenArticle);

  useEffect(() => {
    const filteredData = Jeux.filter((item) => item?.sectQuest === selectedArticle?.sectQuest);
    setMainData(filteredData);
  }, [selectedArticle]); // Add selectedArticle as a dependency

    console.log(isMainData);

  return (
    <div>
        {loading ? (<div>
          <p className="flex-col space-y-4"> <p className="text-lg text-blue-500 font-bold">Nous ouvrons la session</p>	<img src={Load} alt="" className=" w-20 h-16"/>
        </p>
        <p assName="text-lg text-blue-500 font-bold">Veuillez attendre</p>
        </div>):(
          <div>

            dictionary
          </div>
        )}
    </div>
  )
}

export default GamePlay