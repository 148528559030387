import { configureStore } from '@reduxjs/toolkit';
import userReducer from "../features/userSlice";
import articleReducer from '../features/articleSlice'
import articleValidatedReducer from '../features/articleValidatedSlice'
import articleBoardReducer from '../features/articleBoardSlice'

export const store = configureStore({
 
  reducer: {
    user: userReducer,
    article: articleReducer,
    articleValidated: articleValidatedReducer,
    articleBoard: articleBoardReducer,
  },
});
