import React, { useEffect, useState } from "react";
import Logo from "../img/logo.png";
import { BiLogOut } from "react-icons/bi";
import { AiOutlineImport, AiOutlineMail } from "react-icons/ai";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { TbArrowGuide } from "react-icons/tb";
import { TbMoneybag } from "react-icons/tb";
import Modal from "./Modal";
import { Button, Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebase";

const initialState = {
  email: "",
  password: "",
};

function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const [isModalOpenDon, setIsModalOpenDon] = useState(false);

  const [isModalOpenParcours, setIsModalOpenParcours] = useState(false);

  const [isModalOpenContact, setIsModalOpenContact] = useState(false);

  const [data, setData] = useState(initialState);
  const { email, password } = data;

  const [errors, setErrors] = useState({});

  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();

  const openLoginModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalOpen1(false);
    setIsModalOpenContact(false)
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};
    if (!email) {
      errors.email = "email obligatoire";
    }
    if (!password) {
      errors.password = "mot de passe obligatoir";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) return setErrors(errors);
    setIsSubmit(true);

    if (!email || !password) return;

    if (email && password) {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        setIsModalOpen(false);
        navigate("/dashboard");
      } catch (error) {
        console.error(error);
      }
    }
  };

 
  ///logout
  const loMegout = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error(err);
    }
  };

  //menu on Mobile screen

  const [menu, setMenu] = useState(false);

  const openMenu = () => {
    setMenu(!menu);
  };

  const closeMenu = () => {
    setMenu(!menu);
  };

  /// contact

  const contact = () => {
    setIsModalOpenContact(true);
  }

  const faireDon = () => {}

  const parcours =()=>{}

  return (
    <>
      <div className=" flex justify-between border-b-2 items-center mb-2 ">
        <div className="flex items-center">
          {" "}
          <img src={Logo} alt="" className="h-[30px]  sm:w-[50px] mr-2 mb-2" />
          <p className="hidden sm:flex text-ms sm:text-lg">
            1000 Questions Bibliques pour Moi
          </p>
          <p className="flex sm:hidden text-ms sm:text-lg">1000 QBM</p>
        </div>
        <div>
          <p className=" sm:hidden flex text-white font-thin text-xs">
            Bienvenue {auth.currentUser.email}
          </p>
        </div>
        <div className="flex items-center">
          <div className="flex items-center text-xs sm:text-sm lg:text-lg mr-4 italic">
            {" "}
            <p className="mr-4 hidden sm:flex text-white font-thin">
              Bienvenue {auth.currentUser.email}
            </p>
            <p className="hidden sm:flex ">
              <AiOutlineMenu
                className="hidden sm:flex cursor-pointer "
                onClick={openMenu}
              />

            </p>
            {menu && (
              <p className=" hidden sm:flex flex-col z-10 absolute top-10 right-5">
                <div className="flex flex-col max-w-lg bg-white/50 px-2 py-1 rounded-md ">
                <p
                    onClick={closeMenu}
                    className=" justify-end flex  text-sm  hover:bg-sky-200 py-1 px-2 rounded-md cursor-pointer">
                    {" "}
                    <AiOutlineClose className="mr-2 text-sm" />
                   
                  </p>
                  <p
                    onClick={loMegout}
                    className="flex items-center text-sm  hover:bg-sky-200 py-1 px-2 rounded-md cursor-pointer">
                    {" "}
                    <BiLogOut className="mr-2 text-sm" />
                    <p>Déconnecter</p>
                  </p>
                  <p 
                  onClick={parcours}
                  className="flex items-center text-sm  hover:bg-sky-400 py-1 px-2 rounded-md cursor-pointer">
                    {" "}
                    <TbArrowGuide className="mr-2 text-sm" />
                    <p>Parcours</p>
                  </p>
                  <p
                    onClick={faireDon}
                    className="flex items-center text-sm  hover:bg-sky-400 py-1 px-2 rounded-md cursor-pointer">
                    {" "}
                    <TbMoneybag className="mr-2 text-sm" />
                    <p>Faire un don</p>
                  </p>
                  <p
                    onClick={contact}
                    className="flex items-center text-sm  hover:bg-sky-400 py-1 px-2 rounded-md cursor-pointer">
                    {" "}
                    <AiOutlineMail className="mr-2 text-sm" />
                    <p>Contacter-nous</p>
                  </p>
                </div>
              </p>
            )}
            <div className="flex  items-center space-x-6">
              {!auth.currentUser ? (
                <p
                  onClick={openLoginModal}
                  className="flex items-center py-1 px-3 bg-sky-400 text-sm lg:text-lg text-white rounded-full hover:bg-amber-500 cursor-pointer">
                  <AiOutlineImport className="mr-2" />
                  Se connecter
                </p>
              ) : (
                <div
                  onClick={openMenu}
                  className="flex sm:hidden flex-col space-y-2 items-center relative">
                  <p className=" z-0 absolute">
                    {!menu ? (
                      <AiOutlineMenu
                        className=" cursor-pointer"
                        onClick={openMenu}
                      />
                    ) : (
                      <AiOutlineClose
                        className=" cursor-pointer"
                        onClick={openMenu}
                      />
                    )}
                  </p>
                  {menu && (
                    <p className=" z-10 absolute top-0 right-0">
                      <div className="flex flex-col max-w-lg bg-white/50 px-2 py-1 rounded-md ">
                        <p
                          onClick={loMegout}
                          className="flex items-center text-sm  hover:bg-sky-200 py-1 px-2 rounded-md cursor-pointer">
                          {" "}
                          <BiLogOut className="mr-2 text-sm" />
                          <p>Déconnecter</p>
                        </p>
                        <p 
                  onClick={parcours}
                  className="flex items-center text-sm  hover:bg-sky-400 py-1 px-2 rounded-md cursor-pointer">
                    {" "}
                    <TbArrowGuide className="mr-2 text-sm" />
                    <p>Parcours</p>
                  </p>
                  <p
                    onClick={faireDon}
                    className="flex items-center text-sm  hover:bg-sky-400 py-1 px-2 rounded-md cursor-pointer">
                    {" "}
                    <TbMoneybag className="mr-2 text-sm" />
                    <p>Faire un don</p>
                  </p>
                  <p
                    onClick={contact}
                    className="flex items-center text-sm  hover:bg-sky-400 py-1 px-2 rounded-md cursor-pointer">
                    {" "}
                    <AiOutlineMail className="mr-2 text-sm" />
                    <p>Contacter-nous</p>
                  </p>
                      </div>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <div className="flex items-center justify-between">
          <p>
            <img
              src={Logo}
              alt="mutuelle de Sakassou"
              className="w-[30px] h-[30px] rounded-full shadow-sm  shadow-amber-600"
            />
          </p>
          <p className="text-xl font-bold">Connectez-vous ici !!! </p>
        </div>

        <div className="my-6">
          <Form onSubmit={handleSubmit}>
            <Form.Input
              label="Email"
              error={errors.email ? { content: errors.email } : null}
              icon="at"
              iconPosition="left"
              placeholder="Votre Email ici"
              name="email"
              type="email"
              value={email}
              onChange={handleChange}
            />
            <Form.Input
              label="Mot de passe"
              error={errors.password ? { content: errors.password } : null}
              icon="lock"
              iconPosition="left"
              placeholder="Votre Mot de passe ici"
              name="password"
              type="password"
              onChange={handleChange}
              value={password}
            />

            <Button primary type="submit">
              Connectez-vous
            </Button>
          </Form>
        </div>
      </Modal>

      <Modal isOpen={isModalOpenContact} closeModal={closeModal}>
        <div className="flex items-center justify-between">
          Contacter nous

          
        </div>
 
      </Modal>
    </>
  );
}

export default Header;
