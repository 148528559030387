import React, { useState } from 'react'
import Modal from '../components/Modal';
import { Button, Form } from "semantic-ui-react";
import applaud from "../img/applaud.gif"
import "semantic-ui-css/semantic.min.css";
import { selectOpenArticle } from "../features/articleSlice";
import { useSelector } from "react-redux";
import {
	collection,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { useNavigate } from 'react-router-dom';

function Resset() {

    const [isModalOpen, setIsModalOpen] = useState (true);

    const selectedArticle = useSelector(selectOpenArticle);

    const closeModal = () => {
		setIsModalOpen(false);
		
	};

    const [oldId, setOldId] = useState(selectedArticle?.gameId);
	const newId = oldId + 1;
    const navigate = useNavigate();

	
    // Function to update statutJeu in gameBoard subcollection
const handleSubmitNewGame = async (e) => {
    e.preventDefault();
    if(newId < 26 ){
		try {
			let userId = auth.currentUser.uid;
		  // Query gameBoard subcollection to find document with gameId equal to newId
		  const q = query(collection(db, `users/${userId}/gameBoard`), where("gameId", "==", newId));
		  const querySnapshot = await getDocs(q);
	  
		  querySnapshot.forEach(async (doc) => {
			// Update statutJeu field in the found document
			await updateDoc(doc.ref, {
			  statutJeu: true,         });
			console.log("StatutJeu updated successfully!");
		  });
		  navigate("/")
		} catch (error) {
		  console.error("Error updating statutJeu:", error);
		}
	}else{
		navigate("/")
	}
  };

    

  return (
    <div>
        <Modal isOpen={isModalOpen} closeModal={closeModal}>

        <div className='flex flex-col space-y-3'>
							<div className='flex flex-1 space-x-3 items-center'><div className='text-2xl font-bold text-cyan-500'>Félicitations !!!</div>
							<div> <img src={applaud} alt=""  className=' w-28'/></div></div>

							<Form > 
								<Form.Field>
									<label className=' text-3xl '>Voua avez validé le niveau {" "} {selectedArticle.titre} </label>

									
								</Form.Field>

								<Form.Field>
									<label className=' text-3xl'>Voua allez maintenant aborder une nouvelle étape.</label>
									<label className=' text-3xl'>Etes-vous vraiment prêt ?.</label>
									<Form.Input
										placeholder={newId}
										type="hidden"
										name="locataire"
										value={newId}
										onChange={newId}
									/>
								</Form.Field>

								

								<Button
									primary
									type="submit"
									onClick={handleSubmitNewGame}
									>
									Oui je suis prêt !
								</Button>
							</Form>
						</div>
						
		</Modal>
    </div>
  )
}

export default Resset