import React, { useState } from "react";
import Pic from "../img/Image11.jpg";
import { useNavigate } from "react-router-dom";
import Logo from "../img/logo.png";
import { Button, Form } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import {
	createUserWithEmailAndPassword,
	getAuth,
	signInWithEmailAndPassword,
} from "firebase/auth";
import { GameMap } from "../api/data";
import { auth, db } from "../firebase";
import Modal from "../components/Modal";
import { addDoc, collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { motion } from "framer-motion";

const initialState = {
	email: "",
	password: "",
};

const initialState2 = {
	email1: "",
	password1: "",
	nomPrenoms: "",
	pays: "",
	langue: "",
	confPassword: "",
};

function Home() {
	const [isModalOpen, setIsModalOpen] = useState(false);


	const [isModalOpen1, setIsModalOpen1] = useState(false);

	const [data, setData] = useState(initialState);
	const { email, password } = data;

	const [errors, setErrors] = useState({});

	const [isSubmit, setIsSubmit] = useState(false);

	const navigate = useNavigate();

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setIsModalOpen1(false);
	};

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const validate = () => {
		let errors = {};
		if (!email) {
			errors.email = "email obligatoire";
		}
		if (!password) {
			errors.password = "mot de passe obligatoir";
		}

		return errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let errors = validate();
		if (Object.keys(errors).length) return setErrors(errors);
		setIsSubmit(true);

		if (!email || !password) return;

		if (email && password) {
			try {
				await signInWithEmailAndPassword(auth, email, password);
				setIsModalOpen(false);
				navigate("/");
			} catch (error) {
				console.error(error);
			}
		}
	};

	const connecter = () => {
		setIsModalOpen1(true);
	};

	//sign-up data

	const [data2, setData2] = useState(initialState2);
	const { email1, password1, nomPrenoms, pays, langue, confPassword } = data2;

	const [isSubmit2, setIsSubmit2] = useState(false);

	const handleChange2 = (e) => {
		setData2({ ...data2, [e.target.name]: e.target.value });
	};

	const validate2 = () => {
		let errors = {};
		if (!email1) {
			errors.email1 = "email obligatoire";
		}
		if (!password1) {
			errors.password1 = "mot de passe obligatoir";
		}
		if (!nomPrenoms) {
			errors.nomPrenoms = "Nom et Prénoms obligatoirs";
		}
		if (!pays) {
			errors.pays = "Nom et Prénoms obligatoirs";
		}
		if (!langue) {
			errors.langue = "Langue obligatoirs";
		}

		if (!confPassword) {
			errors.confPassword = "confirmer mot de passe obligatoir";
		}
		return errors;
	};

	const handleSubmit2 = async (e) => {
		e.preventDefault();

		const myCollectionRef = collection(db, "gamemap");


		const auth = getAuth();
		const firestore = getFirestore();

		//// Ajouter l'option selection de langue -Francais- anglais- Espagnole - Allemend- portugais
		
		let errors = validate2();
		if (Object.keys(errors).length) return setErrors(errors);
		setIsSubmit2(true);

		if (!email1 || !password1 || !pays || !nomPrenoms) return;

		if (email1 && password1 && pays && nomPrenoms) {
			try {
				const res = await createUserWithEmailAndPassword(
					auth,
					email1,
					password1,
				);

				 // Access the newly created user's ID
				 const userID = res.user.uid;

			
				  // Create the main collection for the user under their userID
				  const userDocRef = doc(collection(firestore, "users"), userID);
				  await setDoc(userDocRef, {}); // Create an empty document
				  
				  // Create a subcollection for the user's profile
				  const profileCollectionRef = collection(userDocRef, "profile");
				  await addDoc(profileCollectionRef, {
					id: userID,
					nomPrenoms: nomPrenoms,
					pays: pays,
					langue: langue,
					email: email1,
				  });
			

				// Create a subcollection for the user's profile
				const gameboardCollectionRef = collection(userDocRef, "gameBoard");

				GameMap.forEach(async (data) => {
					await addDoc(gameboardCollectionRef, data);
				});

				/////////////////////////////
				navigate("/");
			} catch (error) {
				console.error(error);
			}
		}
	};

	//animation

	const variants = {
		hidden: { opacity: 0, scale: 0.8 },
		visible: { opacity: 1, scale: 1 },
	};

	return (
		<>
			<div className="relative flex-1 flex items-center justify-center h-screen border-spacing-x-1.5">
				<div className=" NovaCut text-slate-900 absolute flex-col justify-between  appBackground px-4 py-6  h-[90%] w-[90%] shadow-lg  shadow-sky-600">
					<div className="flex justify-center sm:h-full ">
						<motion.div
							initial="hidden"
							animate="visible"
							variants={variants}
							transition={{ duration: 0.5 }}
							className="sm:flex flex-1 items-center justify-center hidden ">
							<img src={Pic} alt="bible" className=" w-[450px] " />
						</motion.div>

						<div className="flex flex-1 items-center justify-center ">
							<div className="flex flex-col justify-center ">
								<motion.div
									initial="hidden"
									animate="visible"
									variants={variants}
									transition={{ duration: 0.5 }}
									className="flex flex-1 items-center justify-center sm:hidden ">
									<img src={Pic} alt="bible" className=" w-[250px] my-6" />
								</motion.div>

								<motion.p
									initial={{ opacity: 0, y: 50 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 1 }}
									className="sm:text-3xl text-lg flex justify-center text-center font-bold flex-1 border-2 border-white rounded-md sm:py-4 py-1 ">
									Bienvenue sur les 1000 Questions Bibliques pour Moi ( 1000
									QBM)
								</motion.p>

								<p className="flex flex-1 justify-center text-lg sm:text-3xl sm:my-5 my-1 text-white fon">
									- Jouer, Apprendre et Mémoriser -
								</p>

								<p className="sm:text-2xl text-sm mb-6 flex justify-center">
									Apprenez et Mémorisez la bible en jouant à travers 1 000
									Questions. ce jeu se divise en Cinq Stages de 200 questions
									chacun et de Cinq sessions par Stage de 40 questions chacune
								</p>
								<motion.div
									initial={{ opacity: 0, y: -50 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 1 }}
									className="flex flex-1 justify-between items-center">
									<p
										onClick={openModal}
										className="text-bold text-xl border bg-lime-100 px-4 py-2 rounded-md hover:bg-lime-200 cursor-pointer ">
										Se connecter{" "}
									</p>
									<p
										onClick={connecter}
										className="text-bold text-xl border bg-lime-100 px-4 py-2 rounded-md hover:bg-lime-200 cursor-pointer animate-pulse">
										S'inscrire ICI
									</p>
								</motion.div>
							</div>
						</div>
					</div>

					<Modal isOpen={isModalOpen} closeModal={closeModal}>
						<div className="flex items-center justify-between">
							<p>
								<img
									src={Logo}
									alt="mutuelle de Sakassou"
									className="w-[30px] h-[30px] rounded-full shadow-sm  shadow-amber-600"
								/>
							</p>
							<p className="text-xl font-bold">Connectez-vous ici !!!</p>
						</div>

						<div className="my-2 sm:my-6">
							<Form onSubmit={handleSubmit}>
								<Form.Input
									label="Email"
									error={errors.email ? { content: errors.email } : null}
									icon="at"
									iconPosition="left"
									placeholder="Votre Email ici"
									name="email"
									type="email"
									value={email}
									onChange={handleChange}
								/>
								<Form.Input
									label="Mot de passe"
									error={errors.password ? { content: errors.password } : null}
									icon="lock"
									iconPosition="left"
									placeholder="Votre Mot de passe ici"
									name="password"
									type="password"
									onChange={handleChange}
									value={password}
								/>

								<Button primary type="submit">
									Connectez-vous
								</Button>
							</Form>
						</div>
					</Modal>

					<Modal isOpen={isModalOpen1} closeModal={closeModal}>
						<div className="flex items-center justify-between">
							<p>
								<img
									src={Logo}
									alt="mutuelle de Sakassou"
									className="w-[30px] h-[30px] rounded-full shadow-sm  shadow-amber-600"
								/>
							</p>
							<p className="text-xl font-bold text-orange-600">
								inscrivez-vous ici !!!
							</p>
						</div>

						<div className=" flex flex-col my-6">
							<Form onSubmit={handleSubmit2}>
								<Form.Input
									label="nom et Prénoms"
									error={
										errors.nomPrenoms ? { content: errors.nomPrenoms } : null
									}
									icon="user secret"
									iconPosition="left"
									placeholder="Votre Nom et prénoms ici"
									name="nomPrenoms"
									type="text"
									value={nomPrenoms}
									onChange={handleChange2}
								/>

								<Form.Input
									label="pays d'origine"
									error={errors.pays ? { content: errors.pays } : null}
									icon="home"
									iconPosition="left"
									placeholder="Votre pays d'origine ici"
									name="pays"
									type="text"
									value={pays}
									onChange={handleChange2}
								/>

								<Form.Input
									label="langue"
									error={errors.langue ? { content: errors.langue } : null}
									icon="bullhorn"
									iconPosition="left"
									placeholder="Votre langue ici"
									name="langue"
									type="text"
									value={langue}
									onChange={handleChange2}
								/>

								<Form.Input
									label="Email"
									error={errors.email1 ? { content: errors.email1 } : null}
									icon="at"
									iconPosition="left"
									placeholder="Votre Email ici"
									name="email1"
									type="email"
									value={email1}
									onChange={handleChange2}
								/>
								<Form.Input
									label="Mot de passe"
									error={
										errors.password1 ? { content: errors.password1 } : null
									}
									icon="lock"
									iconPosition="left"
									placeholder="Votre Mot de passe ici"
									name="password1"
									type="password"
									onChange={handleChange2}
									value={password1}
								/>

								<Form.Input
									label="Confirmer Mot de passe"
									error={
										errors.confPassword
											? { content: errors.confPassword }
											: null
									}
									icon="lock"
									iconPosition="left"
									placeholder="Confirmer mot de passe ici"
									name="confPassword"
									type="password"
									onChange={handleChange2}
									value={confPassword}
								/>

								<Button primary type="submit" onClick={handleSubmit2}>
									Inscription
								</Button>
							</Form>
						</div>
					</Modal>
				</div>
			</div>
		</>
	);
}

export default Home;
