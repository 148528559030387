import { createSlice } from '@reduxjs/toolkit';

export const articleValidatedSlice = createSlice({
  name: 'articleValidated',
  initialState: {
    selectedArticleValidated: null,
    articleValidatedIsOpen: false,
  },

  reducers: {
    selectArticleValidated: (state, action) => {
      state.selectedArticleValidated = action.payload;
    },
    //2 actions for the same initial state
    openArticleValidated: (state) => {
      state.articleValidatedIsOpen = true;
    },
    closeArticleValidated: (state) => {
      state.articleValidatedIsOpen = false;
    },
  },
});

export const {
  selectArticleValidated,
  openArticleValidated,
  closeArticleValidated,
} = articleValidatedSlice.actions;
// our selectors
export const selectOpenArticleValidated = (state) => state.articleValidated.selectedArticleValidated;
export const selectArticleValidatedIsOpen = (state) => state.articleValidated.articleValidatedIsOpen;

export default articleValidatedSlice.reducer;
