import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import {getFirestore} from "firebase/firestore"
import { getStorage} from 'firebase/storage'

const firebaseConfig = {

    apiKey: "AIzaSyB3w6etWnXs-mUEYMuyaVQ3VuFWvWplpgY",
    authDomain: "bible-quiz-a3e76.firebaseapp.com",
    projectId: "bible-quiz-a3e76",
    storageBucket: "bible-quiz-a3e76.appspot.com",
    messagingSenderId: "103886373084",
    appId: "1:103886373084:web:ce8766841f2ca21afdb419",
    measurementId: "G-2E2SDKKC53"
  
  };
  



// Initialize Firebase
initializeApp(firebaseConfig);
export const auth = getAuth();
export const db =  getFirestore()
export const storage = getStorage()
