import stage1image from "../img/stage1image.png";
import stage2image from "../img/stage2img.png";
import stage3image from "../img/stage3img.png";
import stage4image from "../img/stage4img.png";
import stage5image from "../img/stage5img.png";
import personnages from "../img/personnages.png";
import lieux from "../img/lieux.png";
import institutions from "../img/institutions.png";
import lois from "../img/loisinstructions.png";
import evenements from "../img/marquants.png";

const GameMap = [
	{
		gameId: 1,
		jeuPosition: " stage 1 / session 1",
		jeuName: "Evangiles / les Personnages",
		statutJeu:true,
		sectQuest:" stag1ses1",
	},
	{
		gameId: 2,
		jeuPosition: " stage 1 / session 2",
		jeuName: "Evangiles / les Lieux",
		statutJeu:false,
		sectQuest:" stag1ses2",
	},
	{
        gameId: 3,
		jeuPosition: " stage 1 / session 3",
		jeuName: "Evangiles / Les Institutions",
		statutJeu:false,
		sectQuest:" stag1ses3",
    },
	{
        gameId: 4,
		jeuPosition: " stage 1 / session 4",
		jeuName: "Evangiles / Les Lois",
		statutJeu:false,
		sectQuest:" stag1ses4",
    },
	{
        gameId: 5,
		jeuPosition: " stage 1 / session 5",
		jeuName: "Evangiles / Les évènements",
		statutJeu:false,
		sectQuest:" stag1ses5",
    },

	{
		gameId: 6,
		jeuPosition: " stage 2 / session 1",
		jeuName: "Eglise / les Personnages",
		statutJeu:false,
		sectQuest:" stag2ses1",
	},
	{
		gameId: 7,
		jeuPosition: " stage 2 / session 2",
		jeuName: "Eglise / les Lieux",
		statutJeu:false,
		sectQuest:" stag2ses2",
	},
	{
        gameId: 8,
		jeuPosition: " stage 2 / session 3",
		jeuName: "Eglise / Les Institutions",
		statutJeu:false,
		sectQuest:" stag2ses3"
    },
	{
        gameId: 9,
		jeuPosition: " stage 2 / session 4",
		jeuName: "Eglise / Les Lois",
		statutJeu:false,
		sectQuest:" stag2ses4"
    },
	{
        gameId: 10,
		jeuPosition: " stage 2 / session 5",
		jeuName: "Eglise / Les évènements",
		statutJeu:false,
		sectQuest:" stag2ses5"
    },

	{
		gameId: 11,
		jeuPosition: " stage 3 / session 1",
		jeuName: "Livre de Moïse / les Personnages",
		statutJeu:false,
		sectQuest:" stag3ses1"
	},
	{
		gameId: 12,
		jeuPosition: " stage 3 / session 2",
		jeuName: "Livre de Moïse / les Lieux",
		statutJeu:false,
		sectQuest:" stag3ses2"
	},
	{
        gameId: 13,
		jeuPosition: " stage 3 / session 3",
		jeuName: "Livre de Moïse / Les Institutions",
		statutJeu:false,
		sectQuest:" stag3ses3"
    },
	{
        gameId: 14,
		jeuPosition: " stage 3 / session 4",
		jeuName: "Livre de Moïse / Les Lois",
		statutJeu:false,
		sectQuest:" stag3ses4"
    },
	{
        gameId: 15,
		jeuPosition: " stage 3 / session 5",
		jeuName: "Livre de Moïse / Les évènements",
		statutJeu:false,
		sectQuest:" stag3ses5"
    },

	{
		gameId: 16,
		jeuPosition: " stage 4 / session 1",
		jeuName: "Nation d'Israël / les Personnages",
		statutJeu:false,
		sectQuest:" stag4ses1"
	},
	{
		gameId: 17,
		jeuPosition: " stage 4 / session 2",
		jeuName: "Nation d'Israël  / les Lieux",
		statutJeu:false,
		sectQuest:" stag4ses2"
	},
	{
        gameId: 18,
		jeuPosition: " stage 4 / session 3",
		jeuName: "Nation d'Israël  / Les Institutions",
		statutJeu:false,
		sectQuest:" stag4ses3"
    },
	{
        gameId: 19,
		jeuPosition: " stage 4 / session 4",
		jeuName: "Nation d'Israël  / Les Lois",
		statutJeu:false,
		sectQuest:" stag4ses4"
    },
	{
        gameId: 20,
		jeuPosition: " stage 4 / session 5",
		jeuName: "Nation d'Israël  / Les évènements",
		statutJeu:false,
		sectQuest:" stag4ses5"
    },

	{
		gameId: 21,
		jeuPosition: " stage 5 / session 1",
		jeuName: "L'Exile / les Personnages",
		statutJeu:false,
		sectQuest:" stag5ses1"
	},
	{
		gameId: 22,
		jeuPosition: " stage 5 / session 2",
		jeuName: "L'Exile / les Lieux",
		statutJeu:false,
		sectQuest:" stag5ses2"
	},
	{
        gameId: 23,
		jeuPosition: " stage 5 / session 3",
		jeuName: "L'Exile / Les Institutions",
		statutJeu:false,
		sectQuest:" stag5ses3"
    },
	{
        gameId: 24,
		jeuPosition: " stage 5 / session 4",
		jeuName: "L'Exile / Les Lois",
		statutJeu:false,
		sectQuest:" stag5ses4"
    },
	{
        gameId: 25,
		jeuPosition: " stage 5 / session 5",
		jeuName: "L'Exile / Les évènements",
		statutJeu:false,
		sectQuest:" stag5ses5"
    },

];

const Jeux = [
	{
		gameId: 1,
		jeuPosition: " stage 1 / session 1",
		sectQuest:" stag1ses1",
		jeuTitle:
			"Période de la Vie et du Ministère du Seigneur Jésus (Les Evangiles)",
		paraf1:
			" Dans les Evangiles, Jésus est présenté, comme le Messie des Ecritures, le Maitre, le Fils de l’homme et le Fils de Dieu. C’est ainsi que chacun des auteurs des quatre Evangiles le présentent respectivement. Dans ce Stage, on découvre ou redécouvre avec plaisir, la vie, les œuvres et l’enseignement de Jésus.",
		paraf2:
			"Le Stage 1 couvre une période de 30 ans et permettra au « joueur – stagiaire » de se familiariser, si besoin est, avec l’environnement politique et socio – religieux dans lequel les Ecrits du NT sont trempés. Et tout cela à travers 200 questions.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",
		stagImgSrc: stage1image,
		sectionTitle: "Evangiles / Les personnages",
		sectionImgSrc: personnages,
		questions: [
			
			{
				id: 1,
				text: "Hérode a été empereur au temps de Jésus ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 2,
				text: "Joseph, le fiancé de Marie était un des fils de Jacob  ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
		
			{
				id: 3,
				text: "Jean a été appelé Jean- Baptiste à cause de la mission d’appel à la repentance que Dieu lui avait confié  ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 4,
				text: "Marie la mère de Jésus était descendante de David  ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 5,
				text: "Jacques fils de Zébédée a été le deuxième martyr chrétien mentionné dans la Bible ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 6,
				text: "Jean, apôtre de Jésus, était aussi fils de Zébédée ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 7,
				text: "Pierre a été le premier à être nommé apôtre ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 8,
				text: "Parmi les apôtres, c’est André qui fut le premier à connaitre Jésus ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 9,
				text: "Caïphe a été grand prêtre en Israël, au temps de Jésus, pendant quatre ans ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 10,
				text: "Pilate était le roi Romain qui a condamné Jésus ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 11,
				text: "Nicodème fut cet homme à qui Jésus avait enseigné qu’il fallait naitre de nouveau pour entrer dans le Royaume de Dieu ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 12,
				text: "Zacharie, père de Jean – Baptiste devint sourd à cause du fait qu’il avait émis un doute lorsque l’ange Gabriel lui annonça qu’il aura un fils ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 13,
				text: "César Auguste était un titre que Octave a été le premier à porter ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 14,
				text: " Auguste a été empéreur au temps de Jésus et c'est lui qui ordona le recensement de tout l'empire  ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 15,
				text: "Thaddée était l’un des douze apôtre ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 16,
				text: "Tibère César fut le deuxième empereur de Rome et c’est sous son règne que Jean Baptiste prêcha ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 17,
				text: "Marie sœur de Marthe, était de Béthanie ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 18,
				text: "A Jérusalem, il y avait une femme nommée Marthe, dont Jésus avait ressuscité le frère appelé Lazare  ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 19,
				text: " La femme de laquelle Jésus chassa sept démons s’appelait Jeanne femme de Chuza ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 20,
				text: " Au temps de Jésus, il y avait un Grand prêtre nommé Anne ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 21,
				text: "Lequel de ces hommes a eu l’oreille coupée par Pierre et guérie par le Seigneur Jésus? ",
				options: [
					{ id: 0, text: "Maléléel", isCorrect: false },
					{ id: 1, text: "Malchus", isCorrect: true },
					{ id: 2, text: "Manahem", isCorrect: false },
				],
			},
			
			{
				id: 22,
				text: " Quand Jésus enfant fut présenté au temple, ils y trouvèrent une prophétesse très âgée qui y servait Dieu. Elle s’appelait : ",
				options: [
					{ id: 0, text: "Anne", isCorrect: true },
					{ id: 1, text: "Jeanne", isCorrect: false },
					{ id: 2, text: "Maethe", isCorrect: false },
				],
			},
			
			{
				id: 23,
				text: " Quel est cet homme juste et pieux, qui,  vint dans le Temple, prit l’enfant Jésus dans ses bras et bénit les parents de Jésus, puis loua Dieu ? ",
				options: [
					{ id: 0, text: "Siméon", isCorrect: true },
					{ id: 1, text: "Zacharie ", isCorrect: false },
					{ id: 2, text: "Samuel", isCorrect: false },
				],
			},
			
			{
				id: 24,
				text: " Comment s’appelait ce riche homme qui demanda le corps de Jésus à Pilate pour l’enterrer dans un sépulcre lui appartenant ? ",
				options: [
					{ id: 0, text: "Gamaliel ", isCorrect: false },
					{ id: 1, text: "Joseph d’Arimathée", isCorrect: true },
					{ id: 2, text: "Nicodème", isCorrect: false },
				],
			},
			
			{
				id: 25,
				text: " Parmi ces Hérode, lequel a voulu tuer Jésus quand il était bébé ? ",
				options: [
					{ id: 0, text: "Antipater", isCorrect: false },
					{ id: 1, text: "Agrippa", isCorrect: false },
					{ id: 2, text: "Hérode le Grand", isCorrect: true },
				],
			},
			
			{
				id: 26,
				text: " Parmi ces apôtres, lequel a été personnellement (« évangélisé ») appelé par Jésus à le suivre? ",
				options: [
					{ id: 0, text: "Philippe", isCorrect: true },
					{ id: 1, text: "Pierre", isCorrect: false },
					{ id: 2, text: "André", isCorrect: false },
				],
			},
			
			{
				id: 27,
				text: " Parmi ces apôtres, à qui a-t-il été dit : « Nous avons trouvé celui dont il est parlé dans la loi de Moïse et dans les prophètes, Jésus de Nazareth, fils de Joseph. » ",
				options: [
					{ id: 0, text: "Philippe", isCorrect: false },
					{ id: 1, text: "Thomas ", isCorrect: false },
					{ id: 2, text: "Nathanaël", isCorrect: true },
				],
			},
			
			{
				id: 28,
				text: " A laquelle des Marie, Le Seigneur Jésus est-il apparu, juste après sa résurrection ? ",
				options: [
					{ id: 0, text: "Marie de Magdala", isCorrect: true },
					{ id: 1, text: "Marie mère de Jésus ", isCorrect: false },
					{ id: 2, text: "Marie de Béthanie", isCorrect: false },
				],
			},
			
			{
				id: 29,
				text: " Parmi ces Hérode, devant lequel le Seigneur Jésus a-t-il comparu ? ",
				options: [
					{ id: 0, text: "Hérode Antipas ", isCorrect: true },
					{ id: 1, text: "Hérode Agrippa 1er  ", isCorrect: false },
					{ id: 2, text: "Hérode Agrippa 2", isCorrect: false },
				],
			},
			
			{
				id: 30,
				text: " Apôtre de Jésus, on pense souvent qu’il correspond à un des personnage (celui que Philippe a conduit à Jésus) : ",
				options: [
					{ id: 0, text: "Didyme", isCorrect: false },
					{ id: 1, text: "Thaddée", isCorrect: false },
					{ id: 2, text: "Nathanaël", isCorrect: true },
				],
			},
			
			{
				id: 31,
				text: " L’homme qui a dû porter la croix de Jésus, jusqu’au lieu de sa crucifixion, s’appelait : ",
				options: [
					{ id: 0, text: "Barrabas", isCorrect: false },
					{ id: 1, text: "Antonin le Pieux ", isCorrect: false },
					{ id: 2, text: "Simon", isCorrect: true },
				],
			},
			
			{
				id: 32,
				text: " Parmi ces apôtres, lequel ne porte pas le surnom de Boanergès (fils du tonnerre) ",
				options: [
					{ id: 0, text: "Jean", isCorrect: false },
					{ id: 1, text: "Jacques", isCorrect: false },
					{ id: 2, text: "Jude", isCorrect: true },
				],
			},
			
			{
				id: 33,
				text: " Parmi ces apôtres, lequel portait aussi le nom Didyme? ",
				options: [
					{ id: 0, text: "Thomas", isCorrect: true },
					{ id: 1, text: "Barthélémy", isCorrect: false },
					{ id: 2, text: "Philippe", isCorrect: false },
				],
			},
			
			{
				id: 34,
				text: " Lequel des Jaques était aussi appelé Jacques « le Jeune » ",
				options: [
					{ id: 0, text: "Jacques, fils d’Alphée", isCorrect: true },
					{ id: 1, text: "Jacques fils de Zébédée ", isCorrect: false },
					{ id: 2,text: "Jacques l’auteur du Livre de Jacques",isCorrect: false,},
				],
			},
			
			{
				id: 35,
				text: " Parmi ces apôtres lequel s’était désigné comme le disciple que Jésus aimait ? ",
				options: [
					{ id: 0, text: "Philippe", isCorrect: false },
					{ id: 1, text: "Jean ", isCorrect: true },
					{ id: 2, text: "Pierre", isCorrect: false },
				],
			},
			
			{
				id: 36,
				text: " Parmi ces apôtres lequel n’a pas vu Jésus ressuscité ? ",
				options: [
					{ id: 0, text: "Judas", isCorrect: true },
					{ id: 1, text: "Thomas", isCorrect: false },
					{ id: 2, text: "Jude", isCorrect: false },
				],
			},
			
			{
				id: 37,
				text: " Lebbée, Apôtre de Jésus, s’appelait aussi : ",
				options: [
					{ id: 0, text: "Jude", isCorrect: false },
					{ id: 1, text: "Thomas", isCorrect: false },
					{ id: 2, text: "Thaddée", isCorrect: true },
				],
			},
			
			{
				id: 38,
				text: " Luc, qu’est-ce qu’il n’était pas ? ",
				options: [
					{ id: 0, text: "Apôtre", isCorrect: true },
					{ id: 1, text: "Médecin", isCorrect: false },
					{ id: 2, text: "Compagnon de Paul", isCorrect: false },
				],
			},
			
			{
				d: 39,
				text: " Quel métier exerçait Mathieu avant de suivre Jésus ? ",
				options: [
					{ id: 0, text: "Faiseur de tente ", isCorrect: false },
					{ id: 1, text: "Collecteur d’impôt", isCorrect: true },
					{ id: 2, text: "Médecin ", isCorrect: false },
				],
			},
			
			{
				id: 40,
				text: " Lévi,  Disciple de Jésus, sous quel nom était-il le plus connu ? ",
				options: [
					{ id: 0, text: "Mathias", isCorrect: false },
					{ id: 1, text: "Mathieu", isCorrect: true },
					{ id: 2, text: "Marc", isCorrect: false },
				],
			},
		],
	},
	{
		gameId: 2,
		jeuPosition: " stage 1 / session 2",
		sectQuest:" stag1ses2",
		jeuTitle:
			"Période de la Vie et du Ministère du Seigneur Jésus (Les Evangiles)",
		paraf1:
			" Dans les Evangiles, Jésus est présenté, comme le Messie des Ecritures, le Maitre, le Fils de l’homme et le Fils de Dieu. C’est ainsi que chacun des auteurs des quatre Evangiles le présentent respectivement. Dans ce Stage, on découvre ou redécouvre avec plaisir, la vie, les œuvres et l’enseignement de Jésus.",
		paraf2:
			"Le Stage 1 couvre une période de 30 ans et permettra au « joueur – stagiaire » de se familiariser, si besoin est, avec l’environnement politique et socio – religieux dans lequel les Ecrits du NT sont trempés. Et tout cela à travers 200 questions.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",
		stagImgSrc: stage1image,
		sectionTitle: "Evangiles / Les Lieux",
		sectionImgSrc: lieux,
		questions: [
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 1,
				text: "Bethlehem : C’est le lieu de naissance de Jésus",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 2,
				text: "Judée : Région du Sud de la Palestine et à l’ouest de la mer morte ; Bethléem était une ville de cette région.",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 3,
				text: "Jerusalem: Capitale de la Palestine située en Galilée ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 4,
				text: "Nazareth : Ville de Judée où Jésus a grandi ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 5,
				text: "Naïn : C’est dans cette ville, au sud-est de Nazareth que Jésus ressuscita le fils unique d’une veuve ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 6,
				text: "Galilée : C’est une région située au nord de la Palestine ; où se situe la ville de Nazareth ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 7,
				text: "Jourdain : C’est le cours d’eau principal de Palestine, dans lequel Jean baptisait.",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 8,
				text: "Capernaüm : C’est un village de Judée où Jésus guérit le serviteur d’un centurion romain, qui était paralysé et y prêcha et fit d’autres miracles ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 9,
				text: "Golgotha : C’est un roc en forme de crâne près de Jérusalem.",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 10,
				text: "Gabbatha : Ce mot est traduit en grec « Pavé » et on y trouvait un tribunal, qui avait été utilisé par Pilate pour Juger Jésus. ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 11,
				text: "Gethsémané : C’est un jardin situé près de Jérusalem dans lequel Jésus se rendit une seule fois avec ses disciples, dans la nuit où il fut livré.",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 12,
				text: "Génésareth : C’était une plaine très fertile au bord de la mer qui porte aussi son nom .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 13,
				text: "Enon : C’est un lieu en Palestine où Jean baptisait parce qu’il y avait beaucoup d’eau.",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 14,
				text: "Béthanie : C’est dans ce village près de Jérusalem que vivaient Marie, Marthe et Lazare leur frère ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 15,
				text: "Bethphagé : C’est dans ce village que, peu de jours avant sa crucifixion, Jésus envoya quatre disciples chercher un ânon, sur lequel il entra à Jérusalem. ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 16,
				text: "Bethsaïda : Ville près de Capernaüm et au nord de Galilée, visitée par Jésus qui a loué leur foi face aux miracles dont ils ont été témoins ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 17,
				text: "Emmaüs : C’est un village situé à environ 11 Km de Jérusalem, sur le chemin duquel Jésus ressuscité prêcha à sept disciples",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 18,
				text: "Cana,C’est dans cette ville que Jésus fit son premier miracle relaté dans la Bible.",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 19,
				text: "Le torrent de Cédron était un ravin qui séparait Jérusalem du mont des Oliviers. ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 20,
				text: "Salim : C’est un lieu près d’Enon où Jean baptisait ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 21,
				text: "Lac de Galilée, est appelé aussi :",
				options: [
					{ id: 0, text: "Lac de la Gaulle ancienne", isCorrect: false },
					{ id: 1, text: "La mer des galiléens", isCorrect: false },
					{ id: 2, text: "Lac de Tibériade", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 22,
				text: "Rome était l’époque de Jésus :",
				options: [
					{
						id: 0,
						text: "Chef-lieu de la grande Province de Syrie",
						isCorrect: false,
					},
					{
						id: 1,
						text: "Capitale de la Galatie Phrygienne",
						isCorrect: false,
					},
					{ id: 2, text: "Capitale de l’Empire Romain", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 23,
				text: "Samarie : Laquelle des informations est fausse, ici, concernant cette localité ?",
				options: [
					{
						id: 0,
						text: "C’est une localité de juifs qui refusaient d’obéir à l’Eternel",
						isCorrect: true,
					},
					{
						id: 1,
						text: "C’est une région par laquelle ceux qui venaient du Nord pour aller à Jérusalem devaient passer",
						isCorrect: false,
					},
					{
						id: 2,
						text: "C’est dans cette région que se trouvait Sichar",
						isCorrect: false,
					},
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 24,
				text: "Sichar : Ce fut une ville de :",
				options: [
					{ id: 0, text: "Samarie", isCorrect: true },
					{ id: 1, text: "Galilée", isCorrect: false },
					{ id: 2, text: "l’Idumée", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux", */
			{
				id: 25,
				text: "Siloé : Ce fut un réservoir d’eau, ",
				options: [
					{
						id: 0,
						text: "Dans lequel Jésus guérit un malade depuis 38 ans",
						isCorrect: false,
					},
					{ id: 1, text: "Dans lequel Jésus fut baptisé", isCorrect: false },
					{
						id: 2,
						text: "Dans lequel Jésus envoya un aveugle de naissance s’y laver",
						isCorrect: true,
					},
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 26,
				text: "La tour de Siloé : quelle est la bonne information parmi les informations ci-dessous?",
				options: [
					{
						id: 0,
						text: "Une tour plus haute que celle de Babel",
						isCorrect: false,
					},
					{
						id: 1,
						text: "Une tour qui porte ce nom (Siloé) tomba sur 18 personnes et les tua",
						isCorrect: true,
					},
					{
						id: 2,
						text: "Une tour sur laquelle Jésus monta pour prêcher la Bonne Nouvelle",
						isCorrect: false,
					},
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 27,
				text: "Syrie : choisis la bonne information",
				options: [
					{
						id: 0,
						text: "La région au Nord de la Palestine, dont Cyrénius a été gouverneur",
						isCorrect: true,
					},
					{
						id: 1,
						text: "A l’époque de Jésus, un des rares territoires qui n’étaient pas occupés par les romains",
						isCorrect: false,
					},
					{ id: 2, text: "Pays d’origine du Sri -Lanka", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 28,
				text: "Mont des Oliviers : Dans quel passage biblique, il n’est pas mentionné",
				options: [
					{ id: 0, text: "Mathieu 9 : 1 -3", isCorrect: true },
					{ id: 1, text: "Mathieu 21 : 1 -3", isCorrect: false },
					{ id: 2, text: "Mathieu  26 : 30 - 31", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 29,
				text: "Tyr et Sidon : Ces deux localités sont mentionnées ensemble, en ",
				options: [
					{ id: 0, text: "Mathieu 5 : 8 ", isCorrect: false },
					{ id: 1, text: "Mathieu 16 : 13 ", isCorrect: false },
					{ id: 2, text: "Mathieu 11 : 21", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 30,
				text: "Lac de Tibériade : Le Lac ou la mère de Tibériade ou Tibérias, s’appelait aussi :",
				options: [
					{ id: 0, text: "Mer ou lac de Galilée ", isCorrect: true },
					{ id: 1, text: "Mer ou Lac de Tibère ", isCorrect: false },
					{ id: 2, text: "Mer ou Lac du Tibre", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 31,
				text: "La région des « Dix villes » : Cette région n’est pas aussi appelée :",
				options: [
					{ id: 0, text: "Dalmatie", isCorrect: true },
					{ id: 1, text: "Décapole", isCorrect: false },
					{ id: 2, text: "Décapolis", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 32,
				text: "Quelle est la ville d’origine de l’homme qui a été contraint de porter la croix de Jésus ?",
				options: [
					{ id: 0, text: "Alexandrie", isCorrect: false },
					{ id: 1, text: "Crète", isCorrect: false },
					{ id: 2, text: "Cyrène", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 33,
				text: "Dans quel pays les parents de Jésus ont dû fuir, avec lui, pour échapper à Hérode ?",
				options: [
					{ id: 0, text: "Rome ", isCorrect: false },
					{ id: 1, text: "Phrygie", isCorrect: false },
					{ id: 2, text: "Egypte", isCorrect: true },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 34,
				text:"La piscine de Bethesda : A ce réservoir d’eau, à Jérusalem, qu’est-ce qui ne s’est pas passé ?",
				options: [
					{
						id: 0,
						text: "L’eau bouillonnait de manière incroyable",
						isCorrect: true,
					},
					{
						id: 1,
						text: "Un infirme depuis 38 ans fut guéri",
						isCorrect: false,
					},
					{
						id: 2,
						text: "Tous les malades attendaient que l’eau s’agite",
						isCorrect: false,
					},
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 35,
				text: "Dans laquelle des ville, en Mathieu 16, Jésus a-t-il demandé à ses disciples : «…Au dire des gens, qui suis-je, moi, le Fils de l'homme ? »  ",
				options: [
					{ id: 0, text: "Césarée Maritime ", isCorrect: false },
					{ id: 1, text: "Césarée de Philippe ", isCorrect: true },
					{ id: 2, text: "Capernaüm", isCorrect: false },
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 36,
				text: "Béthanie : Qu’est ce qui n’est pas vrai, ici, concernant cette localité ?",
				options: [
					{ id: 0, text: "C’était le village de Pierre", isCorrect: true },
					{
						id: 1,
						text: "C’était le village de Marthe et Marie",
						isCorrect: false,
					},
					{
						id: 2,
						text: "Ce fut là -bas que Lazare a été ressuscité",
						isCorrect: false,
					},
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 37,
				text: "Gethsémané : qu’est-ce qui n’est pas vrai, ici ,  concernant ce lieu?",
				options: [
					{
						id: 0,
						text: "C’est là que Jésus, trahi par Judas fut arrêté",
						isCorrect: false,
					},
					{ id: 1, text: "C’est là que Pierre renia Jésus", isCorrect: true },
					{
						id: 2,
						text: "C’est là que Jésus et ses disciples se rendirent après son dernier repas de la Pâque",
						isCorrect: false,
					},
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 38,
				text: "Idumée : Choisis l’information vrai",
				options: [
					{
						id: 0,
						text: "A l’époque de Jésus, région occupée par les descendants d’Edom (ou Esaü) .",
						isCorrect: true,
					},
					{
						id: 1,
						text: "A l’époque de Jésus, région occupée par les descendants de Moab",
						isCorrect: false,
					},
					{
						id: 2,
						text: "A l’époque de Jésus, région occupée par les descendants de Issacar, fils de Jacob",
						isCorrect: false,
					},
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 39,
				text: "Bethléem : Qu’est- ce n’est pas vrai relativement à cette ville",
				options: [
					{
						id: 0,
						text: "Hérode y fit tuer les enfants âgés de plus d’un an",
						isCorrect: true,
					},
					{
						id: 1,
						text: "Des bergers y rendirent hommage à l’enfant Jésus",
						isCorrect: false,
					},
					{
						id: 2,
						text: "C’est la ville de naissance de Jésus",
						isCorrect: false,
					},
				],
			},
			/*stage1-section2 sectionQuest:"EvangLieux",, */
			{
				id: 40,
				text: "Tibérias : Choisis la vraie information",
				options: [
					{
						id: 0,
						text: "Nom donné à un des fils de Tibère",
						isCorrect: false,
					},
					{ id: 1, text: "Ville située au bord du Tibre", isCorrect: false },
					{
						id: 2,
						text: "Ville au sud - ouest de la mer de Tibérias",
						isCorrect: true,
					},
				],
			},
		],
	},
	{
		gameId: 3,
		jeuPosition: " stage 1 / session 3",
		sectQuest:" stag1ses3",
		jeuTitle:
			"Période de la Vie et du Ministère du Seigneur Jésus (Les Evangiles)",
		paraf1:
			" Dans les Evangiles, Jésus est présenté, comme le Messie des Ecritures, le Maitre, le Fils de l’homme et le Fils de Dieu. C’est ainsi que chacun des auteurs des quatre Evangiles le présentent respectivement. Dans ce Stage, on découvre ou redécouvre avec plaisir, la vie, les œuvres et l’enseignement de Jésus.",
		paraf2:
			"Le Stage 1 couvre une période de 30 ans et permettra au « joueur – stagiaire » de se familiariser, si besoin est, avec l’environnement politique et socio – religieux dans lequel les Ecrits du NT sont trempés. Et tout cela à travers 200 questions.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",
		stagImgSrc: stage1image,
		sectionTitle: "Evangiles / Les Institutions",
		sectionImgSrc: institutions,
		questions: [
			/*stage1-section3 sectionQuest:"EvangInstitutions", */
			{
				id: 1,
				text: "Le Souverain Sacrificateur, c’est le Sacrificateur le plus âgé ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 2,
				text: "Les Hérodiens sont les membres de la famille d’Hérode ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 3,
				text: "Les Zélotes étaient un groupe de juifs très zélés pour le Judaïsme .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 4,
				text: "Les Paraboles étaient des fables juives pour enseigner",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 5,
				text: "La Croix était le mode de châtiment à l’encontre des juifs qui se révoltaient contre Rome .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 6,
				text: "La flagellation était le mode de punition pour ceux qui manquaient de respect au Grand -Prêtre .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 7,
				text: "L’Esprit de Vérité et le Consolateur désignent tous deux la même personne.",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section, */
			{
				id: 8,
				text: "« Rabbi » est un mot grec qui désigne un maitre qui enseigne .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 9,
				text: "Le premier message de Jésus , en Mathieu, fut un appel à la Repentance ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section, */
			{
				id: 10,
				text: "Le mot « Christ » est synonyme de Messie .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section, */
			{
				id: 11,
				text: "« Messie « est le terme grec pour parler du Christ .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 12,
				text: "Le Fils désigne Jésus dans son adoption par le Père .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 13,
				text: "Les pharisiens et Saducéens ne s’attendaient pas sur la résurrection des morts .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section, */
			{
				id: 14,
				text: "Les Saducéens, comme tout membre du peuple de Dieu, croyaient à la résurrection des morts .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 15,
				text: "Le baptême est un rite purement chrétien, car c’est Jésus qui l’a institué .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 16,
				text: "Beaucoup de juifs sondaient les Ecritures parce qu’ils pensaient trouver en Elles la vie Eternelle.",
				options: [
					{ id: 0, text: "Vrai", isCorrect: true },
					{ id: 1, text: "Faux", isCorrect: false },
				],
			},
			/*stage1-section, */
			{
				id: 17,
				text: "Le Judaïsme est la plus ancienne religion de ce monde .",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 18,
				text: "« Fils de l’homme » : C’est dans les Evangiles qu’apparait, pour la première fois cette appellation, surtout dans l’Évangile de Luc. ",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 19,
				text: "Le Sanhedrin, composé de sacrificateurs, de scribes et d’anciens était dirigé par un sacrificateur, pendant trois ans",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 20,
				text: "Les Juifs, c’étaient tous ceux qui avaient adopté le Judaïsme comme leur religion.",
				options: [
					{ id: 0, text: "Vrai", isCorrect: false },
					{ id: 1, text: "Faux", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 21,
				text: "Ce qui n’est pas vrai pour la Synagogue",
				options: [
					{ id: 0, text: "Assemblée religieuse des juifs", isCorrect: false },
					{
						id: 1,
						text: "Le lieu où se  réunissait cette assemblée religieuse",
						isCorrect: false,
					},
					{
						id: 2,
						text: "Elle était composée d’au maximum 70 membres",
						isCorrect: true,
					},
				],
			},
			/*stage1-section, */
			{
				id: 22,
				text: "Qu’est-ce n’est pas vrai pour Pâque",
				options: [
					{
						id: 0,
						text: "Ce fut la première fête à l’Eternel",
						isCorrect: false,
					},
					{
						id: 1,
						text: "Ce terme vient du mot Hébreux « pascha » qui signifie «  passer par-dessus »",
						isCorrect: false,
					},
					{
						id: 2,
						text: "Elle était célébrée dans les Evangiles, pour commémorer la résurrection de Jésus",
						isCorrect: true,
					},
				],
			},
			/*stage1-section, */
			{
				id: 23,
				text: "Eglise : Ce mot est utilisé pour la Première fois en",
				options: [
					{ id: 0, text: "Matthieu 16", isCorrect: true },
					{ id: 1, text: "Marc 16", isCorrect: false },
					{ id: 2, text: "Luc 16", isCorrect: false },
				],
			},
			/*stage1-section, */
			{
				id: 24,
				text: "Qu’est-ce qui n’est pas vrai concernant les Scribes",
				options: [
					{
						id: 0,
						text: "C’étaient des Pharisiens ou Saducéens qui étudiaient les Ecritures et les Enseignaient",
						isCorrect: true,
					},
					{
						id: 1,
						text: "Ils faisaient quelques fois office de Juristes",
						isCorrect: false,
					},
					{
						id: 2,
						text: "Ils étudiaient la Loi et l’enseignaient au peuple juif",
						isCorrect: false,
					},
				],
			},
			/*stage1-section, */
			{
				id: 25,
				text: "Le mot grec traduit par Consolateur est le mot :",
				options: [
					{ id: 0, text: "Charisma ", isCorrect: false },
					{ id: 1, text: "Pneuma", isCorrect: false },
					{ id: 2, text: "Paraklétos", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 26,
				text: "« Adorer en Esprit et en Vérité » :  Cette notion a été employé en :",
				options: [
					{ id: 0, text: "Jean 3 : 1 - 5 ", isCorrect: false },
					{ id: 1, text: "Jean 4 :23 - 24 ", isCorrect: true },
					{ id: 2, text: "Jean 14 : 17 - 18", isCorrect: false },
				],
			},
			/*stage1-section, */
			{
				id: 27,
				text: "Qu’est – ce qui n’est pas l’Espérance chrétienne dans les définitions suivantes?",
				options: [
					{
						id: 0,
						text: "C’est une attente heureuse et confiante",
						isCorrect: false,
					},
					{
						id: 1,
						text: "Elle s’appuie sur les promesses certaines du Dieu qui ne ment pas",
						isCorrect: false,
					},
					{
						id: 2,
						text: "Elle est la ferme assurance des choses qu’on espère et la démonstration de celles qu’on ne voit pas",
						isCorrect: true,
					},
				],
			},
			/*stage1-section, */
			{
				id: 28,
				text: "(Car la Parole de Dieu est vivante et efficace) : Cette Ecriture se trouve en:",
				options: [
					{ id: 0, text: "2 Timothée 3 : 12 -13", isCorrect: false },
					{ id: 1, text: "Jean 1 : 1 ", isCorrect: false },
					{ id: 2, text: "Hébreux 4 : 12", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 29,
				text: "Parmi ces chapitres des Evangiles, lequel parle le plus du Berger",
				options: [
					{ id: 0, text: "Mathieu 25 ", isCorrect: false },
					{ id: 1, text: "Marc 14 ", isCorrect: false },
					{ id: 2, text: "Jean 10", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 30,
				text: "Qu’est-ce qui n’est pas vrai concernant les Miracles ?",
				options: [
					{
						id: 0,
						text: "Les miracles sont des faits qui ont un caractère surnaturel",
						isCorrect: false,
					},
					{
						id: 1,
						text: "C‘est la marque d’une grande spiritualité",
						isCorrect: true,
					},
					{
						id: 2,
						text: "Ils confirmaient les paroles de Jésus et ses apôtres et aussi l’approbation de Dieu",
						isCorrect: false,
					},
				],
			},
			/*stage1-section, */
			{
				id: 31,
				text: "Parmi les passages bibliques (ci-dessous), dans lequel l’ascension de Jésus n’est pas relatée ?",
				options: [
					{ id: 0, text: "Marc 16 : 19 ", isCorrect: false },
					{ id: 1, text: "Luc 24 : 51 ", isCorrect: false },
					{ id: 2, text: "Jean 21 : 12", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 32,
				text: "Parmi ces arguments suivants, lequel Paul a -t-il utilisé pour prouver qu’il était un apôtre ?",
				options: [
					{ id: 0, text: "Il était d’origine juive", isCorrect: false },
					{
						id: 1,
						text: "Il a fait beaucoup de sacrifice pour Dieu",
						isCorrect: false,
					},
					{
						id: 2,
						text: "Lui aussi avait vu Jésus dans sa gloire après son ascension",
						isCorrect: true,
					},
				],
			},
			/*stage1-section, */
			{
				id: 33,
				text: "Ce qui n’est pas « Amen »",
				options: [
					{
						id: 0,
						text: "Ce qui est sûr et digne de confiance",
						isCorrect: false,
					},
					{ id: 1, text: "Une réponse de bénédiction", isCorrect: true },
					{
						id: 2,
						text: "Un Titre de Jésus lui-même dans Apocalypse",
						isCorrect: false,
					},
				],
			},
			/*stage1-section, */
			{
				id: 34,
				text: "Ce qu’est le sein d’Abraham, dans la pensée hébraïque ",
				options: [
					{
						id: 0,
						text: "Le tombeau qu’il a acheté aux Cananéens et où il a enseveli Sarah",
						isCorrect: false,
					},
					{
						id: 1,
						text: "La place heureuse des croyants après la mort, en entendant la résurrection",
						isCorrect: true,
					},
					{
						id: 2,
						text: "Le lieu de repos des juifs descendants d’Abraham",
						isCorrect: false,
					},
				],
			},
			/*stage1-section, */
			{
				id: 35,
				text: "Combien de personnes (relaté dans les Evangiles) Jésus a-t-il ressuscitées durant son ministère sur terre ?",
				options: [
					{ id: 0, text: "Sept", isCorrect: false },
					{ id: 1, text: "Deux", isCorrect: false },
					{ id: 2, text: "Trois", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 36,
				text: "Naitre de nouveau, selon les Evangiles, c’est :",
				options: [
					{ id: 0, text: "Naitre d’eau et d’esprit", isCorrect: true },
					{ id: 1, text: "Commencer à aller à l’Eglise", isCorrect: false },
					{ id: 2, text: "Devenir de plus en plus croyant", isCorrect: false },
				],
			},
			/*stage1-section, */
			{
				id: 37,
				text: "Parmi ces trois auteurs de l’Evangile, lequel n’était pas apôtre ?",
				options: [
					{ id: 0, text: "Marc", isCorrect: true },
					{ id: 1, text: "Matthieu", isCorrect: false },
					{ id: 2, text: "Jean", isCorrect: false },
				],
			},
			/*stage1-section, */
			{
				id: 38,
				text: "Le terme « Chemin d’un Sabbat » est une distance d’environ :",
				options: [
					{ id: 0, text: "700 m ou 0.43 miles", isCorrect: false },
					{ id: 1, text: "1000 m ou 0.62 miles", isCorrect: false },
					{ id: 2, text: "1100 m ou 0.68 miles", isCorrect: true },
				],
			},
			/*stage1-section, */
			{
				id: 39,
				text: "Ce qui n’est pas l’Evangile :",
				options: [
					{ id: 0, text: "La biographie de Jésus", isCorrect: true },
					{ id: 1, text: "La Bonne nouvelle", isCorrect: false },
					{
						id: 2,
						text: "Le compte rendu de la vie et de l’œuvre de Jésus, par des témoins ou des personnes aidés par des témoins",
						isCorrect: false,
					},
				],
			},
			/*stage1-section, */
			{
				id: 40,
				text: "Parmi ces trois auteurs de l’Evangile, lequel n’était pas apôtre ?",
				options: [
					{ id: 0, text: "Mathieu", isCorrect: false },
					{ id: 1, text: "Luc", isCorrect: true },
					{ id: 2, text: "Jean", isCorrect: false },
				],
			},
		],
	},
	{
		gameId: 4,
		jeuPosition: " stage 1 / session 4",
		sectQuest:" stag1ses4",
		jeuTitle:
			"Période de la Vie et du Ministère du Seigneur Jésus (Les Evangiles)",
		paraf1:
			" Dans les Evangiles, Jésus est présenté, comme le Messie des Ecritures, le Maitre, le Fils de l’homme et le Fils de Dieu. C’est ainsi que chacun des auteurs des quatre Evangiles le présentent respectivement. Dans ce Stage, on découvre ou redécouvre avec plaisir, la vie, les œuvres et l’enseignement de Jésus.",
		paraf2:
			"Le Stage 1 couvre une période de 30 ans et permettra au « joueur – stagiaire » de se familiariser, si besoin est, avec l’environnement politique et socio – religieux dans lequel les Ecrits du NT sont trempés. Et tout cela à travers 200 questions.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",
		stagImgSrc: stage1image,
		sectionTitle: "Evangiles / Les Lois",
		sectionImgSrc: lois,
		questions: [
			{
				id: 1,
				text: " La Parabole de «la Maison bâtie sur le roc » se trouve en ",
				options: [
					{ id: 0, text: "Mathieu 12 :24- 27", isCorrect: false },
					{ id: 1, text: "Mathieu 16 :24- 27", isCorrect: false },
					{ id: 2, text: "Mathieu 7 :24- 27", isCorrect: true },
				],
			},
			{
				id: 2,
				text: "La « parabole du Semeur » est racontée en ",
				options: [
					{ id: 0, text: "Mathieu 13", isCorrect: true },
					{ id: 1, text: "Mathieu 22", isCorrect: false },
					{ id: 2, text: "Mathieu 25", isCorrect: true },
				],
			},

			{
				id: 3,
				text: "La « parabole du fils prodigue » est racontée en ",
				options: [
					{ id: 0, text: "Luc 7", isCorrect: false },
					{ id: 1, text: "Luc 15", isCorrect: true },
					{ id: 2, text: "Luc 18", isCorrect: false },
				],
			},

			{
				id: 4,
				text: "La phrase du Seigneur -va et désormais ne pèche plus- se trouve en :",
				options: [
					{ id: 0, text: "Mathieu 8", isCorrect: false },
					{ id: 1, text: "Jean 8", isCorrect: true },
					{ id: 2, text: "Luc 8", isCorrect: false },
				],
			},

			{
				id: 5,
				text: "La parabole du « bâtisseur sage et du bâtisseur fou » est racontée en…",
				options: [
					{ id: 0, text: "Mathieu 5 : 1- 8", isCorrect: false },
					{ id: 1, text: "Mathieu 7 : 24 – 27 ", isCorrect: true },
					{ id: 2, text: "Mathieu 13 : 3- 23", isCorrect: false },
				],
			},

			{
				id: 6,
				text: "La parabole du « vin nouveau et de la vieille outre » est racontée en…",
				options: [
					{ id: 0, text: "Mathieu 13 : 30 – 31", isCorrect: false },
					{ id: 1, text: "Marc 4 : 30 – 32", isCorrect: false },
					{ id: 2, text: "Mathieu 9 : 17", isCorrect: true },
				],
			},

			{
				id: 7,
				text: "La parabole de « la mauvaise herbe » est racontée en …",
				options: [
					{ id: 0, text: "Mathieu 13 : 1 – 2", isCorrect: false },
					{ id: 1, text: "Mathieu 13 : 3- 23", isCorrect: false },
					{ id: 2, text: "Mathieu 13 : 24 - 30", isCorrect: true },
				],
			},

			{
				id: 8,
				text: "« donne à César ce qui est César et Dieu, ce qui est Dieu » est dit en …",
				options: [
					{ id: 0, text: "Mathieu 7: 7 - 11", isCorrect: false },
					{ id: 1, text: "Mathieu 10: 8 - 10", isCorrect: false },
					{ id: 2, text: "Mathieu 22: 15 - 21", isCorrect: true },
				],
			},

			{
				id: 9,
				text: "La parabole « des deux fils » est racontée en… ",
				options: [
					{ id: 0, text: "Mathieu 7: 24 - 27", isCorrect: false },
					{ id: 1, text: "Mathieu 21: 28 - 32", isCorrect: true },
					{ id: 2, text: "Mathieu 23: 15 - 35", isCorrect: false },
				],
			},
			{
				id: 10,
				text: "Le sermon sur la montagne se trouve en ",
				options: [
					{ id: 0, text: "Mathieu 13 à 15", isCorrect: false },
					{ id: 1, text: "Mathieu 3 à 5", isCorrect: false },
					{ id: 2, text: "Mathieu 5 à 7", isCorrect: true },
				],
			},
			/*stage1-sectio */
			{
				id: 11,
				text: "Tout ce que vous voulez que les hommes fassent pour vous, vous aussi, faites-le de même pour eux, car c'est la loi et les prophètes.",
				options: [
					{ id: 0, text: "Mathieu 7 : 12", isCorrect: true },
					{ id: 1, text: "Marc 7 : 12", isCorrect: false },
					{ id: 2, text: "Jean 7 : 12", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 12,
				text: "«Entrez par la porte étroite car large [[est la porte]] et spacieux le chemin qui mènent à la perdition, et il y en a beaucoup qui entrent par là. » ",
				options: [
					{ id: 0, text: "Mathieu 3: 13", isCorrect: false },
					{ id: 1, text: "Mathieu 7: 13", isCorrect: true },
					{ id: 2, text: "Mathieu 17: 13", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 13,
				text: "« Car là où est votre trésor, là aussi sera votre cœur. »",
				options: [
					{ id: 0, text: "Luc 12 : 34", isCorrect: true },
					{ id: 1, text: "Luc 1 : 34", isCorrect: false },
					{ id: 2, text: "Luc 2 : 34", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 14,
				text: "« Qui de vous, par ses inquiétudes, peut ajouter une coudée à la durée de sa vie. »",
				options: [
					{ id: 0, text: "Luc 2: 25", isCorrect: false },
					{ id: 1, text: "Luc 11: 25", isCorrect: false },
					{ id: 2, text: "Luc 12: 25", isCorrect: true },
				],
			},
			/*stage1-sectio */
			{
				id: 15,
				text: "Celui qui n'est pas avec moi est contre moi, et celui qui n'assemble pas avec moi, disperse. ",
				options: [
					{ id: 0, text: "Mathieu 2: 30", isCorrect: false },
					{ id: 1, text: "Mathieu 22: 30 ", isCorrect: false },
					{ id: 2, text: "Mathieu 12: 30", isCorrect: true },
				],
			},
			/*stage1-sectio */
			{
				id: 16,
				text: "L’enseignement sur la prière se trouve en :",
				options: [
					{ id: 0, text: "Mathieu 11", isCorrect: false },
					{ id: 1, text: "Luc 11", isCorrect: true },
					{ id: 2, text: "Jean 11", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 17,
				text: "Je vous donne un commandement nouveau : Aimez-vous les uns les autres ; comme je vous ai aimés, vous aussi, aimez-vous les uns les autres.",
				options: [
					{ id: 0, text: "Jean 11: 34", isCorrect: false },
					{ id: 1, text: "Jean 13: 34", isCorrect: true },
					{ id: 2, text: "Jean 15: 34", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 18,
				text: "Mais il répondit : Heureux plutôt ceux qui écoutent la parole de Dieu et qui la gardent ! ",
				options: [
					{ id: 0, text: "Luc 3: 28", isCorrect: false },
					{ id: 1, text: "Luc 11: 28", isCorrect: true },
					{ id: 2, text: "Luc 17: 28", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 19,
				text: "Et Jésus dit : Laissez les petits enfants, et ne les empêchez pas de venir à moi ; car le royaume des cieux est pour leurs pareils. ",
				options: [
					{ id: 0, text: "Mathieu 14: 19", isCorrect: false },
					{ id: 1, text: "Mathieu 19: 14", isCorrect: true },
					{ id: 2, text: "Mathieu 9: 14", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 20,
				text: "Alors il s'assit, appela les douze et leur dit : Si quelqu'un veut être le premier, qu'il soit le dernier de tous et le serviteur de tous",
				options: [
					{ id: 0, text: "Marc 7: 35", isCorrect: false },
					{ id: 1, text: "Marc 9: 35", isCorrect: true },
					{ id: 2, text: "Marc 11: 35", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 21,
				text: "C'est l'Esprit qui vivifie. La chair ne sert de rien. Les paroles que je vous ai dites sont Esprit et vie. ",
				options: [
					{ id: 0, text: "Jean 6: 63", isCorrect: true },
					{ id: 1, text: "Jean 16: 34", isCorrect: false },
					{ id: 2, text: "Jean 7: 34", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 22,
				text: "Jean répondit : Un homme ne peut recevoir que ce qui lui a été donné du ciel. ",
				options: [
					{ id: 0, text: "Jean 3: 27", isCorrect: true },
					{ id: 1, text: "Jean 3: 17", isCorrect: false },
					{ id: 2, text: "Jean 3: 16", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 23,
				text: "La polémique sur L’impôt à César se trouve en :",
				options: [
					{ id: 0, text: "Mathieu 15: 21 - 22", isCorrect: false },
					{ id: 1, text: "Mathieu 21: 15 - 21", isCorrect: false },
					{ id: 2, text: "Mathieu 22: 15 - 21", isCorrect: true },
				],
			},

			/*stage1-sectio */
			{
				id: 24,
				text: "Le plus grand commandement est donné en : ",
				options: [
					{ id: 0, text: "Mathieu 22: 34 - 40 ", isCorrect: true },
					{ id: 1, text: "Mathieu 22: 15 - 21", isCorrect: false },
					{ id: 2, text: "Mathieu 12: 34 - 40", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 25,
				text: "La fin des temps est racontée en :  ",
				options: [
					{ id: 0, text: "Mathieu 24", isCorrect: true },
					{ id: 1, text: "Marc 4", isCorrect: false },
					{ id: 2, text: "Luc 24", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 26,
				text: "Les Paraboles des dix vierges et des celle des talents sont racontées en : ",
				options: [
					{ id: 0, text: "Mathieu 21", isCorrect: false },
					{ id: 1, text: "Mathieu 23", isCorrect: false },
					{ id: 2, text: "Mathieu 25 : 13", isCorrect: true },
				],
			},
			/*stage1-sectio */
			{
				id: 27,
				text: "L’enseignement sur le fait de « Couper les sources de tentation » se trouve en : ",
				options: [
					{ id: 0, text: "Marc 9 : 42 - 48", isCorrect: true },
					{ id: 1, text: "Marc 11 : 42 - 48", isCorrect: false },
					{ id: 2, text: "Marc 14 : 42 - 48", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 28,
				text: "L’Enseignement sur le divorce se trouve en : ",
				options: [
					{ id: 0, text: "Marc 7 : 1 - 12", isCorrect: false },
					{ id: 1, text: "Marc 10 : 1 - 12", isCorrect: true },
					{ id: 2, text: "Marc 12 : 28 - 30", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 29,
				text: "L’enseignement sur la récompense pour ceux qui quitte tout pour Jésus se trouve en :",
				options: [
					{ id: 0, text: "Marc 1: 28 - 30", isCorrect: false },
					{ id: 1, text: "Marc 10: 28 - 30", isCorrect: true },
					{ id: 2, text: "Marc 12: 28 - 30", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 30,
				text: "«Jésus dit alors aux Juifs qui avaient cru en lui : Si vous demeurez dans ma parole, vous êtes vraiment mes disciples ;vous connaîtrez la vérité et la vérité vous rendra libres.»",
				options: [
					{ id: 0, text: "Jean 8: 1 - 2", isCorrect: false },
					{ id: 1, text: "Jean 8: 31 - 32", isCorrect: true },
					{ id: 2, text: "Jean 18: 31 - 32", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 31,
				text: "Le voleur ne vient que pour voler et tuer et détruire ; moi, je suis venu, afin que les brebis aient la vie et qu'elles l'aient en abondance.",
				options: [
					{ id: 0, text: "Jean 10: 10", isCorrect: true },
					{ id: 1, text: "Jean 1: 10", isCorrect: false },
					{ id: 2, text: "Jean 20: 10", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 32,
				text: "Donnez, et l'on vous donnera : on versera dans votre sein une bonne mesure, serrée, secouée et qui déborde ; car on vous mesurera avec la mesure dont vous mesurez.",
				options: [
					{ id: 0, text: "Luc 6: 18", isCorrect: true },
					{ id: 1, text: "Luc 8: 38", isCorrect: false },
					{ id: 2, text: "Luc 16 : 38", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 33,
				text: "Puis il dit à tous : Si quelqu'un veut venir après moi, qu'il renonce à lui-même, qu'il se charge chaque jour de sa croix et qu'il me suive",
				options: [
					{ id: 0, text: "Luc 19 : 23", isCorrect: false },
					{ id: 1, text: "Luc 9: 23", isCorrect: true },
					{ id: 2, text: "Luc 23: 9", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 34,
				text: "Car Dieu a tant aimé le monde qu'il a donné son Fils unique, afin que quiconque croit en lui ne périsse pas, mais qu'il ait la vie éternelle.",
				options: [
					{ id: 0, text: "Jean 1: 16", isCorrect: false },
					{ id: 1, text: "Jean 16: 3", isCorrect: false },
					{ id: 2, text: "Jean 3: 16", isCorrect: true },
				],
			},
			/*stage1-sectio */
			{
				id: 35,
				text: "Jésus lui répondit : En vérité, en vérité je te le dis, si un homme ne naît de nouveau il ne peut voir le royaume de Dieu",
				options: [
					{ id: 0, text: "Jean 3: 3", isCorrect: true },
					{ id: 1, text: "Jean 5: 5", isCorrect: false },
					{ id: 2, text: "Jean 7: 7", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 36,
				text: "Mais l'heure vient - et c'est maintenant - où les vrais adorateurs adoreront le Père en esprit et en vérité ; car ce sont de tels adorateurs que le Père recherche",
				options: [
					{ id: 0, text: "Jean 4: 23", isCorrect: true },
					{ id: 1, text: "Jean 14: 23", isCorrect: false },
					{ id: 2, text: "Jean 3: 23", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 37,
				text: "Jésus dit à ses disciples : Il est impossible qu'il n'arrive pas des occasions de chute, mais malheur à celui par qui elles arrivent !",
				options: [
					{ id: 0, text: "Luc 13 : 1", isCorrect: false },
					{ id: 1, text: "Luc 15: 1", isCorrect: false },
					{ id: 2, text: "Luc 17: 1", isCorrect: true },
				],
			},
			/*stage1-sectio */
			{
				id: 38,
				text: "Le ciel et la terre passeront, mais mes paroles ne passeront point",
				options: [
					{ id: 0, text: "Luc 21: 21", isCorrect: false },
					{ id: 1, text: "Luc 21: 1", isCorrect: false },
					{ id: 2, text: "Luc 21: 33", isCorrect: true },
				],
			},
			/*stage1-sectio */
			{
				id: 39,
				text: "Qui de vous, par ses inquiétudes, peut ajouter une coudée à la durée de sa vie",
				options: [
					{ id: 0, text: "Luc 7 : 25", isCorrect: false },
					{ id: 1, text: "Luc 12: 25", isCorrect: true },
					{ id: 2, text: "Luc 13: 25", isCorrect: false },
				],
			},
			/*stage1-sectio */
			{
				id: 40,
				text: "Jésus a fait encore, en présence de ses disciples, beaucoup d'autres miracles qui ne sont pas écrits dans ce livre.Mais ceci est écrit afin que vous croyiez que Jésus est le Christ, le Fils de Dieu,...",
				options: [
					{ id: 0, text: "Jean 20: 1 - 2", isCorrect: false },
					{ id: 1, text: "Jean 20: 30 - 31", isCorrect: true },
					{ id: 2, text: "Jean 21: 24 - 25", isCorrect: false },
				],
			},
		],
	},
	{
		gameId: 5,
		jeuPosition: " stage 1 / session 5",
		sectQuest:" stag1ses5",
		jeuTitle:
			"Période de la Vie et du Ministère du Seigneur Jésus (Les Evangiles)",
		paraf1:
			" Dans les Evangiles, Jésus est présenté, comme le Messie des Ecritures, le Maitre, le Fils de l’homme et le Fils de Dieu. C’est ainsi que chacun des auteurs des quatre Evangiles le présentent respectivement. Dans ce Stage, on découvre ou redécouvre avec plaisir, la vie, les œuvres et l’enseignement de Jésus.",
		paraf2:
			"Le Stage 1 couvre une période de 30 ans et permettra au « joueur – stagiaire » de se familiariser, si besoin est, avec l’environnement politique et socio – religieux dans lequel les Ecrits du NT sont trempés. Et tout cela à travers 200 questions.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",
		stagImgSrc: stage1image,
		sectionTitle: "Evangiles / Les évènements marquants",
		sectionImgSrc: evenements,
		questions: [
			{
				id: 1,
				text: "Le récit de la « transfiguration » se trouve aussi en :",
				options: [
					{ id: 0, text: "Mathieu 17", isCorrect: true },
					{ id: 1, text: "Mathieu 7", isCorrect: false },
					{ id: 2, text: "Mathieu 1", isCorrect: false },
				],
			},
			{
				id: 2,
				text: "Le récit de l’Institution de la cène se trouve en :",
				options: [
					{ id: 0, text: "Mathieu 17 :26 - 29", isCorrect: false },
					{ id: 1, text: "Mathieu 20: 17 - 26", isCorrect: false },
					{ id: 2, text: "Mathieu 26: 17 - 29", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 3,
				text: "Le récit de Jésus qui marche sur les eaux se trouve aussi en :",
				options: [
					{ id: 0, text: "Mathieu 7", isCorrect: false },
					{ id: 1, text: "Mathieu 14", isCorrect: true },
					{ id: 2, text: "Marc 7", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 4,
				text: "Le récit de la naissance de Jésus  se trouve aussi en :",
				options: [
					{ id: 0, text: "Mathieu 1: 1 - 17", isCorrect: false },
					{ id: 1, text: "Mathieu 2: 1 - 12 ", isCorrect: true },
					{ id: 2, text: "Mathieu 3: 1 - 17", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 5,
				text: "La prédication de Jean Baptiste est relatée en :",
				options: [
					{ id: 0, text: "Mathieu 3: 1 - 12", isCorrect: true },
					{ id: 1, text: "Mathieu 4: 17 - 21 ", isCorrect: false },
					{ id: 2, text: "Mathieu 4: 13 - 16", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 6,
				text: "Le récit du baptême de Jésus se trouve en : ",
				options: [
					{ id: 0, text: "Mathieu 3: 13 - 17", isCorrect: true },
					{ id: 1, text: "Mathieu 4: 12 -17", isCorrect: false },
					{ id: 2, text: "Mathieu 5: 13 -16", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 7,
				text: "Le premier miracle public de Jésus est raconté en : »?",
				options: [
					{ id: 0, text: "Jean 1", isCorrect: false },
					{ id: 1, text: "Jean 3", isCorrect: false },
					{ id: 2, text: "Jean 2", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 8,
				text: "Le récit de « la pêche miraculeuse » se trouve en :",
				options: [
					{ id: 0, text: "Luc 3", isCorrect: false },
					{ id: 1, text: "Luc 5", isCorrect: true },
					{ id: 2, text: "Luc 6", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 9,
				text: "Le récit du choix des douze apôtres se trouve",
				options: [
					{ id: 0, text: "Luc 6: 12 ", isCorrect: true },
					{ id: 1, text: "Luc 12: 6", isCorrect: false },
					{ id: 2, text: "Luc 4: 6", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 10,
				text: "La fuite des parents de Jésus en Egypte est racontée:",
				options: [
					{ id: 0, text: "Mathieu 1: 18 - 25", isCorrect: false },
					{ id: 1, text: "Mathieu 2: 13 - 15", isCorrect: true },
					{ id: 2, text: "Mathieu 3: 1 - 8", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 11,
				text: "L’histoire de Gethsémané est racontée en…",
				options: [
					{ id: 0, text: "Mathieu 26 : 6 - 13", isCorrect: false },
					{ id: 1, text: "Mathieu 26 : 36 – 46", isCorrect: true },
					{ id: 2, text: "Mathieu 26 : 57 – 6", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 12,
				text: "Le sermon sur la montagne se trouve en ",
				options: [
					{ id: 0, text: "Mathieu 13 – 15", isCorrect: false },
					{ id: 1, text: "Mathieu 3- 4", isCorrect: false },
					{ id: 2, text: "Mathieu 5 -7", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 13,
				text: "Le récit de Jésus, à douze ans dans le temple, se trouve en :",
				options: [
					{ id: 0, text: "Luc 2 : 41 - 52", isCorrect: true },
					{ id: 1, text: "Luc 3 : 11 - 22", isCorrect: false },
					{ id: 2, text: "Luc 3 : 41 - 52", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 14,
				text: "Le récit de la mort de Jean Baptiste se trouve en :",
				options: [
					{ id: 0, text: "Mathieu 14 : 1 - 11", isCorrect: true },
					{ id: 1, text: "Mathieu 13 : 1 - 11", isCorrect: false },
					{ id: 2, text: "Mathieu 12 : 1 - 11", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 15,
				text: "La mission des soixante-douze est relatée en :",
				options: [
					{ id: 0, text: "Luc 6", isCorrect: false },
					{ id: 1, text: "Luc 8 ", isCorrect: false },
					{ id: 2, text: "Luc 10", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 16,
				text: "L’enseignement sur la prière se trouve en :",
				options: [
					{ id: 0, text: "Mathieu 11", isCorrect: false },
					{ id: 1, text: "Luc 11", isCorrect: true },
					{ id: 2, text: "Jean 11", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 17,
				text: "Le Seigneur Jésus annonce qu’il bâtira son Eglise en :",
				options: [
					{ id: 0, text: "Mathieu 16", isCorrect: true },
					{ id: 1, text: "Mathieu 17", isCorrect: false },
					{ id: 2, text: "Mathieu 18", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 18,
				text: "La résurrection de Lazare est racontée en:",
				options: [
					{ id: 0, text: "Jean 10", isCorrect: false },
					{ id: 1, text: "Jean 11", isCorrect: true },
					{ id: 2, text: "Jean 12", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 19,
				text: "La conversion de Zachée est racontée en :",
				options: [
					{ id: 0, text: "Luc 19 : 1 - 10", isCorrect: true },
					{ id: 1, text: "Luc 19 : 12 - 22", isCorrect: false },
					{ id: 2, text: "Luc 21 : 12 - 22", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 20,
				text: "Le récit des vendeurs chassés du temple se trouve en :",
				options: [
					{ id: 0, text: "Jean 2 : 13 - 19", isCorrect: true },
					{ id: 1, text: "Jean 3 : 13 – 19", isCorrect: false },
					{ id: 2, text: "Jean 6 : 13 - 19", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 21,
				text: "Le récit de l’offrande de la veuve se trouve en :",
				options: [
					{ id: 0, text: "Luc 20: 1 -8", isCorrect: false },
					{ id: 1, text: "Luc 21: 1 -4", isCorrect: true },
					{ id: 2, text: "Mathieu 21 : 12 -17", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 22,
				text: "L’annonce de la destruction de Jérusalem se trouve en :",
				options: [
					{ id: 0, text: "Luc 21", isCorrect: true },
					{ id: 1, text: "Luc 22", isCorrect: false },
					{ id: 2, text: "Luc 24", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 23,
				text: "Le récit de la célébration de la dernière Pâque se trouve en :",
				options: [
					{ id: 0, text: "Luc 12", isCorrect: false },
					{ id: 1, text: "Luc 14", isCorrect: false },
					{ id: 2, text: "Luc 22", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 24,
				text: "L’épisode de Gethsémané se trouve en :",
				options: [
					{ id: 0, text: "Luc 19 ", isCorrect: false },
					{ id: 1, text: "Luc 22", isCorrect: true },
					{ id: 2, text: "Luc 24", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 25,
				text: "Le récit de l’arrestation de Jésus se trouve en ",
				options: [
					{ id: 0, text: "Luc 22 : 47 - 53", isCorrect: true },
					{ id: 1, text: "Luc 21 : 12 - 32", isCorrect: false },
					{ id: 2, text: "Luc 23 : 22 - 32", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 26,
				text: "Jésus comparait devant Pilate en",
				options: [
					{ id: 0, text: "Luc 23 : 1 - 6", isCorrect: true },
					{ id: 1, text: "Luc 21 : 12 - 32", isCorrect: false },
					{ id: 2, text: "Luc 24 : 44 - 49", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 27,
				text: "Jésus comparait devant Hérode ",
				options: [
					{ id: 0, text: "Luc 24 : 1 - 12", isCorrect: false },
					{ id: 1, text: "Luc 23 : 7 - 12", isCorrect: true },
					{ id: 2, text: "Luc 24 : 44 - 49", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 28,
				text: "Le reniement de Pierre est relaté en :",
				options: [
					{ id: 0, text: "Mathieu 20 : 54 - 61", isCorrect: true },
					{ id: 1, text: "Mathieu 21 : 54 - 61", isCorrect: false },
					{ id: 2, text: "Mathieu 22 : 54 - 61", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 29,
				text: "Jésus lave les pieds de ses disciples en :",
				options: [
					{ id: 0, text: "Jean 12 : 1 - 20", isCorrect: false },
					{ id: 1, text: "Jean 13 : 1 - 20", isCorrect: true },
					{ id: 2, text: "Jean 14 ; 1 - 20", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 30,
				text: "La crucifixion de Jésus est racontée en :",
				options: [
					{ id: 0, text: "Jean 19 : 17 -37", isCorrect: true },
					{ id: 1, text: "Jean 19 : 38 - 42", isCorrect: false },
					{ id: 2, text: "Jean 20 : 1 - 18", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 31,
				text: "Le récit de la guérison de l’aveugle de Jéricho se trouve en",
				options: [
					{ id: 0, text: "Luc 17 : 35 – 43", isCorrect: false },
					{ id: 1, text: "Luc 18 : 35 - 43", isCorrect: true },
					{ id: 2, text: "Luc 19 : 35 - 43", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 32,
				text: "La « confession de foi » du centenier romain, au pieds de la Croix de Jésus est en",
				options: [
					{ id: 0, text: "Luc 23 : 47", isCorrect: true },
					{ id: 1, text: "Luc 23 : 34", isCorrect: false },
					{ id: 2, text: "Luc 23 : 3", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 33,
				text: "La rencontre de Jésus et Nicodème se trouve en :",
				options: [
					{ id: 0, text: "Jean 7 : 1- 3", isCorrect: false },
					{ id: 1, text: "Jean 13 : 1 - 7", isCorrect: false },
					{ id: 2, text: "Jean 3 : 1 - 7", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 34,
				text: "La rencontre de Jésus et la femme samaritaine est racontée en :",
				options: [
					{ id: 0, text: "Jean 6 : 1 - 42", isCorrect: false },
					{ id: 1, text: "Jean 14 : 1 - 42", isCorrect: false },
					{ id: 2, text: "Jean 4 : 1 - 42", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 35,
				text: "La mort et la résurrection de Lazare par Jésus est racontée en :",
				options: [
					{ id: 0, text: "Jean 10 : 1 - 44", isCorrect: false },
					{ id: 1, text: "Jean 11 : 1 – 44", isCorrect: true },
					{ id: 2, text: "Jean 12 : 1- 44", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 36,
				text: "Jésus nourrit une foule nombreuse avec « cinq pains d'orge et deux poissons » appartenant à un jeune garçon. ",
				options: [
					{ id: 0, text: "Jean 6 : 1 – 13", isCorrect: true },
					{ id: 1, text: "Jean 13 : 1 - 6", isCorrect: false },
					{ id: 2, text: "Jean 16 : 6 - 13", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 37,
				text: "La guérison de l’infirme de Béthesda, malade depuis 38 ans est racontée en :",
				options: [
					{ id: 0, text: "Jean 5 : 1- 9", isCorrect: true },
					{ id: 1, text: "Jean 9 : 1 - 5", isCorrect: false },
					{ id: 2, text: "Jean 15 : 1- 9", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 38,
				text: "La prière dite sacerdotale se trouve en :",
				options: [
					{ id: 0, text: "Jean 17", isCorrect: true },
					{ id: 1, text: "Jean 14", isCorrect: false },
					{ id: 2, text: "Jean 18", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 39,
				text: "La discussion sur l’impôt à César se trouve en : ",
				options: [
					{ id: 0, text: "Mathieu 21 : 15 – 21", isCorrect: false },
					{ id: 1, text: "Mathieu 15 : 21 - 22", isCorrect: false },
					{ id: 2, text: "Mathieu 22 : 15 – 21", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 40,
				text: "L’histoire de la femme adultère est racontée :  ",
				options: [
					{ id: 0, text: "Jean 8 : 1 – 11", isCorrect: true },
					{ id: 1, text: "Jean 9 : 1- 11", isCorrect: false },
					{ id: 2, text: "Jean 11 : 1 - 11", isCorrect: false },
				],
			},
		],
	},

	{
		gameId: 6,
		jeuPosition: " stage 2 / session 1",
		sectQuest:" stag2ses1",
		jeuTitle:
			"Période de l'Eglise (Les Actes et les Epîtres)",
		paraf1:
			"Cinquante jours après la résurrection du Seigneur Jésus, dix jours après son ascension, un évènement « inattendu » et extraordinaire va se produire à une date, un jour ordinaire. En effet, «1 Quand le jour de la Pentecôte arriva, les disciples étaient tous rassemblés au même endroit.2 Tout à coup, un grand bruit survint du ciel: c'était comme si un violent coup de vent s'abattait sur eux et remplissait toute la maison où ils se trouvaient assis. » (Actes 2 : 1- 2).Ce jour-là, le Saint -Esprit descendit et la première communauté chrétienne vit le jour. ",
		paraf2:
			"C’est cette communauté, qui, sous l’égide du Saint-Esprit va être, par petits groupes dispersés, le témoin du Seigneur, d’Abord à Jérusalem, dans toute la Judée et la Samarie, et jusqu'au bout du monde. Pendant que l’Evangile se répandait, des Lettres étaient écrites pour instruire et encourager les églises. Cette histoire de l’église du premier siècle couvre une période d’à peu près 60 ans.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",
		stagImgSrc: stage2image,
		sectionTitle: "Eglise / Les Personnages",
		sectionImgSrc: personnages,
		questions: [
			{
				id: 1,
				 text: "Hérode le grand fut ce roi qui, devant la délégation de Tyr et Sidon fut frappé par un ange, parce qu'il n'avait pas donné gloire à Dieu. Et, rongé par des vers, il expira  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			{
				id: 2,
				text: "Corneille était cet homme pieux qui adorait Dieu, avec tous les gens de sa maison. Il était généreux envers les pauvres du peuple et priait Dieu en tout temps. Et il habitait Antioche ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 3,
				 text: "Apôtre de Jésus, Paul était originaire de Tarse et parlait aussi le grec",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 4,
				text: "Timothée,  compagnon fidèle de Paul, qui fit sa  connaissance à Lystres , ses deux parents étaient d’origine grecque  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 5,
				 text: "Epaphras était un fidèle serviteur du Seigneur, compagnon de service et de captivité de Paul, c’est cet homme qui a évangélisé la ville de Colosse.",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 6,
				 text: "Gaius : Un chrétien de Derbe portait ce nom-là, accompagna Paul jusqu’en Asie et reçu une Lettre de l’apôtre Jean qui l’encourageait à continuer les bonnes œuvres  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 7,
				text: "Agrippa était un nom d’au moins deux rois. Paul comparut devant l’un d’eux et fut reconnu innocent. ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 8,
				 text: "Epaphrodite : Collaborateur et compagnon d’armes de Paul, c’est lui que l’église de Philippe avait chargé de porter leur don à Paul.",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
		
			{
				id: 9,
				 text: "Tite : D’origine grecque et compagnon d’œuvre de Paul, il se retrouva en Dalmatie, vers la fin de la vie de Paul.  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			{
				id: 10,
				text: "Diotrèphe : Chrétien d’une église à laquelle l’apôtre Jean a écrit, il est dit de lui qu’il demeure attaché à la vérité et vit selon la vérité et son amour est reconnu devant l’église.  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 11,
				 text: "Sosthène fut ce chef de la Syaguogue d’Ephèse qui fut battu par la foule, devant le triunal de Gallion ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 12,
				text: "Priscille : Femme d’Aquilas, elle fut une des femmes qui accompagnait Jésus durant son Ministère terrestre  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 13,
				 text: "Aquilas : Chrétien d’origine grecque, qui venait d’arriver d’Italie à Corinthe, il hébergea Paul et travaillèrent ensemble à confectionner des tentes.  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 14,
				text: "Apollos : Juif originaire d’Alexandrie, il était très éloquent et connaissait très bien les Ecritures et c’est lui qui expliqua plus exactement la voie de Dieu à Priscille et Aquilas",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 15,
				 text: "Eraste : Ce chrétien était, à l’époque de Paul, administrateur de la ville de Corinthe. ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 16,
				text: "Chloé : C’était une femme chrétienne de la ville d’Ephèse",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 17,
				 text: "Jude : Apôtre de Jésus et frère de Jacques, ce nom est diminutif de Judas  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 18,
				 text: "Silas : Avant d’être compagnon d’œuvre de Paul, il fut choisi par l’assemblée de Jérusalem pour communiquer aux chrétiens d’Antioche les décisions prises sur la question de la circoncision des non juifs.  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 19,
				 text: "Etienne fut le premier martyr chrétien ; il faisait partie des sept hommes pleins de foi et du Saint- Esprit qui furent choisis pour aider les apôtres.  ",
  
  options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 20,
				text: "Philippe, l’un des sept, choisis pour aider les apôtres, il évangélisa la Samarie et convertit un eunuque éthiopien et c’est dans sa ville natale que Paul et son équipe convertirent Lydie. ",
  
  options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 21,
				text: "Pierre : Qu’est-ce qui n’est pas vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Son nom est Simon fils de Jonas", isCorrect: false },
        { id: 1, text: "Il a écrit deux épitres, canonisées dans la Bible", isCorrect: false },
        { id: 2, text: "Il fut le premier à être appelé apôtre", isCorrect: true },
				],
			},
			
			{
				id: 22,
				text: "Jean : Qu’est-ce qui n’est pas vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "ll est l’auteur de quatre épitres qui portent son nom", isCorrect: true },
        { id: 1, text: "Il était le frère de Jacques, fils de Zébédé", isCorrect: false },
        { id: 2, text: "Il est aussi l’auteur du Livre de l’Apocalypse", isCorrect: false },
				],
			},
			
			{
				id: 23,
				text: "Jacques fils de Zébédée : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Il fut tué par le roi Hérode, très tôt par l’épée", isCorrect: false },
        { id: 1, text: "Il était le frère de Jean, fils de Zébédée", isCorrect: false },
        { id: 2, text: "Il est l’auteur de l’épitre qui porte son nom", isCorrect: true },
				],
			},
			
			{
				id: 24,
				text: "Barnabas : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Ce surnom qui signifie « fils de consolation » lui a été donné par les apôtres ", isCorrect: false },
        { id: 1, text: "Juif originaire d’Antioche, il s’appelait Joseph", isCorrect: true },
        { id: 2, text: "C’est lui qui prit avec lui Saul, le conduisit vers les apôtres et leur raconta comment Saul avait vu le Seigneur qui lui avait parlé...", isCorrect: false },
				],
			},
			
			{
				id: 25,
				text: "Mathias : Qu’est-ce qui n’est vrai, ici, à son sujet ? ",
      options: [
        { id: 0, text: "Il avait connu le Seigneur Jésus", isCorrect: false },
        { id: 1, text: "La Bible mentionne qu’il était pieux et craignait Dieu", isCorrect: true },
        { id: 2, text: "Il devint apôtre, après la résurrection de Jésus", isCorrect: false },
				],
			},
			{
				id: 26,
				text: "Tabitha : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Son autre nom, en Grec, est Dorcas", isCorrect: false },
        { id: 1, text: "Elle fut ressuscitée par Pierre", isCorrect: false },
        { id: 2, text: "Elle était originaire de Lydda (ou Lydde)", isCorrect: true },
				],
			},
			
			{
				id: 27,
				text: "Festus : Qu’est-ce qui n’est vrai, ici, à son sujet ? ",
      options: [
        { id: 0, text: "Il fut ce gouverneur qui voulait laisser Paul en prison pour gagner la faveur des juifs", isCorrect: true },
        { id: 1, text: "C’est lui qui fit comparaitre Paul devant Agrippa et Bérénice", isCorrect: false },
        { id: 2, text: "C’est devant lui que l’apôtre Paul dût se défendre et en appela à César", isCorrect: false },
				],
			},
			
			{
				id: 28,
				text: "Démas : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Il fut l’un des compagnons d’œuvre de Paul ", isCorrect: false },
        { id: 1, text: "Que Paul a converti à Colosse", isCorrect: true },
        { id: 2, text: "Et qui abandonna Paul pour le monde présent", isCorrect: false },
				],
			},
			
			{
				id: 29,
				text: "Enée : Qu’est-ce qui est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Cet homme a été guéri par Pierre après huit ans de maladie", isCorrect: true },
        { id: 1, text: "Il avait une maladie dont on ne connaissait pas l’origine", isCorrect: false },
        { id: 2, text: "Il vivait à Césarée", isCorrect: false },
				],
			},
		
			{
				id: 30,
				text: "Gaius : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "C’est à lui qu’il a été dit : « Bien-aimé, je souhaite que tu prospères à tous égards et que tu sois en bonne santé, comme prospère ton âme » ", isCorrect: false },
        { id: 1, text: "C’est à ce chrétien généreux et fidèle que l’apôtre Jean a adressé sa première Epïtre", isCorrect: true },
        { id: 2, text: "Il était dans la même Assemblée qu’un certain Diotrèphe", isCorrect: false },
				],
			},
			
			{
				id: 31,
				text: "Etienne : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Ce chrétien pieux et de grande foi évangélisa la Samarie", isCorrect: true },
        { id: 1, text: "Il fut choisi avec six autres, par les apôtres pour servir aux tables", isCorrect: false },
        { id: 2, text: "Pendant qu’on le lapidait, il a vu les cieux ouverts et Jésus, débout à la droite de Dieu.", isCorrect: false },
				],
			},
			
			{
				id: 32,
				text: "Philémon : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Il reçut une lettre de Paul, pendant que ce dernier était en prison", isCorrect: false },
        { id: 1, text: "Paul lui demande d’accueillir et de pardonner un de ses esclave, Onésime, qui s’était enfui de chez  lui", isCorrect: false },
        { id: 2, text: "A Thessalonique l'église se réuissait chez lui", isCorrect: true },
				],
			},
			
			{
				id: 33,
				text: "Stéphanas : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Cet homme était avec Paul quand ce dernier écrivit la Première Lettre aux Corinthiens", isCorrect: false },
        { id: 1, text: "Il était un chrétien de la ville de Colosse", isCorrect: true },
        { id: 2, text: "Il s’était consacré aux service des autres croyants.", isCorrect: false },
				],
			},
			
			{
				id: 34,
				text: "Timothée : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Il était disciple de Derbe, ", isCorrect: false },
        { id: 1, text: "Compagnon d’œuvre et  collaborateur fidèle de Paul, sa grand-mère s’appelait Eunice.", isCorrect: true },
        { id: 2, text: "Paul lui écrivit deux Lettres", isCorrect: false },
				],
			},
			
			{
				id: 35,
				text: "Trophime d'Éphèse: Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Il fut compagnon d’œuvre de Paul, en Asie lors de son troisième voyage missionnaire", isCorrect: false },
        { id: 1, text: "C’est lui qui, étant présent avec Paul à Jérusalem, les juifs ont pensé que Paul l’avait introduit dans le temple.", isCorrect: false },
        { id: 2, text: "C’est de lui aussi que, Paul,  dans sa deuxième Lettre à Timothée, disait qu’il l’avait laissé malade à Ephèse.", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 36,
				text: "Tychique : Qu’est-ce n’est vrai, ici, à son sujet ? ",
      options: [
        { id: 0, text: "Il était originaire de la Macédoine", isCorrect: true },
        { id: 1, text: "Ce bien -aimé et fidèle serviteur dans le Seigneur, avait été chargé d’apporter les Lettres de Paul aux Ephésiens et aux Colossiens.", isCorrect: false },
        { id: 2, text: "Plus tard, Paul l’envoya à Ephèse et pensait à l’envoyer, lui ou Artémas, auprès de Tite", isCorrect: false },
				],
			},
			{
				id: 37,
				text: "Félix : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Il était le gouverneur auprès de qui Paul fut conduit après son arrestation, à Jérusalem.", isCorrect: false },
        { id: 1, text: "Ayant écouté Paul à plusieurs reprises, il fut empressé de le libérer.", isCorrect: true },
        { id: 2, text: "C’est homme fut effrayé par la prédication de Paul, qui lui parlait de la justice, de la maîtrise de soi et du jugement à venir.", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 38,
				text: "Jason de Thessalonique : Qu’est-ce qui n’est vrai, ici, à son sujet ?",
      options: [
        { id: 0, text: "Ce chrétien fidèle avait reçu chez lui Paul et Sylas à Thessalonique", isCorrect: false },
        { id: 1, text: "Des juifs s’en prirent à lui et le trainèrent devant les magistrats de la ville", isCorrect: false },
        { id: 2, text: "Il était aussi un parent de l’apôtre Paul", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 39,
				text: "Quel est le nom de l’homme qui a été élu pour remplacer Juda Iscariote ? ",
      options: [
        { id: 0, text: "Joseph", isCorrect: false },
        { id: 1, text: "Barnabas", isCorrect: false },
        { id: 2, text: "Mathias", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 40,
				text: "Festus : Qu’est-ce qui n’est vrai, ici, à son sujet ? ",
      options: [
        { id: 0, text: "Il fut ce gouverneur qui voulait laisser Paul en prison pour gagner la faveur des juifs", isCorrect: true },
        { id: 1, text: "C’est lui qui fit comparaitre Paul devant Agrippa et Bérénice", isCorrect: false },
        { id: 2, text: "C’est devant lui que l’apôtre Paul dût se défendre et en appela à César", isCorrect: false },
				],
			},
		]
	}, 
	{
		gameId: 7,
		jeuPosition: " stage 2 / session 2",
		sectQuest:" stag2ses2",
		jeuTitle:
			"Période de l'Eglise (Les Actes et les Epîtres)",
		paraf1:
			"Cinquante jours après la résurrection du Seigneur Jésus, dix jours après son ascension, un évènement « inattendu » et extraordinaire va se produire à une date, un jour ordinaire. En effet, «1 Quand le jour de la Pentecôte arriva, les disciples étaient tous rassemblés au même endroit.2 Tout à coup, un grand bruit survint du ciel: c'était comme si un violent coup de vent s'abattait sur eux et remplissait toute la maison où ils se trouvaient assis. » (Actes 2 : 1- 2).Ce jour-là, le Saint -Esprit descendit et la première communauté chrétienne vit le jour.",
		paraf2:
			"C’est cette communauté, qui, sous l’égide du Saint-Esprit va être, par petits groupes dispersés, le témoin du Seigneur, d’Abord à Jérusalem, dans toute la Judée et la Samarie, et jusqu'au bout du monde. Pendant que l’Evangile se répandait, des Lettres étaient écrites pour instruire et encourager les églises. Cette histoire de l’église du premier siècle couvre une période d’à peu près 60 ans.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",

		stagImgSrc: stage2image,
		sectionTitle: "Eglise / Les Lieux",
		sectionImgSrc: lieux,
		questions: [
			{
				id: 1,
				 text: "Antioche : C’est dans cette ville que Barnabas et Saul se rencontrèrent pour la première Fois ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			{
				id: 2,
				text: " Philippe : C’est dans cette ville, qu’une femme nommée Lydie fut convertie ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
   
                        {
				id: 3,
				 text: "Corinthe : Paul est resté à Corinthe trois ans, avec Aquilas et Priscille  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 4,
				text: "Ephèse : Paul a visité et travaillé dans cette ville, lors de son deuxième voyage missionnaire.",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 5,
				 text: "Galatie : Lors de son deuxième voyage missionnaire, Paul accompagné de Timothée a traversé cette région sans toutefois y évangéliser.  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 6,
				 text: "Rome :   A cette époque, capitale de l’empire romain, Priscille et Aquilas y ont séjourné pendant 18 ans, selon le LIvre des Actes.",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 7,
				text: "Thessalonique : Ville du nord de la Grèce, à cette époque, l’apôtre Paul a écrit à l’église de cette ville trois Lettres.",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 8,
				text: "Macédoine : C’était une région au nord de la Grèce dont Philippe, Ephèse, Thessalonique étaient des villes",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 9,
				 text: "Derbe : C’était une ville d’Asie Mineure, située dans la plaine de la Lycaonie où Paul et Barnabas y évangélisèrent lors du premier voyage missionnaire. ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 10,
				text: "Colosse : C’était une ville de Phrygie, en Asie Mineure, où Paul et ses compagnons séjournèrent et évangélisèrent, pendant 18 mois. ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 11,
				 text: " Aéropage : En ce lieu, dans la ville d’Athènes, colline où se réunissait, à cette époque le Conseil de la ville, Paul y prêcha devant des phylosophes.",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 12,
				text: "Lystre : Ville de Macédoine, c’est là que Paul guérit un homme impotent des pieds, infirme de naissance, et qui n'avait jamais marché",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },  
				],
			},
			
			{
				id: 13,
				 text: "A Iconium, Paul et Barnabas entrèrent aussi dans la synagogue des Juifs et parlèrent de telle manière qu'une grande multitude de Juifs et de Grecs crurent.",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 14,
				text: "Asie Mineure : C’était une province romaine au nord de la Méditerranée dont Ephèse faisait partie",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 15,
				 text: "Achaïe : Région de la Grèce ancienne, sa capitale était Corinthe  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 16,
				text: "Samarie : L’apôtre Philippe y prêcha et convertit un magicien, nommé Simon.",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 17,
				 text: "Jérusalem : C’était la capitale de la Palestine, situé en Galilée.  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 18,
				 text: "Syrie : Province romaine au nord de la Palestine dont la capitale était Thessalonique ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 19,
				 text: "Milet : Paul arriva dans cette ville, au cours de son troisième voyage missionnaire, fit appeler les anciens d’Ephèse pour s’entretenir et prier avec eux.",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 20,
				text: "Troas : Lors de son troisième voyage missionnaire, Paul y séjourna pendant 21 jours.",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 21,
				text: "Alexandrie : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Elle fut fondée par Alexandre le Grand au premier siècle de notre ère", isCorrect: true },
        { id: 1, text: "C’était une ville portuaire sur la mer Méditerranée au nord de l’Egypte", isCorrect: false },
        { id: 2, text: "Quant Paul en appelé à César, il monta sur un navire d’Alexandrie qui allait en Italie.", isCorrect: false },
				],
			},
			
			{
				id: 22,
				text: "Antioche de Syrie: Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Ce fut dans cette ville que pour la première fois, les disciples furent nommés chrétiens", isCorrect: false },
        { id: 1, text: "Elle a été fondée vers le 300 avant J.C. par Séleucos Nicator", isCorrect: false },
        { id: 2, text: "À Lystre, des juifs de cette ville lapidèrent Paul et le laissèrent pour mort", isCorrect: true },
				],
			},
			
			{
				id: 23,
				text: "Athènes : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Capitale de la Grèce, elle devînt la métropole de la culture et des arts dans l’Antiquité", isCorrect: false },
        { id: 1, text: "Dans cette ville, Quelques philosophes épicuriens et stoïciens se sont mis à parler avec Paul pour savoir ce qu’il enseignait.", isCorrect: false },
        { id: 2, text: "C’est dans cette ville que Paul prêcha en un lieu dénommé Aéropage, lors de son premier voyage missionnaire", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 24,
				text: "Bérée : Qu’est-ce qui n’est pas vrai au sujet de cette localité ?",
      options: [
        { id: 0, text: "C’est une ville de la Macédoine, près de Thessalonique que Paul visita, lors de son troisième voyage missionnaire", isCorrect: true },
        { id: 1, text: "C’était la ville d’origine de Sopater, un des compagnons de mission de Paul", isCorrect: false },
        { id: 2, text: "Les juifs de Bérée reçurent la parole avec bonne volonté, et ils examinaient chaque jour les Écritures, pour voir si ce qu'on leur disait était exact.", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 25,
				text: "Bithynie : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Lors de son deuxième voyage, Paul et ses compagnons, Arrivés près de la Mysie, tentèrent d'aller en Bithynie ; mais l'Esprit de Jésus ne le leur permit pas.", isCorrect: false },
        { id: 1, text: "Quand Pierre écrit sa première épître, il mentionne les croyants de Bithynie", isCorrect: false },
        { id: 2, text: "C’était une province montagneuse située au nord de l’Archaïe", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 26,
				text: "Cappadoce : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "La première épître de Pierre fut aussi adressé aux croyants de la Cappadoce", isCorrect: false },
        { id: 1, text: "C’était une province romaine à l’est de l’Asie Mineure", isCorrect: false },
        { id: 2, text: "Paul y évangélisa et implanta une église lors de son troisième voyage missionnaire", isCorrect: true },
				],
			},
			
			{
				id: 27,
				text: "Cenchrée : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Phoebé était une servante de l’église de Cenchrée que Paul a recommandée aux chrétiens de Colosse.", isCorrect: true },
        { id: 1, text: "C’était un port de la ville de Corinthe,", isCorrect: false },
        { id: 2, text: "C’est dans cette localité que Paul se fit raser la tête, à la suite d’un vœu. ", isCorrect: false },
				],
			},
			
			{
				id: 28,
				text: "Césarée : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Paul a été prisonnier à Césarée et c’est là qu’il en a appelé à César", isCorrect: false },
        { id: 1, text: "L’origine de son nom vient de celui de l’empéreur César et était unique dans l’Empire Romain", isCorrect: true },
        { id: 2, text: "Philippe se trouva dans Azot, puis il évangélisa toutes les villes par lesquelles il passait jusqu'à son arrivée à Césarée. ", isCorrect: false },
				],
			},
			
			{
				id: 29,
				text: "Chypre : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Barnabas était originaire de cette ville", isCorrect: false },
        { id: 1, text: "C’était une île importante au nord-est de la méditerranée", isCorrect: false },
        { id: 2, text: "C’est là-bas que l’apôtre Paul se rendit après s’être séparé de Barnabas", isCorrect: true },
				],
			},
			
			{
				id: 30,
				text: "Cilicie : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Paul était originaire de Tarse capitale de la Cilicie", isCorrect: false },
        { id: 1, text: "Des gens de la Synagogue de Cilicie ont discuté avec Étienne, mais ne furent pas capables de résister à la sagesse et à l'Esprit par lequel il parlait.", isCorrect: false },
        { id: 2, text: "Paul et ses compagnons visitèrent cette province romaine, lors de son troisième voyage missionnaire", isCorrect: true },
				],
			},
			
			{
				id: 31,
				text: "Corinthe : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Capitale de l’Achaïe, Corinthe avait deux ports", isCorrect: false },
        { id: 1, text: "Paul vista cette ville pendant son premier voyage missionnaire", isCorrect: true },
        { id: 2, text: "Paul y évangélisa pendant un an et demi, et vivait avec Priscille et Aquilas", isCorrect: false },
				],
			},
			
			{
				id: 32,
				text: "Damas : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "C’est en allant persécuté les croyants de cette ville que Paul fut stoppé par Jésus et plu tard fut baptisé", isCorrect: false },
        { id: 1, text: "Paul n’y est plus retourné après sa conversion ", isCorrect: true },
        { id: 2, text: "C’était une ville, à l’époque, importante de Syrie, près de la Palestine", isCorrect: false },
				],
			},
			
			{
				id: 33,
				text: "Ephèse : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Ville de Pamphylie, sur la côte ouest de l’Asie Mineure", isCorrect: true },
        { id: 1, text: "Pendant son troisième voyage missionnaire, Paul y a enseigné pendant au moins deux ans", isCorrect: false },
        { id: 2, text: "Au cours de son troisième voyage, quand il était à Milet, il fit venir les anciens d’Ephèse pour s’entretenir et prier avec eux", isCorrect: false },
				],
			},
			
			{
				id: 34,
				text: "Galatie : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Paul a écrit une lettre aux chrétiens de Galates", isCorrect: false },
        { id: 1, text: "Pierre également, dans sa première épitre s’adressa aussi aux Galates", isCorrect: false },
        { id: 2, text: "C’était une région importante de la Macédoine", isCorrect: true },
				],
			},
			
			{
				id: 35,
				text: "Italie : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "A Corinthe, Paul trouva un Juif du nom d'Aquilas, originaire du Pont, récemment arrivé d'Italie avec sa femme Priscille", isCorrect: false },
        { id: 1, text: "Paul fut conduit en Italie, en tant que prisonnier, en ayant appelé à César, vers la fin de son deuxième voyage missionnaire", isCorrect: true },
        { id: 2, text: "L’auteur de l’épître aux Hébreux a transmis des salutations de la part des frères d’Italie", isCorrect: false },
				],
			},
			
			{
				id: 36,
				text: "Joppé : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "C’est dans cette ville qu’une croyante nommée Dorcas fut ressuscitée par Pierre", isCorrect: false },
        { id: 1, text: "C’est aussi dans cette ville que Pierre a eu une vision qui l’a préparé à la conversion de Corneille de Césarée.", isCorrect: false },
        { id: 2, text: "C’est dans cette ville portuaire de la Palestine, aussi, qu’un croyant nommé Enée fut guéri", isCorrect: true },
				],
			},
			
			{
				id: 37,
				text: "Laodicée : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Les croyants de cette ville avaient reçu une lettre de Paul, comme ceux de Colosse", isCorrect: false },
        { id: 1, text: "L’église de Laodicée se réunissait dans la maison d’un croyant nommé Nymphas", isCorrect: false },
        { id: 2, text: "L‘église de Laodicée est la sixième église à qui est adressée une Lettre dans l’Apocalypse.", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 38,
				 text: "Macédoine : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Au cours de son deuxième voyage missionnaire, pendant une nuit Paul eut une vision : un Macédonien l’appelait au secours !", isCorrect: false },
        { id: 1, text: "Paul mentionne dans une de ses Lettres que la Macédoine et l'Achaïe ont bien voulu faire une collecte en faveur des pauvres qui sont parmi les saints de Jérusalem.", isCorrect: false },
        { id: 2, text: "Philippe, Thessalonique et Ephèse font partie de cette région du nord de la Grêce.", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 39,
				text: "Milet : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Dans une de ses Lettres, Paul dit qu’il avait laissé Trophime malade dans cette ville", isCorrect: false },
        { id: 1, text: "C’est à Milet qu’au cours de son troisième voyage missionnaire, Paul convoqua les anciens d’Ephèse pour les entretenir et prier avec eux.", isCorrect: false },
        { id: 2, text: "Cette ville, au sud d’Ephèse, se trouve en Macédoine", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 40,
				text: "Philadelphie : Qu’est-ce qui n’est pas vrai au sujet de cette localité ? ",
      options: [
        { id: 0, text: "Une des sept Lettres aux églises d’Asie, du  livre d’Apocalypse leur était adressée", isCorrect: false },
        { id: 1, text: "Dans cette Lettre, il est dit des chrétiens de Philadelphie qu’ils étaient fidèles et qu’ils avaient gardé la parole de Dieu", isCorrect: false },
        { id: 2, text: "C’est à eux qu’il a été dit:  Voici ce que dit celui qui a les sept esprits de Dieu et les sept étoiles", isCorrect: true },
				],
			},
		]
	},
	{
		gameId: 8,
		jeuPosition: " stage 2 / session 3",
		sectQuest:" stag2ses3",
		jeuTitle:
			"Période de l'Eglise (Les Actes et les Epîtres)",
		paraf1:
			"Cinquante jours après la résurrection du Seigneur Jésus, dix jours après son ascension, un évènement « inattendu » et extraordinaire va se produire à une date, un jour ordinaire. En effet, «1 Quand le jour de la Pentecôte arriva, les disciples étaient tous rassemblés au même endroit.2 Tout à coup, un grand bruit survint du ciel: c'était comme si un violent coup de vent s'abattait sur eux et remplissait toute la maison où ils se trouvaient assis. » (Actes 2 : 1- 2). Ce jour-là, le Saint -Esprit descendit et la première communauté chrétienne vit le jour. ",
		paraf2:
			"C’est cette communauté, qui, sous l’égide du Saint-Esprit va être, par petits groupes dispersés, le témoin du Seigneur, d’Abord à Jérusalem, dans toute la Judée et la Samarie, et jusqu'au bout du monde. Pendant que l’Evangile se répandait, des Lettres étaient écrites pour instruire et encourager les églises. Cette histoire de l’église du premier siècle couvre une période d’à peu près 60 ans.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",
		stagImgSrc: stage2image,
		sectionTitle: "Eglise / Les Instittions et Rites",
		sectionImgSrc: institutions,
		questions: [
			{
				id: 1,
				 text: "Le Repas du Seigneur a été institué par le Seigneur Jésus lui-même",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			{
				id: 2,
				text: "Que personne, lorsqu’il est tenté, ne dise : C’est Dieu qui me tente. Car Dieu ne peut être tenté par le mal, et il peut tenter qui il veut.",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
   
                        {
				id: 3,
				 text: "La foi peut se définir comme l’énergie intérieure du croyant, qui est nourrie par la Parole de Dieu et guidée par le Saint-Esprit",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 4,
				text: "La Grâce, c’est une ferme assurance des choses qu’on espère et à la démonstration de celles qu’on ne voit pas ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 5,
				 text: "Dans la Bible, le  Seigneur Jésus  a été la première personne à connaitre la résurrection (à être ressuscitée) ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 6,
				 text: "Les Prosélytes étaient, à l’origine, les étrangers des nations qui se convertissaient au Judaïsme et le Christianisme Primitif a aussi eu ses prosélytes dont qu’Apollos.",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },  
				],
			},
			
			{
				id: 7,
				text: "Les Docteurs de l’Eglise étaient des anciens Docteurs de la Loi, convertis au Christianisme et qui ont été très utiles à l’Eglise.",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 8,
				text: "Tous ceux qui acceptent le Christianisme comme Religion bénéficient automatiquement de l’Adoption",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 9,
				 text: "Les Hellénistes comme on peut le voir en Actes 6, étaient des enfants d’Israël qui parlaient la langue grecque et qui avaient adoptés les coutumes grecques.",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 10,
				text: "Les  enfants d’Israël dispersés dans l’empire romain, avaient construit un temple, dans chaque ville importante de l’empire ; Mais spirituellement, nous chrétiens, constituons le Temple du Saint-Esprit.  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 11,
				 text: "Les Ténèbres, symbolisent l’éloignement et la séparation d'avec Dieu ; c’est donc le domaine contrôlé et dominé par satan.",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 12,
				text: "C’est Pierre qui a dit: «  Car tandis que nous sommes dans cette tente, nous gémissons, accablés, parce que nous voulons, non pas nous dépouiller, mais nous revêtir, afin que ce qui est mortel soit englouti par la vie. »",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },  
				],
			},
			
			{
				id: 13,
				 text: "D’après Paul en  1 Corinthiens et 1 Thessaloniciens, la Trompette sonnera, tout juste après la résurrection et juste avant l’avènement du Seigneur. ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 14,
				text: "C’est Pierre qui, à propos du Sceau, a dit: «En lui vous aussi, après avoir entendu la parole de la vérité, l’Evangile de votre salut, en lui vous avez cru et vous avez été scellés du Saint-Esprit qui avait été promis.»",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 15,
				 text: "Dans le Livre des Actes, il a été fait mention de la Secte des Sadducéens, celle des Pharisiens, celle des Nazaréens. ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 16,
				text: "Le terme Pasteur (du Grec Poimèn) n’est utilisé que sept fois dans les Epîtres.",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 17,
				 text: "Cette Vérité : « Car les hommes seront égoïstes, amis de l’argent, …., enflés d’orgueil, aimant le plaisir plus que Dieu,   ayant l’apparence de la piété, mais reniant ce qui en fait la force. » se trouve dans l’Epître aux Romains.  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 18,
				 text: "Paul a dit que : «  C’est pour cet Evangile qu’il a été établi prédicateur et apôtre, chargé d’instruire les païens. » ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 19,
				 text: "Paul fut gardé dans le Prétoire d’Hérode, à Jérusalem et plu tard dans le Prétoire de Rome. ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 20,
				text: "«Car Dieu était en Christ, réconciliant le monde avec lui-même en n’imputant point aux hommes leurs offenses, et il a mis en nous la parole de la réconciliation.». Cette vérité est tirée de 1 Pierre.",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 21,
				text: "Parmi ces passages bibliques, lequel ne mentionne pas la notion d’évangéliste",
      options: [
        { id: 0, text: "Actes 21 : 8 ", isCorrect: false },
        { id: 1, text: "Ephésiens 4 : 11 ", isCorrect: false },
        { id: 2, text: "2 Timothée 4 : 1 ", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 22,
				text: "Qu’est –ce qui n’est pas vrai concernant les anciens ? ",
      options: [
        { id: 0, text: "Vers la fin de leur premier voyage missionnaire, Paul et Barnabas désignèrent un ancien pour chaque église et, après avoir prié et jeûné, ils les recommandèrent au Seigneur, en qui ils avaient cru. ", isCorrect: true },
        { id: 1, text: "C’étaient des chrétiens qui possédaient une maturité et une expérience spirituelles qui les rendaiet aptes à diriger et à prendre soin spirituellement des autres croyants, dans l’église locale ", isCorrect: false },
        { id: 2, text: "L’apôtre Pierre, dans une de ces Lettres a déclaré être un ancien ", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 23,
				text: "Qu’est-ce qui n’est pas vrai concernant l’archange ? ",
      options: [
        { id: 0, text: "Dans la Bible, seulement trois archanges sont mentionnés", isCorrect: true },
        { id: 1, text: "Or, l’archange Michel, lorsqu’il contestait avec le diable et lui disputait le corps de Moïse, n’osa pas porter contre lui un jugement injurieux", isCorrect: false },
        { id: 2, text: "Car le Seigneur lui-même, à un signal donné, à la voix d’un archange, et au son de la trompette de Dieu, descendra du ciel", isCorrect: false },
				],
			},
			
			{
				id: 24,
				text: "Qu’est-ce n’est pas vrai ici pour l’apôtre ? ",
      options: [
        { id: 0, text: "L’Eglise ou la Maison de Dieu est édifié sur le fondement des apôtres et des prophètes, Jésus-Christ lui-même étant la pierre angulaire", isCorrect: false },
        { id: 1, text: "Le mystère de Christ a été révélé maintenant par l’Esprit aux saints apôtres et prophètes de Christ ", isCorrect: false },
        { id: 2, text: "Ceux qui avaient la charge de fonder les églises de tous les temps", isCorrect: true },
				],
			},
			
			{
				id: 25,
				text: "Qu’est-ce qui n’est pas vrai concernant la Confession ?",
      options: [
        { id: 0, text: "Le fait de reconnaitre ou déclarer ouvertement la vérité", isCorrect: false },
        { id: 1, text: "C’est à Dieu seul, en réalité que nous devons confesser nos péchés", isCorrect: true },
        { id: 2, text: "Si nous confessons nos péchés, il est fidèle et juste pour nous les pardonner, et pour nous purifier de toute iniquité.", isCorrect: false },
				],
			},
			
			{
				id: 26,
				text: "Qu’est-ce qui n’est pas vrai au sujet de la Rédemption ? ",
      options: [
        { id: 0, text: "C’est un rachat, sans rançon, suivi d’une délivrance ", isCorrect: true },
        { id: 1, text: "La rédemption du croyant, qui était auparavant esclave du péché, est obtenue par le sang de Jésus – Christ", isCorrect: false },
        { id: 2, text: "N’attristez pas le Saint-Esprit de Dieu, par lequel vous avez été scellés pour le jour de la rédemption.", isCorrect: false },
				],
			},
			
			{
				id: 27,
				text: "Parmi ces passages bibliques, lequel ne parle pas de sanctification ? ",
      options: [
        { id: 0, text: "2 Corinthiens 7 : 1 ", isCorrect: false },
        { id: 1, text: "Hébreux 12 : 14 ", isCorrect: false },
        { id: 2, text: "Ephésiens 4 : 29 ", isCorrect: true },
				],
			},
			
			{
				id: 28,
				text: "Parmi ces passages bibliques, lequel ne parle pas de Justification ? ",
      options: [
        { id: 0, text: "Romain 4 : 25 ", isCorrect: false },
        { id: 1, text: "Romain 5 : 18 ", isCorrect: false },
        { id: 2, text: "Romain 6 : 23 ", isCorrect: true },
				],
			},
			
			{
				id: 29,
				 text: "Parmi ces passages bibliques, lequel parle de Rachat ? ",
      options: [
        { id: 0, text: "Romain 5 : 12 ", isCorrect: false },
        { id: 1, text: "Hébreux 9 : 15 ", isCorrect: true },
        { id: 2, text: "1 Pierre 1 : 23 ", isCorrect: false },
				],
			},
			
			{
				id: 30,
				text: "Parmi ces passages bibliques, lequel parle d’Adoption ? ",
      options: [
        { id: 0, text: "Romain 8 : 15", isCorrect: true },
        { id: 1, text: "Romain 8 : 18 ", isCorrect: false },
        { id: 2, text: "Romain 8 : 24 ", isCorrect: false },
				],
			},
			
			{
				id: 31,
				text: "Parmi ces passages bibliques, lequel ne parle pas de Pardon ? ",
      options: [
        { id: 0, text: "Hébreux 7 : 22", isCorrect: true },
        { id: 1, text: "Hébreux 9 : 22", isCorrect: false },
        { id: 2, text: "Hébreux 10 : 18", isCorrect: false },
				],
			},
			
			{
				id: 32,
				text: "Qu’est-ce qui n’est pas vrai concernant les Epîtres ? ",
      options: [
        { id: 0, text: "Paul en a écrit quinze (15) ", isCorrect: true },
        { id: 1, text: "Pierre, lui en a écrit  deux (2) ", isCorrect: false },
        { id: 2, text: "Jean en a écrit trois (03 ", isCorrect: false },
				],
			},
			
			{
				id: 33,
				text: "Quelle est la référence de ce passage ?« Or, tout ce qui a été écrit d’avance l’a été pour notre instruction, afin que, par la patience, et par la consolation que donnent les Ecritures, nous possédions l’espérance. » ",
				options: [
					{ id: 0, text: "Romain 1 : 2 ", isCorrect: false },
					{ id: 1, text: "Romain 15 : 4 ", isCorrect: true },
					{ id: 2, text: "2 Pierre 3 : 16 ", isCorrect: false },
							],
			},
			
			{
				id: 34,
				text: "Parmi ces Epîtres, lequel parle le plus de la notion de Vérité ? ",
      options: [
        { id: 0, text: "Romain ", isCorrect: true },
        { id: 1, text: "2 Jean ", isCorrect: false },
        { id: 2, text: "3 Jean ", isCorrect: false },
				],
			},
			
			{
				id: 35,
				text: "Quelle est la référence? « Et c’est en lui que vous avez été circoncis d’une circoncision que la main n’a pas faite, mais de la circoncision de Christ, qui consiste dans le dépouillement du corps de la chair » ",
      options: [
        { id: 0, text: "Romain 2 : 29 ", isCorrect: false },
        { id: 1, text: "Philippiens 3 :2 ", isCorrect: false },
        { id: 2, text: "Colossiens 2 : 11 ", isCorrect: true },
				],
			},
			
			{
				id: 36,
				text: "Quelle est la référence ? « Voici donc ce que je dis et ce que je déclare dans le Seigneur : vous ne devez plus marcher comme les païens, qui marchent selon la vanité de leurs pensées. » ",
      options: [
        { id: 0, text: "Ephésiens 2 : 11 ", isCorrect: false },
        { id: 1, text: "Ephésiens 3 : 6 ", isCorrect: false },
        { id: 2, text: "Ephésiens 4 : 17 ", isCorrect: true },
				],
			},
			
			{
				id: 37,
				text: "Parmi ces passages bibliques, lequel ne  parle pas de Synagogue ? ",
      options: [
        { id: 0, text: "Actes 13 : 15 ", isCorrect: false },
        { id: 1, text: "Philippiens 2 : 8 ", isCorrect: true },
        { id: 2, text: "Apocalypse 2 : 9 ", isCorrect: false },
				],
			},
			
			{
				id: 38,
				 text: "Quelle est la référence de ce passage biblique, relatif au Salut ?« Ainsi, mes bien-aimés, comme vous avez toujours obéi, mettez en œuvre votre salut avec crainte et tremblement, ... » ",
      options: [
        { id: 0, text: "Romain 13 : 11 ", isCorrect: false },
        { id: 1, text: "Philippiens 2 : 12 ", isCorrect: true },
        { id: 2, text: "Romain 11 : 13", isCorrect: false },
				],
			},
			
			{
				id: 39,
				text: "Quelle est la référence de ce passage biblique, relatif au Saint-Esprit?« Le Saint-Esprit montrait par-là que le chemin du lieu très saint n’était pas encore ouvert, tant que le premier tabernacle subsistait. » ",
      options: [
        { id: 0, text: "1 Corinthiens 6 : 19 ", isCorrect: false },
        { id: 1, text: "2 Corinthiens 13 : 14 ", isCorrect: false },
        { id: 2, text: "Hébreux 9 : 8 ", isCorrect: true },
				],
			},
			
			{
				id: 40,
				text: "Parmi ces passages bibliques, lequel ne parle pas de tentation ? ",
      options: [
        { id: 0, text: "Jacques 1 : 10  ", isCorrect: true },
        { id: 1, text: "Jacques 1 : 12  ", isCorrect: false },
        { id: 2, text: "Jacques 1 : 14 ", isCorrect: false },
				],
			},
		]
	},
	{
		gameId: 9,
		jeuPosition: " stage 2 / session 4",
		sectQuest:" stag2ses4",
		jeuTitle:
			"Période de l'Eglise (Les Actes et les Epîtres)",
		paraf1:
			"Cinquante jours après la résurrection du Seigneur Jésus, dix jours après son ascension, un évènement « inattendu » et extraordinaire va se produire à une date, un jour ordinaire. En effet, «1 Quand le jour de la Pentecôte arriva, les disciples étaient tous rassemblés au même endroit.2 Tout à coup, un grand bruit survint du ciel: c'était comme si un violent coup de vent s'abattait sur eux et remplissait toute la maison où ils se trouvaient assis. » (Actes 2 : 1- 2). Ce jour-là, le Saint -Esprit descendit et la première communauté chrétienne vit le jour. ",
		paraf2:
			"C’est cette communauté, qui, sous l’égide du Saint-Esprit va être, par petits groupes dispersés, le témoin du Seigneur, d’Abord à Jérusalem, dans toute la Judée et la Samarie, et jusqu'au bout du monde. Pendant que l’Evangile se répandait, des Lettres étaient écrites pour instruire et encourager les églises. Cette histoire de l’église du premier siècle couvre une période d’à peu près 60 ans.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",

		stagImgSrc: stage2image,
		sectionTitle: "Eglise / Les Lois et Instructions",
		sectionImgSrc: lois,
		questions: [
			{
				id: 1,
				 text: "Quelle est la référence de ce passage ? « Car je n'ai pas honte de l'Évangile : c'est une puissance de Dieu pour le salut de quiconque croit, du Juif premièrement, puis du Grec. » ",
    options: [
        { id: 0, text: "Actes 20 :24 ", isCorrect: false },
        { id: 1, text: "Romain 1 : 16 ", isCorrect: true },
        { id: 2, text: "2 Thessaloniciens 1 : 2 ", isCorrect: false }, 
				],
			},
			{
				id: 2,
				text: "Quelle est la référence de ce passage ? «La colère de Dieu se révèle du ciel contre toute impiété et toute injustice des hommes qui retiennent injustement la vérité captive, » ",
    options: [
        { id: 0, text: "Romain 1 : 18 ", isCorrect: true },
        { id: 1, text: " Romains 4:15", isCorrect: false },
        { id: 2, text: "Romains 5:6 ", isCorrect: false },
				],
			},
   
                        {
				id: 3,
				 text: "Quelle est la référence de ce passage ? « Car le salaire du péché, c'est la mort ; mais le don gratuit de Dieu, c'est la vie éternelle en Christ-Jésus notre Seigneur. » ",
    options: [
        { id: 0, text: "Romains 5:12 ", isCorrect: false },
        { id: 1, text: "Romain 6 : 23 ", isCorrect: true },
        { id: 2, text: "Galates 5 :12 ", isCorrect: false },
				],
			},
			
			{
				id: 4,
				text: "Quelle est la référence de:« C'est lui qui, dans les jours de sa chair, offrit à grands cris et avec larmes, des prières et des supplications à Celui qui pouvait le sauver de la mort. Ayant été exaucé à cause de sa piété,.. » ",
    options: [
        { id: 0, text: "Hébreux 2:14  ", isCorrect: false },
        { id: 1, text: "Hébreux 5 : 7  ", isCorrect: true },
        { id: 2, text: "Hébreux 12 : 28 ", isCorrect: false },
				],
			},
			
			{
				id: 5,
				 text: "Quelle est la référence de: « Et si vous invoquez comme Père celui qui, sans considération de personnes, juge chacun selon ses œuvres, conduisez-vous avec crainte pendant le temps de votre séjour (sur terre). » ",
    options: [
        { id: 0, text: "1 Pierre 1 : 17 ", isCorrect: true },
        { id: 1, text: "1 Pierre 2 : 17 ", isCorrect: false },
        { id: 2, text: "1 Pierre 3 : 17 ", isCorrect: false },
				],
			},
			
			{
				id: 6,
				 text: "Quelle est la référence de: « Maintenant, ce que je vous ai écrit, c'est de ne pas avoir de relations avec quelqu'un qui, tout en se nommant frère, serait débauché, ou cupide, ou ..., et même de ne pas manger avec un tel homme. » ",
    options: [
        { id: 0, text: "Romains 16:17 ", isCorrect: false },
        { id: 1, text: "1 Corinthiens 5 : 11 ", isCorrect: true },
        { id: 2, text: "2 Thessaloniciens 3:6 ", isCorrect: false },
				],
			},
			
			{
				id: 7,
				text: "Quelle est la référence de: « Heureux l'homme qui endure la tentation ; car après avoir été mis à l'épreuve, il recevra la couronne de vie, que le Seigneur a promise à ceux qui l'aiment. » ",
    options: [
        { id: 0, text: "Jacques 1:2 ", isCorrect: false },
        { id: 1, text: "Jacques 1 : 12 ", isCorrect: true },
        { id: 2, text: "Jacques 5:11 ", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 8,
				text: "Quelle est la référence de: « Pierre leur dit : Repentez-vous, et que chacun de vous soit baptisé au nom de Jésus-Christ, pour le pardon de vos péchés ; et vous recevrez le don du Saint-Esprit. » ",
    options: [
        { id: 0, text: "Actes 2 : 38 ", isCorrect: true },
        { id: 1, text: "Actes 3:19 ", isCorrect: false },
        { id: 2, text: "Actes 17:30 ", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 9,
				 text: "Quelle est la référence de: « C'est lui que nous annonçons, en avertissant tout homme et en instruisant tout homme en toute sagesse, afin de rendre tout homme parfait en Christ. »  ",
    options: [
        { id: 0, text: "Actes 13:38 ", isCorrect: false },
        { id: 1, text: "Romains 16:25 ", isCorrect: false },
        { id: 2, text: "Colossiens 1 : 28 ", isCorrect: true },
				],
			},
			/*stage1-sec*/
			{
				id: 10,
				text: "Quelle est la référence de ce passage ?«  Toute Écriture est inspirée de Dieu et utile pour enseigner, pour convaincre, pour redresser, pour éduquer dans la justice, » ",
    options: [
        { id: 0, text: "Romains 15:4 ", isCorrect: false },
        { id: 1, text: "2 Timothée 3 : 16 ", isCorrect: true },
        { id: 2, text: "Hébreux 4:12 ", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 11,
				 text: "Quelle est la référence de ce passage ? « Car tout ce que Dieu a créé est bon, et rien ne doit être rejeté, pourvu qu’on le prenne avec actions de grâces, » ",
    options: [
        { id: 0, text: "Romains 14:14 ", isCorrect: false },
        { id: 1, text: "1 Timothée 4:4  ", isCorrect: true },
        { id: 2, text: "Tite 1 : 15 ", isCorrect: false }, 
				],
			},
			/*stage1-sec*/
			{
				id: 12,
				text: "Où se trouve ce passage ? « Exerce-toi à la piété ; car l'exercice corporel est utile à peu de choses, tandis que la piété est utile à tout, elle a la promesse de la vie présente et de la vie à venir. » ",
    options: [
        { id: 0, text: "1 Timothée 4:6 ", isCorrect: false },
        { id: 1, text: "1 Timothée 4 : 8 ", isCorrect: true },
        { id: 2, text: "1 Timothée 6 : 4 ", isCorrect: false },  
				],
			},
			/*stage1-sec*/
			{
				id: 13,
				 text: "Où se trouve « Fuyez l'inconduite. Quelque autre péché qu'un homme commette, ce péché est extérieur au corps ; mais celui qui se livre à l'inconduite pèche contre son propre corps? » ",
    options: [
        { id: 0, text: "Romains 6:12 ", isCorrect: false },
        { id: 1, text: "1 Corinthiens 6 : 18 ", isCorrect: true },
        { id: 2, text: "2 Timothée 2:22 ", isCorrect: false },
				],
			},
			
			{
				id: 14,
				text: "Quelle est la référence de ce passage ? «  Ne vous y trompez pas : on ne se moque pas de Dieu. Ce qu'un homme aura semé, il le moissonnera aussi. » ",
    options: [
        { id: 0, text: "1 Corinthiens 3:18 ", isCorrect: false },
        { id: 1, text: "Galates 6:3 ", isCorrect: false },
        { id: 2, text: "Galates 6 : 7 ", isCorrect: true }, 
				],
			},
			
			{
				id: 15,
				 text: "Quelle est la référence de ce passage ? «  Car si nous péchons volontairement après avoir reçu la connaissance de la vérité, il ne reste plus de sacrifice pour les péchés, » ",
    options: [
         { id: 0, text: "Hébreux 6:4 ", isCorrect: false },
         { id: 1, text: "Hébreux 10 : 26 ", isCorrect: true },
         { id: 2, text: "2 Pierre 2:20 ", isCorrect: false },
				],
			},
			
			{
				id: 16,
				text: "Quelle est la référence de ce passage ?« Mais par-dessus tout, revêtez-vous de l'amour qui est le lien de la perfection. » ",
    options: [
        { id: 0, text: "Romains 13:8 ", isCorrect: false },
        { id: 1, text: "1 Corinthiens 13:1 ", isCorrect: false },
        { id: 2, text: "Colossiens 3 : 14 ", isCorrect: true }, 
				],
			},
			
			{
				id: 17,
				 text: "Quelle est la référence de ce passage ? « Car il y a un seul Dieu, et aussi un seul médiateur entre Dieu, et les hommes, le Christ-Jésus homme, » ",
    options: [
        { id: 0, text: "1 Corinthiens 8:6 ", isCorrect: false },
        { id: 1, text: "Ephésiens 4:6 ", isCorrect: false },
        { id: 2, text: "1 Timothée 2 : 5 ", isCorrect: true }, 
				],
			},
			
			{
				id: 18,
				 text: "Quelle est la référence de ce passage ? «Et comme il est réservé aux hommes de mourir une seule fois, - après quoi vient le jugement ",
    options: [
        { id: 0, text: "Romains 14:11 ", isCorrect: false },
        { id: 1, text: "2 Corinthiens 5:10 ", isCorrect: false },
        { id: 2, text: "Hébreux 9 : 27 ", isCorrect: true },
				],
			},
			
			{
				id: 19,
				 text: "Où se trouve: « Je vous exhorte donc, frères, par les compassions de Dieu, à offrir vos corps comme un sacrifice vivant, saint, agréable à Dieu, ce qui sera de votre part un culte raisonnable. »? ",
    options: [
        { id: 0, text: "Romain 12 : 1 ", isCorrect: true },
        { id: 1, text: "Ephésiens 5:17 ", isCorrect: false },
        { id: 2, text: "1 Thessaloniciens 4 : 3 ", isCorrect: false },
				],
			},
			
			{
				id: 20,
				text: "« Après avoir purifié vos âmes dans l'obéissance à la vérité en vue d'un amour fraternel sincère, aimez-vous les uns les autres ardemment et de tout cœur, » se trouve en: ",
    options: [
        { id: 0, text: "Romains 6:17 ", isCorrect: false },
        { id: 1, text: "1 Pierre 1 : 22 ", isCorrect: true },
        { id: 2, text: "Jacques 4:8 ", isCorrect: false },
				],
			},
			
			{
				id: 21,
				text: "Quelle est la référence de : « car tout ce qui est dans le monde, la convoitise de la chair, la convoitise des yeux et l'orgueil de la vie, ne vient pas du Père, mais vient du monde. »? ",
      options: [
        { id: 0, text: "Galates 5:17 ", isCorrect: false },
        { id: 1, text: "Jude 1:16 ", isCorrect: false },
        { id: 2, text: "1 Jean 2 : 16 ", isCorrect: true },
				],
			},
			
			{
				id: 22,
				text: "Quelle est la référence de ce passage ? « Ce que Dieu veut, c'est votre sanctification ; c'est que vous vous absteniez de l'inconduite » ",
      options: [
        { id: 0, text: "Romains 12:2 ", isCorrect: false },
        { id: 1, text: "Ephésiens 5:17 ", isCorrect: false },
        { id: 2, text: "1 Thessaloniciens 4 : 3 ", isCorrect: true },
				],
			},
			
			{
				id: 23,
				text: "Quelle est la référence de ce passage ? « Ne nous lassons pas de faire le bien ; car nous moissonnerons au temps convenable, si nous ne nous relâchons pas. » ",
      options: [
        { id: 0, text: "1 Corinthiens 15:58 ", isCorrect: false },
        { id: 1, text: "Galates 6 : 9 ", isCorrect: true },
        { id: 2, text: "2 Thessaloniciens 3:13 ", isCorrect: false },
				],
			},
			
			{
				id: 24,
				text: "« En effet, si après s'être retirés des souillures du monde par la connaissance du Seigneur et Sauveur Jésus-Christ, ils s'y engagent de nouveau et sont vaincus par elles, leur dernière condition est pire que la première. » ",
      options: [
        { id: 0, text: "1 Pierre 2 ; 20 ", isCorrect: false },
        { id: 1, text: "2 Pierre 2 : 20 ", isCorrect: true },
        { id: 2, text: "2 Pierre 3 : 20 ", isCorrect: false },
				],
			},
			
			{
				id: 25,
				text: "Quelle est la référence de ce passage ? « Que nul de vous ne souffre comme meurtrier, comme voleur, comme malfaiteur ou comme se mêlant des affaires d'autrui ; » ",
      options: [
        { id: 0, text: "1 Pierre 2:20 ", isCorrect: false },
        { id: 1, text: "2 Thessaloniciens 3:11 ", isCorrect: false },
        { id: 2, text: "1 Pierre 4 : 15 ", isCorrect: true },
				],
			},
			
			{
				id: 26,
				text: "Quelle est la référence de ce passage ? «Nous savons, du reste, que toutes choses coopèrent au bien de ceux qui aiment Dieu, de ceux qui sont appelés selon son dessein. » ",
      options: [
        { id: 0, text: "Romains 5:3 ", isCorrect: false },
        { id: 1, text: "Romain 8 : 28 ", isCorrect: true },
        { id: 2, text: "2 Corinthiens 4:17 ", isCorrect: false },				],
			},
			
			{
				id: 27,
				text: "« Il y a eu de faux prophètes parmi le peuple ; de même il y a parmi vous de faux docteurs qui introduiront insidieusement des hérésies de perdition et qui, reniant le Maître qui les a rachetés, attireront sur eux une perdition soudaine. »  ",
      options: [
        { id: 0, text: "2 Pierre 2 : 1 ", isCorrect: true },
        { id: 1, text: "1 Jean 2:18 ", isCorrect: false },
        { id: 2, text: "Jean 4:11 ", isCorrect: false },
				],
			},
			
			{
				id: 28,
				text: "« Si quelqu'un dit : J'aime Dieu, et qu'il haïsse son frère, c'est un menteur, car celui qui n'aime pas son frère qu'il voit, ne peut aimer Dieu qu'il ne voit pas. » ",
      options: [
        { id: 0, text: "1 Jean 3:17 ", isCorrect: false },
        { id: 1, text: " 1 Jean 4:12", isCorrect: false },
        { id: 2, text: "1 Jean 4 : 20 ", isCorrect: true },
				],
			},
			
			{
				id: 29,
				 text: "Quelle est la référence de ce passage ? « Ne vous enivrez pas de vin : c'est de la débauche. Mais soyez remplis de l'Esprit : »  ",
      options: [
        { id: 0, text: "Romains 13:13 ", isCorrect: false },
        { id: 1, text: "Ephésiens 3 : 18 ", isCorrect: false },
        { id: 2, text: "Ephésiens 5 : 18 ", isCorrect: true },
				],
			},
			
			{
				id: 30,
				text: "Quelle est la référence de ce passage ? « Or la foi, c'est l'assurance des choses qu'on espère, la démonstration de celles qu'on ne voit pas. » ",
      options: [
        { id: 0, text: "Hébreux 11 : 1 ", isCorrect: true },
        { id: 1, text: "Hébreux 11:13 ", isCorrect: false },
        { id: 2, text: "Hébreux 11 : 27 ", isCorrect: false },
				],
			},
			
			{
				id: 31,
				text: "Où se trouve ce texte :« J'exhorte donc, en tout premier lieu, à faire des requêtes, prières, intercessions, actions de grâces, pour tous les hommes, »? ",
      options: [
        { id: 0, text: "1 Timothée 2 : 1 ", isCorrect: true },
        { id: 1, text: "2 Thessaloniciens 1:3 ", isCorrect: false },
        { id: 2, text: "1 Timothée 2:4 ", isCorrect: false },
				],
			},
			
			{
				id: 32,
				text: "Où se trouve ce texte:« Christ nous a rachetés de la malédiction de la loi, étant devenu malédiction pour nous - car il est écrit : Maudit soit quiconque est pendu au bois »? ",
      options: [
        { id: 0, text: "Romains 8:3 ", isCorrect: false },
        { id: 1, text: "Galates 3:10 ", isCorrect: false },
        { id: 2, text: "Galates 3 : 13 ", isCorrect: true },
				],
			},
			
			{
				id: 33,
				text: "« Quand le Christ, votre vie, paraîtra, alors vous paraîtrez aussi avec lui dans la gloire. Faites donc mourir votre nature terrestre : l'inconduite, l'impureté, les passions, les mauvais désirs et... » ",
      options: [
        { id: 0, text: "Galates 2:20 -21 ", isCorrect: false },
        { id: 1, text: "Colossiens 3 : 4- 5 ", isCorrect: true },
        { id: 2, text: "2 Timothée 4:8 - 9 ", isCorrect: false },
				],
			},
			
			{
				id: 34,
				text: "Où se trouve: « Nous vous demandons, frères, d'avoir de la considération pour ceux qui travaillent parmi vous, qui vous dirigent dans le Seigneur et qui vous avertissent. »? ",
      options: [
        { id: 0, text: "1 Thessaloniciens 5 : 12 ", isCorrect: true },
        { id: 1, text: "Hébreux 13:7 ", isCorrect: false },
        { id: 2, text: "Hébreux 13:17 ", isCorrect: false },
				],
			},
			
			{
				id: 35,
				text: "« Ce Fils, qui est le rayonnement de sa gloire et l'expression de son être, soutient toutes choses par sa parole puissante ; après avoir accompli la purification des péchés, il s'est assis à la droite de la majesté divine dans les lieux très-hauts, » ",
      options: [
        { id: 0, text: "2 Corinthiens 4:6 ", isCorrect: false },
        { id: 1, text: "Colossiens 1:15 ", isCorrect: false },
        { id: 2, text: "Hébreux 1 : 3 ", isCorrect: true },
				],
			},
			
			{
				id: 36,
				text: "Quelle est la référence de ce passage ?« Car toutes les fois que vous mangez ce pain et que vous buvez cette coupe, vous annoncez la mort du Seigneur, jusqu'à ce qu'il vienne. » ",
      options: [
        { id: 0, text: "1 Corinthiens 4:5 ", isCorrect: false },
        { id: 1, text: "1 Corinthiens 11 : 26 ", isCorrect: true },
        { id: 2, text: "1 Corinthiens 15:23 ", isCorrect: false },
				],
			},
			
			{
				id: 37,
				text: "Quelle est la référence de ce passage : « entretenez-vous par des psaumes, des hymnes et des cantiques spirituels ; chantez et célébrez le Seigneur de tout votre cœur  »? ",
      options: [
        { id: 0, text: "1 Corinthiens 14:26 ", isCorrect: false },
        { id: 1, text: "Ephésiens 5 : 19 ", isCorrect: true },
        { id: 2, text: "Colossiens 3:16 ", isCorrect: false },
				],
			},
			
			{
				id: 38,
				 text: "Où se trouve: « Tout est pur pour ceux qui sont purs, mais rien n'est pur pour ceux qui sont souillés et incrédules ; leur intelligence aussi bien que leur conscience est souillée. »? ",
      options: [
        { id: 0, text: "Romains 14:14 ", isCorrect: false },
        { id: 1, text: "1 Timothée 4:4 ", isCorrect: false },
        { id: 2, text: "Tite 1 : 15 ", isCorrect: true },
				],
			},
			
			{
				id: 39,
				text: "Quelle est la référence de ce passage ?« C'est pourquoi, prenez toutes les armes de Dieu, afin de pouvoir résister dans le mauvais jour et tenir ferme après avoir tout surmonté. » ",
      options: [
        { id: 0, text: "2 Corinthiens 10:4 ", isCorrect: false },
        { id: 1, text: "Ephésiens 6:12 ", isCorrect: false },
        { id: 2, text: "Ephésiens 6 : 13 ", isCorrect: true },
				],
			},
			
			{
				id: 40,
				text: "Quelle est la référence de ce passage ? « Tout est permis, mais tout n'est pas utile ; tout est permis, mais tout n'édifie pas., »  ",
      options: [
        { id: 0, text: "1 Corinthiens 6:12 ", isCorrect: false },
        { id: 1, text: "1 Corinthiens 8:9 ", isCorrect: false },
        { id: 2, text: "1 Corinthiens 10 : 23 ", isCorrect: true },
				],
			},
		]
	},
	{
		gameId: 10,
		jeuPosition: " stage 2 / session 5",
		sectQuest:" stag2ses5",
		jeuTitle:
			"Période de l'Eglise (Les Actes et les Epîtres)",
		paraf1:
			"Cinquante jours après la résurrection du Seigneur Jésus, dix jours après son ascension, un évènement « inattendu » et extraordinaire va se produire à une date, un jour ordinaire. En effet, «1 Quand le jour de la Pentecôte arriva, les disciples étaient tous rassemblés au même endroit.2 Tout à coup, un grand bruit survint du ciel: c'était comme si un violent coup de vent s'abattait sur eux et remplissait toute la maison où ils se trouvaient assis. » (Actes 2 : 1- 2). Ce jour-là, le Saint -Esprit descendit et la première communauté chrétienne vit le jour. ",
		paraf2:
			"C’est cette communauté, qui, sous l’égide du Saint-Esprit va être, par petits groupes dispersés, le témoin du Seigneur, d’Abord à Jérusalem, dans toute la Judée et la Samarie, et jusqu'au bout du monde. Pendant que l’Evangile se répandait, des Lettres étaient écrites pour instruire et encourager les églises. Cette histoire de l’église du premier siècle couvre une période d’à peu près 60 ans.",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",

		stagImgSrc: stage2image,
		sectionTitle: "Eglise / Les Evènements marquants",
		sectionImgSrc: evenements,
		questions: [
			{
				id: 1,
				 text: "Dans quel chapitre du Livre des Actes se trouve l’histoire de la formation de l’église d’Ephèse? ",
    options: [
        { id: 0, text: "Actes 16 ", isCorrect: false },
        { id: 1, text: "Actes 17 ", isCorrect: false },
        { id: 2, text: "Actes 19 ", isCorrect: true },
				],
			},
			{
				id: 2,
				text: "Pendant combien de temps, Paul a t-il séjourné et travaillé dans la région d’Asie?",
    options: [
        { id: 0, text: "18 mois ", isCorrect: false },
        { id: 1, text: "2 ans ", isCorrect: false },
        { id: 2, text: "3 ans ", isCorrect: true },
				],
			},
   
                        {
				id: 3,
				 text: "Quelle est la référence de ce passage: « Un Juif du nom d'Apollos, originaire d'Alexandrie, homme éloquent et versé dans les Écritures, était arrivé à Éphèse? »  ",
    options: [
        { id: 0, text: "Actes 18 : 1 – 2 ", isCorrect: false },
        { id: 1, text: "Actes18 : 18 ", isCorrect: false },
        { id: 2, text: "Actes 18 : 24 ", isCorrect: true },
				],
			},
			
			{
				id: 4,
				text: "La rencontre de Paul avec les responsables d’Ephèse à Milet se trouve en : ",
    options: [
        { id: 0, text: "Actes 20 ", isCorrect: true },
        { id: 1, text: "Actes 21 ", isCorrect: false },
        { id: 2, text: "Actes 22 ", isCorrect: false },
				],
			},
			/*stage1-sec*/
			{
				id: 5,
				 text: "Quelle est la référence de: « Partis le lendemain, nous sommes arrivés à Césarée. Étant entrés dans la maison de Philippe l'évangéliste, qui était l'un des sept, nous avons demeuré chez lui. »? ",
    options: [
        { id: 0, text: "Actes 21 : 8 ", isCorrect: true },
        { id: 1, text: "Actes 8 : 21 ", isCorrect: false },
        { id: 2, text: "Actes 21 : 17 ", isCorrect: false },
				],
			},
			
			{
				id: 6,
				 text: "Où trouver ce passage ?« La ville entière fut secouée, et le peuple accourut de toutes parts. Ils se saisirent de Paul et le traînèrent hors du temple, dont les portes furent aussitôt fermées. » ",
    options: [
        { id: 0, text: "Actes 21 : 8 ", isCorrect: false },
        { id: 1, text: "Actes 8 : 21  ", isCorrect: false },
        { id: 2, text: "Actes 21 : 30 ", isCorrect: true },
				],
			},
			
			{
				id: 7,
				text: "Le récit de l’arrestation de Paul se trouve en : ",
    options: [
        { id: 0, text: "Actes 21 : 30 ", isCorrect: true },
        { id: 1, text: "Actes 21 : 26 ", isCorrect: false },
        { id: 2, text: "Actes 21 : 17 ", isCorrect: false },
				],
			},
			
			{
				id: 8,
				text: "Paul fait son témoignage devant le gouverneur Festus et le roi Agrippa en : ",
    options: [
        { id: 0, text: "Actes 25  ", isCorrect: false },
        { id: 1, text: "Actes 26 ", isCorrect: true },
        { id: 2, text: "Actes 27 ", isCorrect: false },
				],
			},
			
			{
				id: 9,
				 text: "Le départ du prisonnier Paul pour Rome se trouve en : ",
    options: [
        { id: 0, text: "Actes 26 ", isCorrect: false },
        { id: 1, text: "Actes 27  ", isCorrect: true },
        { id: 2, text: "Actes 28 ", isCorrect: false },
				],
			},
			
			{
				id: 10,
				text: "Paul fait son témoignage devant le Grand Conseil en : ",
    options: [
        { id: 0, text: "Actes 23 ", isCorrect: true },
        { id: 1, text: "Actes 24 ", isCorrect: false },
        { id: 2, text: "Actes 26 ", isCorrect: false },
				],
			},
			
			{
				id: 11,
				 text: "La promesse du baptême du Saint -Esprit se trouve en : ",
    options: [
        { id: 0, text: "Actes 1 : 4-  8  ", isCorrect: true },
        { id: 1, text: "Actes 2 : 1 – 5  ", isCorrect: false },
        { id: 2, text: "Actes 2: 36 – 38 ", isCorrect: false }, 
				],
			},
			
			{
				id: 12,
				text: "Parmi ces critères, lequel n’ était pas dans le choix du remplaçant de Juda, comme apôtre ? ",
    options: [
        { id: 0, text: " Un disciple de Jésus intègre et droit et avec un esprit missionnaire", isCorrect: true },
        { id: 1, text: "L’un de ceux qui les ont accompagnés durant tout ce temps ", isCorrect: false },
        { id: 2, text: "Un témoin de la résurrection de Jésus ", isCorrect: false }, 
				],
			},
			
			{
				id: 13,
				 text: "Paul fait son témoignage devant le gouverneur Félix en : ",
    options: [
        { id: 0, text: "Actes 22 ", isCorrect: false },
        { id: 1, text: "Actes 23  ", isCorrect: false },
        { id: 2, text: "Actes 24 ", isCorrect: true },
				],
			},
			
			{
				id: 14,
				text: "Le jour de la descente du Saint – Esprit en Actes 2 , quelle information, ici, est fausse ? ",
    options: [
        { id: 0, text: "Ce fut le jour de la Pentecôte ", isCorrect: false },
        { id: 1, text: "Ce fut à Jérusalem ", isCorrect: false },
        { id: 2, text: "Ce jour-là, toute la multitude de juifs qui accoururent et se rassemblèrent parlèrent en langue.", isCorrect: true },
				],
			},
			
			{
				id: 15,
				 text: "La première personne que pierre a guéri, mentionné dans le Livre des Actes a été : ",
    options: [
        { id: 0, text: " Un homme paralysé depuis sa naissance ", isCorrect: true },
        { id: 1, text: "Un homme porté sur une civière que l’ombre de Pierre a couvert ", isCorrect: false },
        { id: 2, text: "Un homme du nom de Enée qui n’avait pas quitté son lit depuis huit  ans parce qu’il était paralysé", isCorrect: false },
				],
			},
			
			{
				id: 16,
				text: "Où se trouve ce texte: « Simon lui-même crut aussi et, après avoir été baptisé, il ne quittait plus Philippe et voyait avec étonnement les grands signes et miracles qui se produisaient. »? ",
    options: [
        { id: 0, text: "Actes 13 : 8 ", isCorrect: false },
        { id: 1, text: "Actes 8 : 13 ", isCorrect: true },
        { id: 2, text: "Actes 8 : 18 ", isCorrect: false }, 
				],
			},
			
			{
				id: 17,
				 text: "Le premier témoignage de Paul après son arrestation se trouve en : ",
    options: [
        { id: 0, text: "Actes 21 ", isCorrect: false },
        { id: 1, text: "Actes 22 ", isCorrect: true },
        { id: 2, text: "Actes 23 ", isCorrect: false },
				],
			},
			
			{
				id: 18,
				 text: "L’histoire de Paul qui « s’oppose » à Pierre, se trouve en : ",
    options: [
        { id: 0, text: "Actes 9 ", isCorrect: false },
        { id: 1, text: "Actes 15 ", isCorrect: false },
        { id: 2, text: "Galates 2 ", isCorrect: true },
				],
			},
			
			{
				id: 19,
				 text: "« Quand ils eurent fini de prier, la terre se mit à trembler sous leurs pieds à l'endroit où ils étaient assemblés. Ils furent tous remplis du Saint-Esprit et annonçaient la Parole de Dieu avec assurance. » ",
    options: [
        { id: 0, text: "Actes 3 : 23 ", isCorrect: false },
        { id: 1, text: "Actes 4 : 31 ", isCorrect: true },
        { id: 2, text: "Actes 6 : 31 ", isCorrect: false },
				],
			},
			
			{
				id: 20,
				text: "Quelles sont les références de ce passage : « Un nombre toujours croissant d'hommes et de femmes croyaient au Seigneur et se joignaient à eux. » ",
      options: [
        { id: 0, text: "Actes 5 : 14 ", isCorrect: true },
        { id: 1, text: "Actes 3 : 14 ", isCorrect: false },
        { id: 2, text: "Actes 12 : 14 ", isCorrect: false },
				],
			},
			
			{
				id: 21,
				text: "« Car il n'y avait parmi eux aucun indigent ; tous ceux qui possédaient des champs ou des maisons les vendaient, apportaient le prix de ce qu'ils avaient vendu et le déposaient aux pieds des apôtres ; et l'on distribuait à chacun selon qu'il en avait besoin.. » ",
      options: [
        { id: 0, text: "Actes 2 : 42 – 43 ", isCorrect: false },
        { id: 1, text: " Actes 3 : 42 – 43", isCorrect: false },
        { id: 2, text: "Actes 4 : 34 – 35 ", isCorrect: true },
				],
			},
			
			{
				id: 22,
				text: "« Dès lors, ils s'attachaient à écouter assidûment l'enseignement des apôtres, à vivre en communion les uns avec les autres, à rompre le pain et à prier ensemble. » ",
      options: [
        { id: 0, text: "Actes 2 : 5 ", isCorrect: false },
        { id: 1, text: "Actes 2 : 36 ", isCorrect: false },
        { id: 2, text: "Actes 2 : 42 ", isCorrect: true },
				],
			},
			
			{
				id: 23,
				text: "Le récit de la formation de l’église d’Antioche se trouve en : ",
      options: [
        { id: 0, text: "Actes 8 ", isCorrect: false },
        { id: 1, text: "Actes 11 ", isCorrect: true },
        { id: 2, text: "Actes 13 ", isCorrect: false },
				],
			},
			
			{
				id: 24,
				text: "Combien de voyage missionnaire (d’implantation d’églises), Paul et son équipe ont-ils fait, à partir d’Antioche ? ",
      options: [
        { id: 0, text: "Trois ", isCorrect: true },
        { id: 1, text: "Cinq ", isCorrect: false },
        { id: 2, text: "Sept  ", isCorrect: false },
				],
			},
			
			{
				id: 25,
				text: "Parmi ces chapitres du Livre des Actes, dans lequel, le premier voyage missionnaire de Paul est – il mentionné ? ",
      options: [
        { id: 0, text: "Actes 11 ", isCorrect: false },
        { id: 1, text: "Actes 13 ", isCorrect: true },
        { id: 2, text: "Actes 16 ", isCorrect: false },
				],
			},
			
			{
				id: 26,
				text: "Dans quel chapitre, du Livre des Actes, se trouve mentionné, le débat à Jérusalem, sur la circoncision des non juifs ? ",
      options: [
        { id: 0, text: "Actes 13 ", isCorrect: false },
        { id: 1, text: "Actes 15 ", isCorrect: true },
        { id: 2, text: "Actes 17 ", isCorrect: false },				],
			},
			
			{
				id: 27,
				text: "Quelles sont les références de ce passage biblique qui parle de la séparation de Paul d’avec Barnabas ? ",
      options: [
        { id: 0, text: "Actes 14 : 36 – 40 ", isCorrect: false },
        { id: 1, text: "Actes 16 : 31 – 40 ", isCorrect: false },
        { id: 2, text: "Actes 15 : 36 – 40 ", isCorrect: true },
				],
			},
			
			{
				id: 28,
				text: "Dans quel chapitre du Livre des Actes, l’église de Philippe a été implantée ? ",
      options: [
        { id: 0, text: "Actes 16 ", isCorrect: true },
        { id: 1, text: "Actes 17 ", isCorrect: false },
        { id: 2, text: "Actes 18 ", isCorrect: false },
				],
			},
			
			{
				id: 29,
				 text: "Le récit de la formation de l’église de Corinthe se trouve en : ",
      options: [
        { id: 0, text: "Actes 18 ", isCorrect: true },
        { id: 1, text: "Actes 20 ", isCorrect: false },
        { id: 2, text: "Actes 21 ", isCorrect: false },
				],
			},
			
			{
				id: 30,
				text: "Dans quel chapitre du Livre des Actes, Timothée a-t-il été appelé par Paul, pour la mission ? ",
      options: [
        { id: 0, text: "Actes 14 ", isCorrect: false },
        { id: 1, text: "Actes 15 ", isCorrect: false },
        { id: 2, text: "Actes 16 ", isCorrect: true },
				],
			},
			
			{
				id: 31,
				text: "Le débat de Paul à Athènes (à l’Aéropage) avec les philosophes, se trouve en : ",
      options: [
        { id: 0, text: "Actes 17 ", isCorrect: true },
        { id: 1, text: "Actes 18 ", isCorrect: false },
        { id: 2, text: "Actes 13 ", isCorrect: false },
				],
			},
			
			{
				id: 32,
				text: "Combien de personne avaient été choisis pour assister les apôtres en Actes 6 ? ",
      options: [
        { id: 0, text: "Trois ", isCorrect: false },
        { id: 1, text: "Sept  ", isCorrect: true },
        { id: 2, text: "Dix ", isCorrect: false },
				],
			},
			
			{
				id: 33,
				text: "Quelles sont les références de ce texte ? « La parole de Dieu se répandait, le nombre des disciples se multipliait beaucoup à Jérusalem, et une grande foule de sacrificateurs obéissait à la foi. » ",
      options: [
        { id: 0, text: "Actes 6 : 7 ", isCorrect: true },
        { id: 1, text: "Actes 7 : 6 ", isCorrect: false },
        { id: 2, text: "Actes 17 : 6 ", isCorrect: false },
				],
			},
			
			{
				id: 34,
				text: "Comment  Etienne est-il  mort? ",
      options: [
        { id: 0, text: "Par lapidation ", isCorrect: true },
        { id: 1, text: "Par crucifixion ", isCorrect: false },
        { id: 2, text: "Par l’épée ", isCorrect: false },
				],
			},
			
			{
				id: 35,
				text: "Quelles sont les références de ce texte ? « Cependant Saul, qui respirait encore la menace et le meurtre contre les disciples du Seigneur, se rendit chez le souverain sacrificateur »  ",
      options: [
        { id: 0, text: "Actes 9 : 1 ", isCorrect: true },
        { id: 1, text: "Actes 13 : 1 ", isCorrect: false },
        { id: 2, text: "Actes 18 : 1 ", isCorrect: false },
				],
			},
			
			{
				id: 36,
				text: "Le récit de la conversion de l’eunuque éthiopien se trouve en : ",
      options: [
        { id: 0, text: "Actes 6 ", isCorrect: false },
        { id: 1, text: "Actes 8 ", isCorrect: true },
        { id: 2, text: "Actes 9 ", isCorrect: false },
				],
			},
			
			{
				id: 37,
				text: "Qu’est-ce qui a été à l’origine des tensions entre les juifs de culture grecque et ceux de la Palestine en Actes 6 ? ",
      options: [
        { id: 0, text: "Les disciples juifs de culture grecque se plaignaient de ce que leurs veuves étaient défavorisées lors des distributions quotidiennes. ", isCorrect: true },
        { id: 1, text: "Les disciples Juifs de culture grecque se plaignaient de ce que ceux de la Palestine les méprisaient du fait de leur incirconcision ", isCorrect: false },
        { id: 2, text: "Les disciples de culture juive se plaignaient de ce que ceux de culture grecque refusaient les pratiques du Judaïsme ", isCorrect: false },
				],
			},
			
			{
				id: 38,
				 text: "Quel est le couple chrétien qui a menti à l’Eglise et au Saint-Esprit et qui moururent à cause de cela ? ",
      options: [
        { id: 0, text: "Priscille et Aquilas ", isCorrect: false },
        { id: 1, text: "Achab et Jézabel ", isCorrect: false },
        { id: 2, text: "Ananias et Saphira ", isCorrect: true },
				],
			},
			
			{
				id: 39,
				text: "Où se trouve ce texte ?« Simon lui-même crut aussi et, après avoir été baptisé, il ne quittait plus Philippe et voyait avec étonnement les grands signes et miracles qui se produisaient. » ",
      options: [
        { id: 0, text: "Actes 13 : 8 ", isCorrect: false },
        { id: 1, text: "Actes 8 : 13 ", isCorrect: true },
        { id: 2, text: "Actes 8 : 18 ", isCorrect: false },
				],
			},
			
			{
				id: 40,
				text: "Le jour de la descente du Saint-Esprit en Actes 2 , qui prit la parole pour s’adresser à la multitude présente ? ",
      options: [
        { id: 0, text: "Jean ", isCorrect: false },
        { id: 1, text: "Paul ", isCorrect: false },
        { id: 2, text: "Pierre ", isCorrect: true },
				],
			},
		]
	},

	{
		gameId: 11,
		jeuPosition: " stage 3 / session 1",
		sectQuest:" stag3ses1",
		jeuTitle:
			"La Période des commencements, des patriarches et de l’Exode (Les Livres de Moïse)",
		paraf1:
			"Si vous ouvrez votre Bible, à la première page vous lirez : « Au commencement, Dieu créa le ciel et la terre. ». Le premier verset de la Bible mentionne deux entités distinctes : Le Créateur et la création. ",
		paraf2:
			"Les Livres de Moïse partent de la Genèse à Deutéronome et relatent l’histoire de la création - dont l’homme est l’entité principale- la chute de l’homme, la multiplication de l’espèce humaine, l’histoire du déluge, la naissance des nations et des races, l’origine du peuple de Dieu, son séjour en Egypte, sa sortie et sa marche dans le désert pendant 40 ans, tout juste avant la conquête de Canaan. ",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",

		stagImgSrc: stage3image,
		sectionTitle: "LivresdeMoïse / Les Personnages",
		sectionImgSrc: personnages,
		questions: [
			{
				id: 1,
				 text: "Adam fut le premier homme créé par Dieu  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			{
				id: 2,
				text: "Quand Caïn fut chassé devant la présence de l’Eternel, il n’eut pas de descendants   ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 3,
				 text: "Abel : Il fut le premier fils d’Adam et Eve   ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 4,
				text: "Seth : C’est à son époque que les hommes ont commencé à prier l’Eternel ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 5,
				 text: "Abraham : Son père s’appelait Terah et il fut le premier juif ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 6,
				 text: "Sarah : Femme d’Abraham, elle fut la mère d’Ismaël et d’Isaac ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 7,
				text: "Isaac : Premier fils d’Abraham, son nom signifie « il a ri » ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 8,
				 text: "Rebecca : Elle fut la mère d’Esaü et femme d’Isaac  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 9,
				 text: "Sem : Il fut fils de Noé et père de Canaan ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 10,
				text: "Lévi : Il était fils de Jacob et de Rachel ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 11,
				 text: "Moïse : Il fut le premier à être appelé « Prophète »  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 12,
				text: "Laban, oncle d’Esaü, c’est chez lui que se refugia Jacob ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 13,
				 text: "Séphora, femme de Moïse, elle refusa de le suivre en Egypte ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 14,
				text: "Lot était le cousin d’Abraham ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 15,
				 text: "Esaü, frère de Joseph est le père d’Edom ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 16,
				text: "Jacob eut au total douze enfants avec quatre femmes ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 17,
				 text: "Rachel n’eut réellement qu’un fils, Joseph  ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 18,
				 text: "Agar, mère d’Ismaël, était d’origine cananéenne ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 19,
				 text: " Abimélek : Deux rois de Guérar portaient ce nom et firent alliance l’un avec Abraham et l’autre avec Isaac ",
    options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 20,
				text: " Balak fut ce devin à qui Balaam, roi de Moab, avait demandé de maudire Israel ",
    options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 21,
				text: "C’est à l’époque de qui les hommes ont commencé à prier en invoquant l’Eternel",
    options: [
        { id: 0, text: "Abel", isCorrect: false },
        { id: 1, text: "Seth ", isCorrect: true },
        { id: 2, text: "Noé", isCorrect: false }, 
				],
			},
			
			{
				id: 22,
				text: " Dans quel chapitre du Livre de la Genèse, le nom d’Abram fut changé en Abraham ",
    options: [
        { id: 0, text: "Genèse 12 ", isCorrect: false },
        { id: 1, text: "Genèse 16 ", isCorrect: false },
        { id: 2, text: "Genèse 17", isCorrect: true }, 
				],
			},
			
			{
				id: 23,
				text: " Le premier homme qui a tué son frère (fratricide) s’appelait: ",
    options: [
        { id: 0, text: "Acan", isCorrect: false },
        { id: 1, text: "Abel ", isCorrect: false },
        { id: 2, text: "Caïn", isCorrect: true }, 
				],
			},
			
			{
				id: 24,
				text: " Adam et Eve ont été chassé de l’Eden, parce que : ",
    options: [
        { id: 0, text: "Ils ont refusé de labourer le sol ", isCorrect: false },
        { id: 1, text: "Leurs enfants ont désobéi à Dieu", isCorrect: false },
        { id: 2, text: "Ils ont mangé le fruit interdit", isCorrect: true }, 
				],
			},
			
			{
				id: 25,
				text: " Il fût le premier homme à être enlevé au ciel par Dieu : ",
    options: [
        { id: 0, text: "Adam ", isCorrect: false },
        { id: 1, text: "Noé", isCorrect: false },
        { id: 2, text: "Enock", isCorrect: true }, 
				],
			},
			
			{
				id: 26,
				text: " Que signifie le nom de Noé ? ",
    options: [
        { id: 0, text: "Consolation", isCorrect: true },
        { id: 1, text: "Rédemption", isCorrect: false },
        { id: 2, text: "Navigateur", isCorrect: false },
				],
			},
			
			{
				id: 27,
				text: " Parmi ces personnages, lequel n’est pas fils de Noé ? ",
    options: [
        { id: 0, text: "Japhet", isCorrect: false },
        { id: 1, text: "Mathusalem ", isCorrect: true },
        { id: 2, text: "Cham", isCorrect: false }, 
				],
			},
			
			{
				id: 28,
				text: " Qu’est – ce qui n’est pas vrai concernant Yokébed ? ",
    options: [
        { id: 0, text: "Mère de Moïse", isCorrect: false },
        { id: 1, text: "Tante de Amram, père de Moïse ", isCorrect: false },
        { id: 2, text: "Femme d’Aaron", isCorrect: true },  
				],
			},
			
			{
				id: 29,
				text: " Parmi ces personnages qui n‘est pas fils de Terah ? ",
    options: [
        { id: 0, text: "Abram ", isCorrect: false },
        { id: 1, text: "Harân ", isCorrect: false },
        { id: 2, text: "Lot  ", isCorrect: true }, 
        
				],
			},
			
			{
				id: 30,
				text: " Mathusalem vécut jusqu’à combien d’année ? ",
    options: [
        { id: 0, text: "930 ans ", isCorrect: false },
        { id: 1, text: "969 ans ", isCorrect: true },
        { id: 2, text: "990 ans", isCorrect: false }, 
				],
			},
			
			{
				id: 31,
				text: " Qu’est – ce qui n’est pas vrai concernant Hénok ? ",
    options: [
        { id: 0, text: "Hénok, après la naissance de Mathusalem, marcha 300 ans avec Dieu et il engendra des fils et des filles.", isCorrect: false },
        { id: 1, text: "La durée totale de sa vie fut de 300 ans. ", isCorrect: true },
        { id: 2, text: "Hénok marcha avec Dieu ; puis il ne fut plus, parce que Dieu l'enleva.", isCorrect: false },
				],
			},
			
			{
				id: 32,
				text: "Qu’est – ce qui n’est pas vrai concernant Nimrod ? ",
    options: [
        { id: 0, text: "Sem engendra aussi Nimrod ", isCorrect: true },
        { id: 1, text: "Il fut un vaillant Chasseur devant l'Éternel ", isCorrect: false },
        { id: 2, text: "Il régna d'abord sur Babel, Erek, Akkad et Kalné, au pays de Chinéar", isCorrect: false },
				],
			},
			
			{
				id: 33,
				text: " Qu’est – ce qui ‘n’est pas vrai concernant Aaron ? ",
    options: [
        { id: 0, text: "Frère ainé de Moïse", isCorrect: false },
        { id: 1, text: "Le premier sacrificateur d’Israël", isCorrect: false },
        { id: 2, text: "Le premier à être appelé « Prophète »", isCorrect: true }, 
				],
			},
			
			{
				id: 34,
				text: " Qui était Elisheba ? ",
    options: [
        { id: 0, text: "Femme d’Aaron", isCorrect: true },
        { id: 1, text: "Fils d’Eli", isCorrect: false },
        { id: 2, text: "Fils d’Aaron", isCorrect: false },
				],
			},
			{
				id: 35,
				text: " Qu’est – ce qui ‘n’est pas vrai concernant Jethro ?",
    options: [
        { id: 0, text: "Prêtre de Madian, beau-père de Moïse", isCorrect: false },
        { id: 1, text: "Appelé aussi Réouel (ou Rehuel)", isCorrect: false },
        { id: 2, text: "Moïse travailla pour lui en tant que berger pendant 25 ans", isCorrect: true }, 
				],
			},
			{
				id: 36,
				text: " Qu’est – ce qui ‘n’est pas vrai concernant Melchisédech ? ",
    options: [
        { id: 0, text: "Melchisédech, roi de Salem, fit apporter du pain et de l’eau ", isCorrect: true },
        { id: 1, text: "Il était sacrificateur du Dieu Très-Haut et bénit Abram ", isCorrect: false },
        { id: 2, text: "C’est à lui qu’Abraham donna le dixième (la dîme) de tout son butin", isCorrect: false }, 
				],
			},
			{
				id: 37,
				text: " Qu’est – ce qui ‘n’est pas vrai concernant Ismaël ? ",
    options: [
        { id: 0, text: "Fils ainé d’Abraham", isCorrect: false },
        { id: 1, text: "Sa mère s’appelait Agar", isCorrect: false },
        { id: 2, text: "L’héritier de la promesse de Dieu faite à Abraham", isCorrect: true }, 
				],
			},
			{
				id: 38,
				text: " Parmi ces personnages, qui n’était pas, directement, fils de Cham ? ",
    options: [
        { id: 0, text: "Nimrod", isCorrect: true },
        { id: 1, text: "Kouch", isCorrect: false },
        { id: 2, text: "Mitsraïm, ", isCorrect: false }, 
				],
			},
			{
				id: 39,
				text: " Quel est le nom du premier polygame mentionné dans la Bible ?",
    options: [
        { id: 0, text: "Caïn", isCorrect: false },
        { id: 1, text: "Lemek", isCorrect: true },
        { id: 2, text: "Abraham ", isCorrect: false }, 
				],
			},
			
			{
				id: 40,
				text: " Qu’est – ce qui ‘n’est pas vrai concernant Joseph ?",
    options: [
        { id: 0, text: "Onzième fils de Jacob, aîné de Rachel", isCorrect: false },
        { id: 1, text: "Il fut vendu par ses frères à des marchands égyptiens", isCorrect: true },
        { id: 2, text: "Le pharaon lui donna en mariage Asnath, fille du prêtre d'On", isCorrect: false }, 
				],
			},


		],
	},
	{
		gameId: 12,
		jeuPosition: " stage 3 / session 2",
		sectQuest:" stag3ses2",
		jeuTitle:
			"La Période des commencements, des patriarches et de l’Exode (Les Livres de Moïse)",
		paraf1:
			"Si vous ouvrez votre Bible, à la première page vous lirez : « Au commencement, Dieu créa le ciel et la terre. ». Le premier verset de la Bible mentionne deux entités distinctes : Le Créateur et la création. ",
		paraf2:
			"Les Livres de Moïse partent de la Genèse à Deutéronome et relatent l’histoire de la création - dont l’homme est l’entité principale- la chute de l’homme, la multiplication de l’espèce humaine, l’histoire du déluge, la naissance des nations et des races, l’origine du peuple de Dieu, son séjour en Egypte, sa sortie et sa marche dans le désert pendant 40 ans, tout juste avant la conquête de Canaan. ",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",

		stagImgSrc: stage3image,
		sectionTitle: "LivresdeMoïse / Les Lieux",
		sectionImgSrc: lieux,
		questions: [
			{
				id: 1,
				 text: "Chaldée : C’était le Pays d’origine d’Abraham ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			{
				id: 2,
				text: "Béthel : Appelé autrefois par les Cananéens Louz, elle signifiait Maison de Dieu. ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
   
                        {
				id: 3,
				 text: "Sodome : C’est une ville de la Chaldée, qui a été détruite à cause du mal qui y régnait ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 4,
				text: "Gomorrhe : Associé à Sodome, elle fut anéantie par le feu du ciel",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 5,
				 text: "Hébron : Ville de la région montagneuse de Juda, appelée à l'origine, Qiryath-Arba, Isaac et Jacob y séjournèrent quelque temps.  ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 6,
				 text: "Adma : La première Ville fondée par Seth, en l’honneur d’Adam, fut détruite en même temps que Sodome ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 7,
				text: "C’est à Guérar où séjournait Abraham qu’Abimélek, le roi fit enlever Sara.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 8,
				text: "Beer – Sheba : C’était une plaine fertile, dans laquelle s’égara Agar ; c’est là aussi que l'ange de Dieu appela Agar du haut du ciel et lui dit : Qu'as-tu, Agar ? Sois sans crainte, car Dieu a entendu la voix du garçon, là où il est. ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 9,
				 text: "A Réphidim le peuple entra en contestation avec Moïse, parce qu’ils réclamait de la viande à manger, et Dieu leur donna la caille   ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 10,
				text: "Désert de Sin : Toute la communauté des Israélites partit du désert de Sin selon l'ordre de l'Éternel ; ils campèrent à Rephidim , il n'y avait point d'eau à boire pour le peuple. ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 11,
				 text: "La mer des roseaux : La mer des roseaux s’appelait aussi la mer rouge ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 12,
				text: "La seconde année, le second mois, le 20 du mois, la nuée s'éleva de dessus le tabernacle du Témoignage. Les Israélites partirent du désert du Sinaï, selon l'ordre fixé pour leur départ. La nuée s'arrêta dans le désert de Parân.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 13,
				 text: "Tabéira : Le nom de ce lieu signifie « brûlant » et c’est en ce Lieu que les Israélites excitèrent par leurs plaintes la colère de l'Eternel, qui les châtia en envoyant le feu à l'extrémité du camp. L'incendie s'arrêta grâce à l'intercession de Josué. ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 14,
				text: "Désert de Paran : C’est là qu’Ismaël s’établit avec sa mère qui prit pour lui une femme du pays de Canaan ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 15,
				 text: "Le Pharaon apprit que Moïse avait tué un égyptien. Moïse prit la fuite loin du Pharaon et vint résider dans le pays de Madian. Il y restât pendant 25 ans. ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 16,
				text: "Moïse faisait paître le troupeau de Jéthro, son beau-père, sacrificateur de Madian ; il mena le troupeau au-delà du désert et se rendit à la montagne de Dieu, à Moriya. ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 17,
				 text: "Péniel : C’est en ce lieu que le nom de Jacob fut changé en Israël",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 18,
				 text: "Le séjour que les Israélites firent en Égypte fut de 430 ans. Au bout de 430 ans, ce jour précis, toutes les troupes de l'Éternel sortirent du pays d'Égypte.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				text: "Après la mort d'Abraham, Dieu bénit son fils Isaac qui habitait près du puits de Lahaï-roï.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 20,
				text: "Sichem : C’est dans cette localité qu’auparavant les fils de Jacob avaient massacré des populations à cause du viol de leur sœur ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },
				],
			},
			
			{
				id: 21,
				text: "Sur quelle montagne s’est posée l’arche de Noé après le déluge ?",
      options: [
        { id: 0, text: "Moriya", isCorrect: false },
        { id: 1, text: "Horeb", isCorrect: false },
        { id: 2, text: "Ararat", isCorrect: true },
				],
			},
			
			{
				id: 22,
				text: "Qu’est-ce qui n’est pas vrai concernant le Jardin d’Eden ?",
      options: [
        { id: 0, text: "Sept fleuves, dont l’Euphrate et le Tigre arrosaient ce jardin", isCorrect: true },
        { id: 1, text: "C’est l’Eternel Dieu qui planta ce jardin vers l’Orient", isCorrect: false },
        { id: 2, text: "L’arbre de la vie se trouvait au milieu de ce jardin", isCorrect: false }, 
				],
			},
			
			{
				id: 23,
				text: "Qu’est -ce qui n’est pas vrai concernant « Babel » ?",
      options: [
        { id: 0, text: "Ce mot d’origine Akkadienne signifie « Porte des dieux »", isCorrect: false },
        { id: 1, text: "Ce fut une ville que les hommes de cette époque ont bâtit et achevé, grâce à leur unité", isCorrect: true },
        { id: 2, text: "C’est à partir de là que l’Eternel a confondu le langage des hommes", isCorrect: false },
				],
			},
			
			{
				id: 24,
				text: "Qu’est -ce qui n’est pas vrai concernant Our (Ur) ?",
      options: [
        { id: 0, text: "Our se trouvait en Chaldée", isCorrect: false },
        { id: 1, text: "C’était le pays natal d’Abraham", isCorrect: false },
        { id: 2, text: "C’était aussi une région Cananéenne", isCorrect: true }, 
				],
			},
			
			{
				id: 25,
				text: "Qu’est -ce qui n’est pas vrai concernant Canaan ?",
      options: [
        { id: 0, text: "Abraham y fut nomade", isCorrect: false },
        { id: 1, text: "Isaac et sa famille y vécut", isCorrect: false },
        { id: 2, text: "Les Cananéens étaient descendants de Japhet, fils de Noé", isCorrect: true },
				],
			},
			
			{
				id: 26,
				text: "Qu’est -ce qui n’est pas vrai concernant Bethel ?",
      options: [
        { id: 0, text: "Son nom d’origine était Louz", isCorrect: false },
        { id: 1, text: "Jacob y fit un rêve lors de sa fuite vers Padam -Aram", isCorrect: false },
        { id: 2, text: "Issac y construisît un autel", isCorrect: true },
				],
			},
			
			{
				id: 27,
				text: "Qu’est -ce qui n’est pas vrai concernant la région du Néguev ?",
      options: [
        { id: 0, text: "Une région désertique au sud de l’Egypte", isCorrect: true },
        { id: 1, text: "Qadech-Barnea et  Lahaï-roi sont deux localités importantes de cette région", isCorrect: false },
        { id: 2, text: "Après que l’Eternel apparut à Abraham et lui promit le pays à sa descendance, il bâtit un autel à l’Eternel et se dirigea vers le Néguev", isCorrect: false },
				],
			},
			
			{
				id: 28,
				text: "Qu’est -ce qui n’est pas vrai concernant Makpéla ?",
      options: [
        { id: 0, text: "Un Champ situé en face de Mamré, ayant une grotte et appartenant à Ephrôn, le Hittite  fut acquis par Abraham pour 400 sicles d'argent, afin d'ensevelir Sara.", isCorrect: false },
        { id: 1, text: "Abraham lui-même, Isaac et Rébecca y furent ensevelis ", isCorrect: false },
        { id: 2, text: "Jacob et ses femmes Rachel et Léa furent ensevelis dans cette grotte aussi", isCorrect: true },
				],
			},
			
			{
				id: 29,
				text: "Qu’est -ce qui n’est pas vrai concernant Sodome ? ",
      options: [
        { id: 0, text: "C’était une ville de la plaine du Jourdain", isCorrect: false },
        { id: 1, text: "Elle est très souvent associée à Gomorrhe", isCorrect: false },
        { id: 2, text: "Abraham et Lot y vécurent avant sa destruction", isCorrect: true },
				],
			},
			
			{
				id: 30,
				text: "Vallée de Hébron : Qu’est -ce qui n’est pas vrai concernant ce lieu ?",
      options: [
        { id: 0, text: "Cette localité s’appelle aussi Qiriath - Haarba ", isCorrect: false },
        { id: 1, text: "C’est de là que Jacob envoya Joseph à la rencontre de ses frères ", isCorrect: false },
        { id: 2, text: "Elle était aussi appelée « la Vallée des Réphidims »", isCorrect: true },
				],
			},
			
			{
				id: 31,
				text: "Abel – Mitsraïm : Qu’est -ce qui n’est pas vrai concernant ce lieu ?",
      options: [
        { id: 0, text: "C’est là que les enfants d’Abraham célébrèrent ses funérailles", isCorrect: true },
        { id: 1, text: "Le nom de ce lieu signifie « Deuil de l’Egypte »", isCorrect: false },
        { id: 2, text: "Ce sont les cananéens qui donnèrent ce nom en voyant les funérailles de Jacob sur l’aire d’Atad", isCorrect: false },
				],
			},
			
			{
				id: 32,
				text: "Quand les israélites quittèrent la mer des roseaux et prirent la direction du désert de Chour, pendant combien de temps y marchèrent-ils sans trouver de l’eau ?",
      options: [
        { id: 0, text: "Pendant trois jours", isCorrect: true },
        { id: 1, text: "Pendant trois semaines", isCorrect: false },
        { id: 2, text: "Pendant trois mois", isCorrect: false },
				],
			},
			
			{
				id: 33,
				text: "Egypte : Qu’est -ce qui n’est pas vrai concernant ce lieu ?",
      options: [
        { id: 0, text: "C’est dans ce pays que Jacob et sa famille émigrèrent ", isCorrect: false },
        { id: 1, text: "C’est là- bas que, Joseph vendu par ses frères fut amené comme esclave.", isCorrect: false },
        { id: 2, text: "Le peuple d’Israël y a vécu comme esclave pendant 700 ans", isCorrect: true },
				],
			},
			
			{
				id: 34,
				text: "Galaad : Qu’est -ce qui n’est pas vrai concernant ce lieu ?",
      options: [
        { id: 0, text: "La caravane d’ismaélites qui achetèrent Joseph venait de cette région", isCorrect: false },
        { id: 1, text: "Quand Jacob s’enfuit de chez Laban avec tout ce qui lui appartenait, il traversa l’Euphrate et se dirigea vers les monts de cette contrée", isCorrect: false },
        { id: 2, text: "Le premier né de Gad est l’ancêtre des Galaadites", isCorrect: true },
				],
			},
			
			{
				id: 35,
				text: "Ramsès : Qu’est -ce qui n’est pas vrai concernant ce lieu ? ",
      options: [
        { id: 0, text: "Joseph a installé son père et ses frères dans cette région de l’Egypte ", isCorrect: false },
        { id: 1, text: "Cette ville a eu plusieurs noms dont Tsoan, Avaris, Tanis et même Alexandrie", isCorrect: true },
        { id: 2, text: "C’est de là que les enfants d’Israël, lors de l’Exode partirent pour Soukkoth", isCorrect: false },
				],
			},
			
			{
				id: 36,
				text: "Mont Sinaï : Qu’est -ce qui n’est pas vrai concernant cette montagne ?",
      options: [
        { id: 0, text: "Au pied de cette montagne, s'étendait une région désertique où le peuple dressa ses tentes ; ils l'atteignirent trois ans après leur départ de l'Egypte", isCorrect: true },
        { id: 1, text: "C’est sur cette montagne que les dix commandements furent ordonnés", isCorrect: false },
        { id: 2, text: "C’est sur cette montagne que l’Eternel fut alliance avec Israël", isCorrect: false },
				],
			},
			
			{
				id: 37,
				text: "Sur quelle montagne mourut le prêtre Aaron après l’avoir gravi, sur ordre de l’Eternel ?",
      options: [
        { id: 0, text: "Horeb", isCorrect: false },
        { id: 1, text: "Séir", isCorrect: false },
        { id: 2, text: "Hor ", isCorrect: true },
				],
			},
			
			{
				id: 38,
				 text: "Trouve le mot maquant dans ce verset : « Dieu dit : Prends donc ton fils, ton unique, celui que tu aimes, Isaac ; va-t'en dans le pays de ………….. et là, offre-le en holocauste sur l'une des montagnes que je t'indiquerai. »",
      options: [
        { id: 0, text: "Moab ", isCorrect: false },
        { id: 1, text: "Sinaï", isCorrect: false },
        { id: 2, text: "Moriya", isCorrect: true },
				],
			},
			
			{
				id: 39,
				text: "Quelle est l’information fausse concernant le mont Nébo",
      options: [
        { id: 0, text: "Il était situé au pays de Moab, vis-à-vis de Jéricho", isCorrect: false },
        { id: 1, text: "C’est là que Moïse Contempla le pays de Canaan et mourut", isCorrect: false },
        { id: 2, text: "C’est sur cette montage que sur ordre de Moïse, , Siméon, Lévi, Juda, Issacar, Joseph et Benjamin devaient se tenir pour bénir le peuple", isCorrect: true },
				],
			},
			
			{
				id: 40,
				text: "Chittim, Quelle information est fausse concernant ce lieu ?",
      options: [
        { id: 0, text: "Il se situait dans les steppes de Moab", isCorrect: false },
        { id: 1, text: "C’est là que le peuple se mit à se livrer à la débauche avec les filles de Moab", isCorrect: false },
        { id: 2, text: "C’est là que mourut Myriam et qu’elle fut enterrée.", isCorrect: true },
				],
			},
		]
	},
	{
		gameId: 13,
		jeuPosition: " stage 3 / session 3",
		sectQuest:" stag3ses3",
		jeuTitle:
			"La Période des commencements, des patriarches et de l’Exode (Les Livres de Moïse)",
		paraf1:
			"Si vous ouvrez votre Bible, à la première page vous lirez : « Au commencement, Dieu créa le ciel et la terre. ». Le premier verset de la Bible mentionne deux entités distinctes : Le Créateur et la création. ",
		paraf2:
			"Les Livres de Moïse partent de la Genèse à Deutéronome et relatent l’histoire de la création - dont l’homme est l’entité principale- la chute de l’homme, la multiplication de l’espèce humaine, l’histoire du déluge, la naissance des nations et des races, l’origine du peuple de Dieu, son séjour en Egypte, sa sortie et sa marche dans le désert pendant 40 ans, tout juste avant la conquête de Canaan. ",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",

		stagImgSrc: stage3image,
		sectionTitle: "LivresdeMoïse / Les Instittions et Rites",
		sectionImgSrc: institutions ,
		questions: [
			{
				id: 1,
				 text: "Quand l’Eternel prescrivit la Circoncision à Abraham et ses descendants, ses esclaves étaient exclus, sauf ceux qui étaient nés dans sa maison  ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			{
				id: 2,
				text: "Le premier Autel dédié à l’Eternel fut érigé (construit) par Abraham. ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
   
                        {
				id: 3,
				 text: "Quand l’Eternel fit alliance avec Abraham, Sara sa femme était âgée de 65 ans et lui de 75 ans.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 4,
				text: "Voici ce que l’Eternel dit à Moïse quand il institua la Paque : « Voici comment vous le mangerez : une ceinture à vos reins, vos sandales aux pieds et votre bâton à la main ; et prenez votre temps. C'est la Pâque de l'Éternel. » ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },
				],
			},
			
			{
				id: 5,
				 text: "Le tabernacle était fait de dix tentures de fin lin retors et (d'étoffes) violettes… ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 6,
				 text: "L’investiture d’Aaron comme prêtre s’est faite sept jours avant celle de ses fils ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true },  
				],
			},
			
			{
				id: 7,
				text: "Les deux holocaustes quotidiens qu’Israël devait offrir à l’Eternel était deux agneaux âgés d’un an, qui devaient être sacrifiés tous les deux chaque matin. .",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 8,
				text: "« L‘Autel des parfums était l’autel sur lequel Aaron devait brûler du parfum aromatique ; il devait en faire brûler chaque matin, lorsqu'il préparerait les lampes et aussi entre les deux soirs, lorsqu'il arrangerait les lampes.",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 9,
				 text: "L’Offrande pour racheter la vie des israélites, après le dénombrement, devait être payé par tous ceux qui étaient âgés d’au moins 25 ans ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 10,
				text: "Les douze tribus d’Israël étaient issues des douze fils de Jacob et chacun d’eux a donné son nom à une tribu.",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 11,
				 text: "« Voici ce que l’Eternel dit à Moïse quand il institua la fête des pains sans levain : « Vous observerez (la fête) des pains sans levain, car c'est en ce jour précis que j'aurai fait sortir vos troupes du pays d'Égypte ; vous observerez ce jour comme une prescription perpétuelle pour toutes vos générations.» ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 12,
				text: "Que pensez -vous de ce texte ? « Toute la communauté des Israélites partit du désert de Sin selon l'ordre de l'Éternel pour leur départ ; ils campèrent à Rephidim ; il n'y avait point d'eau à boire pour le peuple. » ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false },  
				],
			},
			
			{
				id: 13,
				 text: "Que pensez -vous de ce texte ? « Lorsque l'Éternel eut achevé de parler à Moïse sur le mont Sinaï, il lui donna les deux tables du Témoignage, tables de pierre écrites du doigt de Dieu. » ",
      options: [
        { id: 0, text: "Vrai", isCorrect:  true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 14,
				text: " Moïse bâtit un autel et l'appela du nom de : l'Éternel mon étendard. » Ou encore : Yahveh Nissi  ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 15,
				 text: "Il y avait entre la tente de la rencontre et l’autel, une cuve de bronze pour les ablutions, réservée à tout homme d’Israël qui voulait se purifier ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 16,
				text: "La nuit du dixième fléau sur l’Egypte, chaque famille israélite devait trouver forcément un agneau qu’il devait immoler comme agneau pascal ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
		
			{
				id: 17,
				 text: "Que pensez -vous de ce texte ? « Abram habita dans le pays de Canaan ; et Loth habita dans les villes de la plaine et dressa ses tentes en direction de Sichem. » ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 18,
				 text: "« Que pensez -vous de ce texte ?  Betsaleél fit l'arche en bois d'acacia, d'une longueur de deux coudées et demie, d'une largeur d'une coudée et demie et d'une hauteur d'une coudée et demie.  ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 19,
				 text: "L’éphod était le vêtement des lévites, ceux dont l’héritage est l’Eternel. ",
      options: [
        { id: 0, text: "Vrai", isCorrect: false },
        { id: 1, text: "Faux", isCorrect: true }, 
				],
			},
			
			{
				id: 20,
				text: "La Pentecôte, une des fêtes d’Israël et la première des fêtes relatives aux récoltes, était aussi appelée fête des semaines, parce que sa date était fixée à 7 semaines après l'offrande de la gerbe des prémices de l'orge, cela symbolisait de la consécration de la moisson. ",
      options: [
        { id: 0, text: "Vrai", isCorrect: true },
        { id: 1, text: "Faux", isCorrect: false }, 
				],
			},
			
			{
				id: 21,
				text: "Qui est le premier personnage biblique à être polygame",
      options: [
        { id: 0, text: "Lémek", isCorrect: true },
        { id: 1, text: "Nimrod", isCorrect: false },
        { id: 2, text: "Abraham", isCorrect: false },
				],
			},
			
			{
				id: 22,
				text: "Quelles sont les références de ce texte biblique : « Moïse prit sa femme et ses fils, les fit monter sur des ânes et retourna dans le pays d'Égypte. Moïse prit dans sa main le bâton de Dieu. »",
      options: [
        { id: 0, text: "Exode 2 :20", isCorrect: false },
        { id: 1, text: "Exode 4 :20 »", isCorrect: true },
        { id: 2, text: "Exode 6 :20", isCorrect: false },
				],
			},
			
			{
				id: 23,
				text: "A qui Dieu a-t-il dit « Tu diras au Pharaon : Ainsi parle l'Éternel : Israël est mon fils, mon premier-né.» ",
      options: [
        { id: 0, text: "Le Pharaon", isCorrect: false },
        { id: 1, text: "Moïse", isCorrect: true },
        { id: 2, text: "Abraham", isCorrect: false },
				],
			},
			
			{
				id: 24,
				text: "Quel était le signe de l’alliance avec Noé ?",
      options: [
        { id: 0, text: "Toutes les bêtes pures et de tous les oiseaux purs qu’il avait réservés et qu’il offrît en holocaustes sur l'autel", isCorrect: false },
        { id: 1, text: "Dieu place son Arc dans la nuée ", isCorrect: true },
        { id: 2, text: "Un agneau sans tâche ni ride", isCorrect: false },
				],
			},
			
			{
				id: 25,
				text: "Qu’est -ce que Séphora a dit à Moïse à cause de la circoncision ?",
      options: [
        { id: 0, text: "N‘oublie jamais la circoncision ", isCorrect: false },
        { id: 1, text: "Il n‘y a pas de salut sans effusion de sang", isCorrect: false },
        { id: 2, text: "Époux de sang !", isCorrect: true },
				],
			},
			
			{
				id: 26,
				text: "Le premier signe miraculeux que l’Eternel fît en Egypte devant le pharaon fut :",
      options: [
        { id: 0, text: "La main de Moïse qui se couvre de lèpre blanche comme de la neige ", isCorrect: false },
        { id: 1, text: "L’eau changée en sang", isCorrect: false },
        { id: 2, text: "Le bâton d’Aaron qui est changé en serpent", isCorrect: true },
				],
			},
			
			{
				id: 27,
				text: "Quel est la signification du nom Yahvé ?",
      options: [
        { id: 0, text: "Le Dieu Tout Puissant", isCorrect: false },
        { id: 1, text: "L’Eternel", isCorrect: true },
        { id: 2, text: "Le Dieu qui sauve", isCorrect: false },
				],
			},
			
			{
				id: 28,
				text: "Combien parmi les anciens d’Israël ont-ils été choisis pour assister Moïse et à qui l’Eternel a pris de l’Esprit qui était sur Moïse pour leur en donner ?",
      options: [
        { id: 0, text: "Douze ", isCorrect: false },
        { id: 1, text: "Quarante ", isCorrect: false },
        { id: 2, text: "Soixante - dix", isCorrect: true },
				],
			},
			
			{
				id: 29,
				 text: "Quel est l’animal dont les israélites, dans le pays de Canaan devaient racheter le premier né avec un agneau ?",
      options: [
        { id: 0, text: "Le bœuf ", isCorrect: false },
        { id: 1, text: "Le mouton", isCorrect: false },
        { id: 2, text: "L’âne", isCorrect: true },
				],
			},
			
			{
				id: 30,
				text: "Quel est la référence de ce texte « Quand l'Éternel traversera l'Égypte pour frapper et qu'il verra le sang sur le linteau et sur les deux poteaux, l'Éternel passera par-dessus la porte et ne laissera pas le destructeur entrer dans vos maisons pour (vous) frapper.»",
      options: [
        { id: 0, text: "Exode 4 : 23", isCorrect: false },
        { id: 1, text: "Exode 8 : 23", isCorrect: false },
        { id: 2, text: "Exode 12 : 23", isCorrect: true },
				],
			},
			
			{
				id: 31,
				text: "Qu’est-ce qui n‘est pas vrai, ici, dans la réglementation de la fête des pains sans levain ?",
      options: [
        { id: 0, text: "La fête commence le quinzième jour du septième mois ", isCorrect: true },
        { id: 1, text: "Les israélites devaient manger pendant sept jours des pains sans levain", isCorrect: false },
        { id: 2, text: "Les israélites devaient offrir à l'Éternel, pendant sept jours, des (sacrifices) consumés par le feu", isCorrect: false },
				],
			},
			
			{
				id: 32,
				text: "Dans quel chapitre de la Genèse, l’Eternel ordonna la circoncision comme signe de l’alliance avec Abraham et ses descendants ?",
      options: [
        { id: 0, text: "Genèse 17 ", isCorrect: true },
        { id: 1, text: "Genèse 18 ", isCorrect: false },
        { id: 2, text: "Genèse 19", isCorrect: false },
				],
			},
			
			{
				id: 33,
				text: "Pendant combien de jours se faisait la fête des pains sans levain",
      options: [
        { id: 0, text: "Trois jours", isCorrect: false },
        { id: 1, text: "Sept jours", isCorrect: true },
        { id: 2, text: "Quatorze jours", isCorrect: false },
				],
			},
			
			{
				id: 34,
				text: "Lors de l’investiture d’Aaron et de ses fils, combien de fois Moïse a -t-il fait l’aspersion sur l’autel et ensuite l’oignit avec tous ces accessoires. ",
      options: [
        { id: 0, text: "Deux fois", isCorrect: false },
        { id: 1, text: "Trois fois", isCorrect: false },
        { id: 2, text: "Sept fois", isCorrect: true },
				],
			},
			
			{
				id: 35,
				text: "Combien de jours a-t-il fallu pour installer Aaron et ses fils dans leur charge?",
      options: [
        { id: 0, text: "Trois jours", isCorrect: false },
        { id: 1, text: "Sept jours", isCorrect: true },
        { id: 2, text: "Quatorze jours", isCorrect: false },
				],
			},
			
			{
				id: 36,
				text: "Qu’est- ce qui ‘n’est pas vrai concernant l’ Ourim et Toummim ?",
      options: [
        { id: 0, text: "Ces deux objets se trouvaient dans le Pectoral", isCorrect: false },
        { id: 1, text: "Ces deux objets servaient à déterminer la volonté de Dieu (jugements, décisions), dans les litiges difficiles à résoudre ou les situations critiques", isCorrect: false },
        { id: 2, text: "Ces deux objets étaient portés par Aaron et les Lévites", isCorrect: true },
				],
			},
			
			{
				id: 37,
				text: "Qu’est- ce qui ‘n’est pas vrai concernant le chandelier ?",
      options: [
        { id: 0, text: "Ce chandelier était d'or battu. Son pied et sa tige, ses calices, ses corolles et ses fleurs étaient d'une seule pièce.", isCorrect: false },
        { id: 1, text: "Ce chandelier avait sept branches", isCorrect: false },
        { id: 2, text: "Ce chandelier, dans le Tabernacle, était placé à côté de l’arche de l’Alliance, dans le Saint des Saints", isCorrect: true },
				],
			},
			
			{
				id: 38,
				 text: "Quelle est la référence de ce passage où l’Eternel fait d’Aaron le « prophète » de Moïse : « Il parlera pour toi au peuple ; il te servira de bouche, et tu tiendras pour lui la place de Dieu. »",
      options: [
        { id: 0, text: "Exode 4 : 15", isCorrect: true },
        { id: 1, text: "Lévitique 18 : 14", isCorrect: false },
        { id: 2, text: "Deutéronome 18 : 14", isCorrect: false },
				],
			},
			
			{
				id: 39,
				text: "Qu’est ce qui n’était pas vrai concernant le Pectoral ?",
      options: [
        { id: 0, text: "C’était une œuvre d'art, d'or, de (fil) violet, pourpre et cramoisi, et de fin lin retors.", isCorrect: false },
        { id: 1, text: "Il était aussi appelé Pectoral du verdict ou de décision", isCorrect: false },
        { id: 2, text: "C’était une sorte de poche contenant un objet servant à déterminer la volonté de Dieu", isCorrect: true },
				],
			},
			
			{
				id: 40,
				text: "Remplacer le mot manquant dans ce texte biblique : « Quant au ....... que tu feras, vous n'en ferez pas dans les mêmes proportions pour votre usage ; il sera pour toi consacré à l'Éternel. Quiconque en fera de semblable...., sera retranché de son peuple. »",
      options: [
        { id: 0, text: "Pain", isCorrect: false },
        { id: 1, text: "Vin", isCorrect: false },
        { id: 2, text: "Parfum", isCorrect: true },
				],
			},

		]
	},
	{
		gameId: 14,
		jeuPosition: " stage 3 / session 4",
		sectQuest:" stag3ses4",
		jeuTitle:
			"La Période des commencements, des patriarches et de l’Exode (Les Livres de Moïse)",
		paraf1:
			"Si vous ouvrez votre Bible, à la première page vous lirez : « Au commencement, Dieu créa le ciel et la terre. ». Le premier verset de la Bible mentionne deux entités distinctes : Le Créateur et la création. ",
		paraf2:
			"Les Livres de Moïse partent de la Genèse à Deutéronome et relatent l’histoire de la création - dont l’homme est l’entité principale- la chute de l’homme, la multiplication de l’espèce humaine, l’histoire du déluge, la naissance des nations et des races, l’origine du peuple de Dieu, son séjour en Egypte, sa sortie et sa marche dans le désert pendant 40 ans, tout juste avant la conquête de Canaan. ",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",

		stagImgSrc: stage3image,
		sectionTitle: "LivresdeMoïse / Les Lois et Instructions",
		sectionImgSrc: lois,
		questions: [
			{
				id: 1,
				 text: " Où trouve-t-on ce texte biblique: « L'Éternel dit à Moïse et à Aaron : Voici la prescription au sujet de la Pâque : Aucun étranger n'en mangera. » ? ",
      options: [
        { id: 0, text: "Exode 10 : 43 ", isCorrect: false },
        { id: 1, text: "Exode 12 : 43 ", isCorrect: true },
        { id: 2, text: "Exode 14 : 43", isCorrect: false }, 
				],
			},
			{
				id: 2,
				text: "Où trouve-t-on ce texte biblique: « Maintenant, Israël, que demande de toi l'Éternel, ton Dieu, si ce n'est que tu craignes l'Éternel, ton Dieu, afin de marcher dans toutes ses voies, d'aimer et de servir l'Éternel, ton Dieu, de tout ton cœur et de toute ton âme ; » ",
      options: [
        { id: 0, text: "Deutéronome 10 : 12", isCorrect: true },
        { id: 1, text: "Nombre 9 : 23", isCorrect: false },
        { id: 2, text: "Nombre 11 : 25", isCorrect: true },
				],
			},
   
                        {
				id: 3,
				 text: "Où trouve-t-on ce texte biblique: «Le résident temporaire et le mercenaire n'en mangeront pas (La Pâque). » ? ",
      options: [
        { id: 0, text: "Exode 12 : 45", isCorrect: true  },
        { id: 1, text: "Exode 25 : 40", isCorrect: false },
        { id: 2, text: "Exode 29 : 45", isCorrect: false },
				],
			},
			
			{
				id: 4,
				text: "Qui a dit cette phrase :« Béni soit l'Éternel qui vous a délivrés de la main des Égyptiens et de la main du Pharaon, qui a délivré le peuple du pouvoir des Égyptiens ! » ?",
      options: [
        { id: 0, text: "Melchisédech ", isCorrect: false },
        { id: 1, text: "Jéthro", isCorrect: true },
        { id: 2, text: "Moïse", isCorrect: false },
				],
			},
			
			{
				id: 5,
				 text: "Où trouve-t-on ce texte biblique: « Prenez sur ce qui vous appartient un prélèvement pour l'Éternel. Tout homme au cœur généreux apportera cette offrande prélevée pour l'Éternel : de l'or, de l'argent et du bronze » ?",
      options: [
        { id: 0, text: "Genèse 35 : 5", isCorrect: false },
        { id: 1, text: "Exode 35 : 5 ", isCorrect: true },
        { id: 2, text: "Nombre 35 : 5", isCorrect: false },
				],
			},
			
			{
				id: 6,
				 text: "Parmi ces fils d’Aaron, lequel n‘a pas été consumé par le feu qui tua deux fils d’Aaron parce que ces derniers apportèrent devant l'Éternel du feu étranger ?",
      options: [
        { id: 0, text: "Abihou", isCorrect: false },
        { id: 1, text: "Itamar", isCorrect: true },
        { id: 2, text: "Nadab ", isCorrect: false },
				],
			},
			
			{
				id: 7,
				text: "Où trouve-t-on ce texte biblique: « Moïse et Aaron entrèrent dans la tente de la Rencontre. Lorsqu'ils en sortirent, ils bénirent le peuple. Et la gloire de l'Éternel apparut à tout le peuple. »",
      options: [
        { id: 0, text: "Exode 9 : 23", isCorrect: false },
        { id: 1, text: "Lévitique 9 : 23", isCorrect: true },
        { id: 2, text: "Deutéronome 9 : 23", isCorrect: false },
				],
			},
			
			{
				id: 8,
				text: "D’après la Loi Lévitique, lorsqu'une femme enfantera un garçon, combien de temps au total, sera -elle impure, ne touchera rien de consacré et n'ira pas au sanctuaire ?",
      options: [
        { id: 0, text: "Pendant (près de) sept jours", isCorrect: false },
        { id: 1, text: "Pendant (près de) trente-trois jours", isCorrect: false },
        { id: 2, text: "Pendant (près de) quarante jours", isCorrect: true },
				],
			},
			
			{
				id: 9,
				 text: "Où trouve-t-on ce texte biblique: « L'Éternel dit à Moïse : Écris ces choses comme souvenir dans le livre, et déclare à Josué que j'effacerai le souvenir d'Amalec de dessous les cieux. 15 Moïse bâtit un autel et l'appela du nom de : l'Éternel mon étendard. »",
      options: [
        { id: 0, text: "Exode 17 : 14 – 15 ", isCorrect: true },
        { id: 1, text: "Lévitique 17 : 14 – 15", isCorrect: false },
        { id: 2, text: "Deutéronome 17 : 14 - 15", isCorrect: false },
				],
			},
			
			{
				id: 10,
				text: "Où trouve-t-on ce texte biblique: « Tu mettras du sel sur toutes tes offrandes en oblation, tu ne laisseras pas ton offrande manquer de sel de l'alliance de ton Dieu ; sur toutes tes oblations tu présenteras du sel. » ?",
      options: [
        { id: 0, text: "Lévitique 2 : 13", isCorrect: true },
        { id: 1, text: "Lévitique 12 : 13", isCorrect: false },
        { id: 2, text: "Lévitique 13 : 2", isCorrect: false },
				],
			},
			
			{
				id: 11,
				 text: "D’après la Loi Lévitique, lorsqu'une femme enfantera une fille, combien de temps au total, sera -elle rituellement impure ?",
      options: [
        { id: 0, text: "Pendant deux semaines", isCorrect: false },
        { id: 1, text: "Pendant (près de) sept jours", isCorrect: false },
        { id: 2, text: "Pendant quatre vingt jours", isCorrect: true }, 
				],
			},
			
			{
				id: 12,
				text: "Où trouve-t-on ce texte biblique: « Moïse dit : Voici ce que l'Éternel a ordonné : faites (-le) ; et la gloire de l'Éternel va vous apparaître. » ",
      options: [
        { id: 0, text: "Genèse 9 :6", isCorrect: false },
        { id: 1, text: "Exode 9 : 6", isCorrect: false },
        { id: 2, text: "Lévitique 9 : 6", isCorrect: true },  
				],
			},
			
			{
				id: 13,
				 text: "D’après la Loi Lévitique, Si c'est le sacrificateur ayant reçu l'onction qui a péché et qui a rendu par là le peuple coupable, qu’offrira -t-il à l'Éternel (en sacrifice) pour le péché qu'il a commis ? ",
      options: [
        { id: 0, text: "Un agneau sans défaut", isCorrect: false },
        { id: 1, text: "Un bouc sans défaut", isCorrect: false },
        { id: 2, text: "Un jeune taureau sans défaut", isCorrect: true },
				],
			},
			
			{
				id: 14,
				text: "D’après la Loi Lévitique,  Si c'est quelqu'un du peuple qui a péché involontairement en commettant contre l'un des commandements de l'Éternel des actes illicites et en se rendant ainsi coupable, s'il s'aperçoit du péché qu'il a commis, il présentera en oblation : ",
      options: [
        { id: 0, text: "Un jeune taureau sans défaut", isCorrect: false },
        { id: 1, text: "Une chèvre, une femelle sans défaut ", isCorrect: true },
        { id: 2, text: "Un agneau sans défaut", isCorrect: false },
				],
			},
			
			{
				id: 15,
				 text: "« Dieu bénit Noé, ainsi que ses fils, et leur dit : Soyez féconds, multipliez-vous et remplissez la terre.2 Vous serez un sujet de crainte et de terreur pour tout animal de la terre, pour tout oiseau du ciel, pour tout ce qui rampe sur le sol et pour tous les poissons de la mer : ils sont livrés entre vos mains. »",
      options: [
        { id: 0, text: "Genèse 6 : 1- 2", isCorrect: false },
        { id: 1, text: "Genèse 7 : 1 - 2", isCorrect: false },
        { id: 2, text: "Genèse 9: 1 - 2", isCorrect: true },
				],
			},
			
			{
				id: 16,
				text: "D’après la Loi Lévitique, Lorsqu'un prince péchera en commettant involontairement contre l'un des commandements de l'Éternel, son Dieu, des actes illicites et en se rendant ainsi coupable, s'il s'aperçoit du péché qu'il a commis ; il présentera en oblation :",
      options: [
        { id: 0, text: "Un bouc mâle sans défaut.", isCorrect: true },
        { id: 1, text: "Un jeune taureau sans défaut", isCorrect: false },
        { id: 2, text: "Un agneau sans défaut", isCorrect: false }, 
				],
			},
			
			{
				id: 17,
				 text: "Où trouve-t-on ce texte biblique: «Tu établiras Aaron et ses fils pour qu'ils observent les fonctions de leur sacerdoce ; et l'étranger qui approchera sera puni de mort.",
      options: [
        { id: 0, text: "Exode 3 : 10", isCorrect: false },
        { id: 1, text: "Lévitique 3 : 10", isCorrect: false },
        { id: 2, text: "Nombre 3 : 10", isCorrect: true }, 
				],
			},
			
			{
				id: 18,
				 text: "D’après la Loi Lévitique, Lorsque quelqu'un commettra une infidélité et péchera involontairement en retenant des choses consacrées à l'Éternel, il présentera à cause de sa culpabilité envers l'Éternel :",
      options: [
        { id: 0, text: "Un bélier sans défaut", isCorrect: true },
        { id: 1, text: "Un bouc mâle sans défaut.", isCorrect: false },
        { id: 2, text: "Un jeune taureau sans défaut ", isCorrect: false },
				],
			},
			
			{
				id: 19,
				 text: "Qu’est -ce qui est faux, ici, concernant les Lévites ? ",
      options: [
        { id: 0, text: "Fais approcher la tribu de Lévi, et tu la placeras sous la surveillance du sacrificateur Aaron, pour qu'elle soit à son service et serve aussi dans le sanctuaire ", isCorrect: true  },
        { id: 1, text: "Ils auront le soin de ce qui est remis à sa garde et à la garde de toute la communauté devant la tente de la Rencontre : ils s'emploieront au service du tabernacle.", isCorrect: false },
        { id: 2, text: "Ils auront la garde de tous les ustensiles de la tente de la Rencontre et de ce qui est remis à la garde des Israélites : ils s'emploieront au service du tabernacle.", isCorrect: false },
				],
			},
			
			{
				id: 20,
				text: "D’après la Loi Lévitique, Lorsque quelqu'un, en parlant à la légère et sans y prendre garde de quelque manière que ce soit, jure de faire du mal ou du bien, et qu'il s'en aperçoive (plus tard), il sera coupable sur un de ces points ;puis il présentera comme sacrifice pour le péché :",
      options: [
        { id: 0, text: "Un agneau sans défaut", isCorrect: false },
        { id: 1, text: "Un bouc mâle sans défaut.", isCorrect: false },
        { id: 2, text: "Une brebis ou une chèvre ", isCorrect: true },
				],
			},
			
			{
				id: 21,
				text: "Où trouve-t-on ce texte biblique: « Tu te souviendras de l'Éternel, ton Dieu, car c'est lui qui te donne de la force pour acquérir ces richesses, afin de confirmer, comme il le fait aujourd'hui, son alliance qu'il a jurée à tes pères. » ",
      options: [
        { id: 0, text: "Lévitique 8 : 18", isCorrect: false },
        { id: 1, text: "Nombre 8 : 18", isCorrect: false },
        { id: 2, text: "Deutéronome 8 : 18", isCorrect: true },
				],
			},
			
			{
				id: 22,
				text: "Où trouve-t-on ce texte biblique: « Dieu dit : Prends donc ton fils, ton unique, celui que tu aimes, Isaac ; va-t'en dans le pays de Moriya et là, offre-le en holocauste sur l'une des montagnes que je t'indiquerai. » ",
      options: [
        { id: 0, text: "Genèse 18 : 2", isCorrect: false },
        { id: 1, text: "Genèse 21 : 2", isCorrect: false },
        { id: 2, text: "Genèse 22 : 2", isCorrect: true },
				],
			},
			
			{
				id: 23,
				text: "Où trouve-t-on ce texte biblique: «Tu ne te vengeras pas, et tu ne garderas pas de rancune envers les fils de ton peuple. Tu aimeras ton prochain comme toi-même. Je suis l'Éternel. »",
      options: [
        { id: 0, text: "Lévitique 16 : 18", isCorrect: false },
        { id: 1, text: "Lévitique 18 : 18", isCorrect: false },
        { id: 2, text: "Lévitique 19 : 18", isCorrect: true },
				],
			},
			
			{
				id: 24,
				text: "Où trouve-t-on ce texte biblique: « Voici comment vous garderez l'alliance que je traite avec vous et avec ta descendance après toi : tout mâle parmi vous sera circoncis. » ",
      options: [
        { id: 0, text: "Genèse 17 : 10 ", isCorrect: true },
        { id: 1, text: "Lévitique 12 : 3", isCorrect: false },
        { id: 2, text: "Deutéronome 10 : 16", isCorrect: false },
				],
			},
			
			{
				id: 25,
				text: "Où trouve-t-on ce texte biblique: « Tu circonciras tout esclave acquis à prix d'argent ; alors il en mangera. » ",
      options: [
        { id: 0, text: "Exode 4 : 26 ", isCorrect: false },
        { id: 1, text: "Exode 12 : 26 ", isCorrect: false },
        { id: 2, text: "Exode 12 : 44", isCorrect: true },
				],
			},
			
			{
				id: 26,
				text: "A qui l’Eternel a – t- il dit :  « Pourquoi es-tu irrité, et pourquoi ton visage est-il abattu ? 7 Si tu agis bien tu relèveras la tête, mais si tu n'agis pas bien, le péché est tapi à ta porte, et ses désirs (se portent) vers toi : mais toi, domine sur lui. » ",
      options: [
        { id: 0, text: "Caïn", isCorrect: true },
        { id: 1, text: "Jacob", isCorrect: false },
        { id: 2, text: "Esaü", isCorrect: false },				],
			},
			
			{
				id: 27,
				text: "Où trouve-t-on ce texte biblique: “L'Éternel descendit dans la nuée et lui parla ; il prit de l'esprit qui était sur lui et le mit sur les soixante-dix anciens. Et dès que l'Esprit reposa sur eux, ils se mirent à prophétiser, mais ils ne continuèrent pas.” ? ",
      options: [
        { id: 0, text: "Nombre 9 : 23", isCorrect: false },
        { id: 1, text: "Nombre 11 : 25", isCorrect: true },
        { id: 2, text: "Nombre 25 : 11", isCorrect: false },
				],
			},
			
			{
				id: 28,
				text: "Où trouve-t-on ce texte biblique: « L'Éternel Dieu donna ce commandement à l'homme : Tu pourras manger de tous les arbres du jardin ;17 mais tu ne mangeras pas de l'arbre de la connaissance du bien et du mal, car le jour où tu en mangeras, tu mourras. » ",
      options: [
        { id: 0, text: "Genèse 1 : 16 - 17", isCorrect: false },
        { id: 1, text: "Genèse 2 : 16 - 17", isCorrect: true },
        { id: 2, text: "Genèse 3 : 16 - 17", isCorrect: false },
				],
			},
			
			{
				id: 29,
				 text: "Où trouve-t-on ce texte biblique : « Tu te lèveras devant les cheveux blancs et tu honoreras la personne du vieillard. Tu craindras ton Dieu. Je suis l'Éternel. »",
      options: [
        { id: 0, text: "Genèse 19 : 32", isCorrect: false },
        { id: 1, text: "Lévitique 19 : 32", isCorrect: true },
        { id: 2, text: "Lévitique 32 : 19", isCorrect: false },
				],
			},
		
			{
				id: 30,
				text: "Où trouve-t-on ce texte biblique: « Vous ne vous rallierez pas à d'autres dieux, d'entre les dieux des peuples qui sont autour de vous ; car l'Éternel, ton Dieu, est un Dieu jaloux au milieu de toi. La colère de l'Éternel, ton Dieu, s'enflammerait contre toi, et il t'exterminerait de la surface du sol. »",
      options: [
        { id: 0, text: "Deutéronome 6 : 14 – 15", isCorrect: true },
        { id: 1, text: "Deutéronome 8 : 14 – 15", isCorrect: false },
        { id: 2, text: "Deutéronome 8 : 18 - 19", isCorrect: false },
				],
			},
		
			{
				id: 31,
				text: "Où trouve-t-on ce texte biblique: « Si ton frère devient pauvre et vend une portion de sa propriété, celui qui a le devoir de rachat, son plus proche parent, viendra et rachètera ce qu'a vendu son frère. »",
      options: [
        { id: 0, text: "Genèse 25 ; 25", isCorrect: false },
        { id: 1, text: "Exode 25 : 25", isCorrect: false },
        { id: 2, text: "Lévitique 25 : 25", isCorrect: true },
				],
			},
			
			{
				id: 32,
				text: "Où trouve-t-on ce texte biblique:« (Dieu) dit : N'approche pas d'ici, ôte tes sandales de tes pieds, car l'endroit sur lequel tu te tiens est une terre sainte. »",
      options: [
        { id: 0, text: "Exode 3 : 5", isCorrect: true },
        { id: 1, text: "Exode  9 : 5", isCorrect: false },
        { id: 2, text: "Exode 12 : 5", isCorrect: false },
				],
			},
			
			{
				id: 33,
				text: "A quelle occasion cette phrase a été dite ?  « C'est ce que l'Éternel a déclaré, lorsqu'il a dit : Je serai sanctifié par ceux qui s'approchent de moi, et je serai glorifié en présence de tout le peuple. Aaron garda le silence. »",
      options: [
        { id: 0, text: "Quand Aaron, en l’absence de Moïse fabriqua un veau d’or, comme divinité pour le peuple", isCorrect: false },
        { id: 1, text: "Quand Israël s'établit à Shittim et le peuple commença à se livrer à la débauche avec les filles de Moab.", isCorrect: false },
        { id: 2, text: "Après que le feu sortit de devant l'Éternel et consuma ada et Abihou et qu’ils moururent devant l'Éternel.", isCorrect: true },
				],
			},
			
			{
				id: 34,
				text: "Où trouve-t-on ce texte biblique: «Tu ne découvriras pas la nudité d'une femme et de sa fille. Tu ne prendras pas la fille de son fils, ni la fille de sa fille, pour découvrir leur nudité. Ce sont des proches parentes : c'est une infamie. »",
      options: [
        { id: 0, text: "Lévitique 2 : 13", isCorrect: false },
        { id: 1, text: "Lévitique 5 : 15", isCorrect: false },
        { id: 2, text: "Lévitique 18 : 17", isCorrect: true },
				],
			},
			
			{
				id: 35,
				text: "D’après la Loi Lévitique, à quel âge l’enfant devrait -il être circoncis ?",
      options: [
        { id: 0, text: "Sept jours", isCorrect: false },
        { id: 1, text: "Huit jours", isCorrect: true },
        { id: 2, text: "Quatorze jours", isCorrect: false },
				],
			},
			
			{
				id: 36,
				text: "Où trouve-t-on ce texte biblique : “Ils campaient sur l'ordre de l'Éternel et ils partaient sur l'ordre de l'Éternel ; ils observaient le commandement de l'Éternel, selon l'ordre que l'Éternel donnait à Moïse.” ?",
      options: [
        { id: 0, text: "Exode 9 : 23", isCorrect: false },
        { id: 1, text: "Exode 23 : 9", isCorrect: false },
        { id: 2, text: "Nombre 9 : 23", isCorrect: true },
				],
			},
			
			{
				id: 37,
				text: "Où trouve-t-on ce texte biblique : « Fais-toi une arche de bois de gopher ; tu disposeras cette arche en cellules, et tu la couvriras d'un enduit, en dedans et en dehors. » ",
      options: [
        { id: 0, text: "Genèse 4 : 14", isCorrect: false },
        { id: 1, text: "Genèse 6 : 14", isCorrect: true },
        { id: 2, text: "Genèse 8 : 14", isCorrect: false },
				],
			},
			
			{
				id: 38,
				 text: "Où trouve-t-on ce texte biblique : « Lorsque tu mangeras et te rassasieras, lorsque tu bâtiras et habiteras de belles maisons, 13 lorsque ........l'or se multiplieront pour toi et que tout ce qui est à toi se multipliera, 14 (prends garde), de peur que ton cœur ne s'élève et que tu n'oublies l'Éternel, ton Dieu, .... »",
      options: [
        { id: 0, text: "Exode 8 : 12 – 14", isCorrect: false },
        { id: 1, text: "Lévitique 8 : 12 - 14", isCorrect: false },
        { id: 2, text: "Deutéronome 8 : 12 - 14", isCorrect: true },
				],
			},
			
			{
				id: 39,
				text: "Où trouve-t-on ce texte biblique : « Vous circoncirez donc votre cœur et vous ne raidirez plus votre nuque. »",
      options: [
        { id: 0, text: "Lévitique 10: 16", isCorrect: false },
        { id: 1, text: "Deutéronome 10 : 16", isCorrect: true },
        { id: 2, text: "Deutéronome 16 : 10", isCorrect: false },
				],
			},
			
			{
				id: 40,
				text: "Où trouve-t-on ce texte biblique : « L'Éternel dit à Abram : Va-t'en de ton pays, de ta patrie et de la maison de ton père, vers le pays que je te montrerai. »",
      options: [
        { id: 0, text: "Genèse 11 : 31", isCorrect: false },
        { id: 1, text: "Genèse 12 : 1", isCorrect: true },
        { id: 2, text: "Genèse 22 : 2", isCorrect: false },
				],
			},
		]
	},
	{
		gameId: 15,
		jeuPosition: " stage 3 / session 5",
		sectQuest:" stag3ses5",
		jeuTitle:
			"La Période des commencements, des patriarches et de l’Exode (Les Livres de Moïse)",
		paraf1:
			"Si vous ouvrez votre Bible, à la première page vous lirez : « Au commencement, Dieu créa le ciel et la terre. ». Le premier verset de la Bible mentionne deux entités distinctes : Le Créateur et la création. ",
		paraf2:
			"Les Livres de Moïse partent de la Genèse à Deutéronome et relatent l’histoire de la création - dont l’homme est l’entité principale- la chute de l’homme, la multiplication de l’espèce humaine, l’histoire du déluge, la naissance des nations et des races, l’origine du peuple de Dieu, son séjour en Egypte, sa sortie et sa marche dans le désert pendant 40 ans, tout juste avant la conquête de Canaan. ",
		paraf3: "Bon stage à toi et retrouvons – nous au stage prochain",

		stagImgSrc: stage3image,
		sectionTitle: "LivresdeMoïse/ Les Evènements marquants",
		sectionImgSrc: evenements,
		questions: [
			{
				id: 1,
				 text: "L’histoire de la séparation d’Abraham d’avec Lot se trouve en :",
      options: [
        { id: 0, text: "Genèse 13 ", isCorrect: true },
        { id: 1, text: "Genèse 14 ", isCorrect: false },
        { id: 2, text: "Genèse 15 ", isCorrect: false },
				],
			},
			{
				id: 2,
				text: "L’histoire de la sortie d’Israël d’Egypte se trouve en :",
      options: [
        { id: 0, text: "Exode 10  ", isCorrect: false },
        { id: 1, text: "Exode 15", isCorrect: false },
        { id: 2, text: "Exode 12", isCorrect: true },
				],
			},
   
                        {
				id: 3,
				 text: "L’histoire des cailles se trouve en :",
      options: [
        { id: 0, text: "Exode 14", isCorrect: false },
        { id: 1, text: "Exode 15", isCorrect: false },
        { id: 2, text: "Exode 16", isCorrect: true },
				],
			},
			
			{
				id: 4,
				text: "L’histoire de la naissance d’Ismaël est racontée en :",
      options: [
        { id: 0, text: "Genèse 14 ", isCorrect: false },
        { id: 1, text: "Genèse 16 ", isCorrect: true },
        { id: 2, text: "Genèse 19", isCorrect: false },
				],
			},
			
			{
				id: 5,
				 text: "L’histoire du veau d’or est relatée en :",
      options: [
        { id: 0, text: "Exode 25  ", isCorrect: false },
        { id: 1, text: "Exode 27   ", isCorrect: false },
        { id: 2, text: "Exode 32", isCorrect: true },
				],
			},
			
			{
				id: 6,
				 text: "L’histoire de la débauche des fils d’Israël qui conduisit à la mort de 24000 personnes est racontée en :",
      options: [
        { id: 0, text: "Nombre 25 ", isCorrect: true },
        { id: 1, text: "Nombre 30 ", isCorrect: false },
        { id: 2, text: "Nombre 31", isCorrect: false },
				],
			},
			
			{
				id: 7,
				text: "L’histoire de l’enfant Moïse sauvé des eaux par la fille du pharaon se trouve en :",
      options: [
        { id: 0, text: "Exode 2 : 1 – 10  ", isCorrect: true },
        { id: 1, text: "Exode 2 : 15 – 22 ", isCorrect: false },
        { id: 2, text: "Exode 3 : 1 - 10", isCorrect: false },
				],
			},
			
			{
				id: 8,
				text: "L'histoire de la chute d’Adam est racontée en : ",
      options: [
        { id: 0, text: "Genèse 3", isCorrect: true },
        { id: 1, text: "Genèse 4", isCorrect: false },
        { id: 2, text: "Genèse 7", isCorrect: false },
				],
			},
			
			{
				id: 9,
				 text: "Quelles sont les références de ce passage biblique? « Dieu parla encore à Moïse et lui dit : Moi, (je suis) l'Éternel.Je suis apparu à Abraham, à Isaac et à Jacob, comme le Dieu Tout-Puissant ; mais je n'ai pas été reconnu par eux sous mon nom : l'Éternel. »",
      options: [
        { id: 0, text: "Exode 3 : 2 - 3", isCorrect: false },
        { id: 1, text: "Exode 5 : 2 – 3", isCorrect: false },
        { id: 2, text: "Exode 6 : 2 - 3", isCorrect: true },
				],
			},
			
			{
				id: 10,
				text: "L’histoire de la naissance d’Isaac se trouve en :",
      options: [
        { id: 0, text: "Genèse 21 ", isCorrect: true },
        { id: 1, text: "Genèse 26 ", isCorrect: false },
        { id: 2, text: "Genèse 27", isCorrect: false },
				],
			},
			
			{
				id: 11,
				 text: "Le cantique de l’Alliance dont Moïse prononça jusqu'au bout les paroles pour les faire entendre à toute l'assemblée d'Israël, se trouve en :",
      options: [
        { id: 0, text: "Exode 32", isCorrect: false },
        { id: 1, text: "Nombre 32", isCorrect: false },
        { id: 2, text: "Deutéronome 32", isCorrect: true }, 
				],
			},
			
			{
				id: 12,
				text: "L’histoire de la destruction de Sodome et Gomorrhe se trouve en : ",
      options: [
        { id: 0, text: "Genèse 19", isCorrect: true },
        { id: 1, text: "Genèse 20", isCorrect: false },
        { id: 2, text: "Genèse 28", isCorrect: false },
				],
			},
			
			{
				id: 13,
				 text: "Dans quel chapitre du Livre de la Genèse, le nom d’Abram fut changé en Abraham ?",
      options: [
        { id: 0, text: "Genèse 12", isCorrect: false },
        { id: 1, text: "Genèse 16", isCorrect: false },
        { id: 2, text: "Genèse 17", isCorrect: true },
				],
			},
			
			{
				id: 14,
				text: "La bénédiction par laquelle Moïse, homme de Dieu, bénit les Israélites, avant sa mort ; se trouve en : ",
      options: [
        { id: 0, text: "Nombre 34", isCorrect: false },
        { id: 1, text: "Deutéronome 33", isCorrect: true },
        { id: 2, text: "Deutéronome 34", isCorrect: false },
				],
			},
			
			{
				id: 15,
				 text: "La mort de Moise sur le mont Nebo est relatée :",
      options: [
        { id: 0, text: "Deutéronome 34", isCorrect: true },
        { id: 1, text: "Nombre 34 ", isCorrect: false },
        { id: 2, text: "Lévitique 34", isCorrect: false },
				],
			},
			
			{
				id: 16,
				text: "Dans quel chapitre du Livre de la Genèse l’Eternel ordonna-t-il la circoncision comme signe de l’Alliance avec Abraham et ses descendants",
      options: [
        { id: 0, text: "Genèse 17 ;", isCorrect: true },
        { id: 1, text: "Genèse 18", isCorrect: false },
        { id: 2, text: "Genèse 19", isCorrect: false }, 
				],
			},
			
			{
				id: 17,
				 text: "Quand le peuple s’aperçut que Moise tardait de descendre de la montagne, qu’est-ce qui ne s’est passé ?",
      options: [
        { id: 0, text: "Le peuple obligea Aaron à leur fabriquer des dieux qui mrchent devant eux ", isCorrect: true },
        { id: 1, text: "Aaron leur dit : Défaites les anneaux d'or qui sont aux oreilles de vos femmes, de vos fils et de vos filles, et apportez-les moi.", isCorrect: false },
        { id: 2, text: "Il reçut l'or de leurs mains, le façonna avec le burin et fit un veau en métal fondu. Puis ils dirent : Israël ! Les voici tes dieux qui t'ont fait monter du pays d'Égypte.", isCorrect: false },
				],
			},
			
			{
				id: 18,
				 text: "La désignation de Betsaléel et des artisans du sanctuaire se trouve en :",
      options: [
        { id: 0, text: "Exode 31", isCorrect: true },
        { id: 1, text: "Lévitique 13", isCorrect: false },
        { id: 2, text: "Nombre 13", isCorrect: false },
				],
			},
			
			{
				id: 19,
				 text: "Combien d’hommes du peuple perdirent la vie quand Moïse ordonna aux Lévites : « Que chacun de vous mette son épée au côté ; traversez et parcourez le camp d'une porte à l'autre, et que chacun tue son frère, son ami, son parent. »",
      options: [
        { id: 0, text: "Environ 14 000 hommes", isCorrect: false  },
        { id: 1, text: "Environ 7 000 hommes", isCorrect: false },
        { id: 2, text: "Environ 3 000 hommes", isCorrect: true },
				],
			},
			
			{
				id: 20,
				text: "Où trouve-t-on ce texte biblique : « J'enverrai devant toi un ange et je chasserai les Cananéens, les Amoréens, les Hittites, les Phéréziens, les Héviens et les Yebousiens. (Monte) vers un pays découlant de lait et de miel. Mais je ne monterai pas au milieu de toi, de peur de t'exterminer en chemin, car tu es un peuple à la nuque raide. »",
      options: [
        { id: 0, text: "Exode 33 : 2 – 3", isCorrect: true },
        { id: 1, text: "Nombre 33 : 2 – 3", isCorrect: false },
        { id: 2, text: "Deutéronome 33 : 2 – 3", isCorrect: false },
				],
			},
			
			{
				id: 21,
				text: "L’arrivée des israélites au mont Sinaï s’est produite : ",
      options: [
        { id: 0, text: "Le troisième mois après leur départ d’Egypte", isCorrect: true },
        { id: 1, text: "Sept mois après leur départ d’Egypte", isCorrect: false },
        { id: 2, text: "La troisième année après leur départ d’Egypte", isCorrect: false },
				],
			},
			
			{
				id: 22,
				text: "Quelles sont les références de : « Moïse prit la tente et la dressa pour lui hors du camp, à quelque distance ; il l'appela tente de la Rencontre ; et quiconque voulait consulter l'Éternel sortait vers la tente de la Rencontre, qui était hors du camp.8 Lorsque Moïse sortait vers la tente, tout le peuple se levait, chacun se tenait à l'entrée de sa tente et suivait des yeux Moïse, jusqu'à ce qu'il soit entré dans la tente. »",
      options: [
        { id: 0, text: "Exode 23 : 7 – 8", isCorrect: false },
        { id: 1, text: "Exode 33 : 7 – 8", isCorrect: true },
        { id: 2, text: "Exode 40 : 7 – 8", isCorrect: false },
				],
			},
			
			{
				id: 23,
				text: "Où trouve-t-on ce texte biblique : « 32 Vos cadavres, à vous, tomberont dans ce désert ; 33 et vos fils seront nomades quarante années dans le désert et porteront le poids de vos infidélités, jusqu'à ce que vos cadavres soient tous tombés dans le désert. »",
      options: [
        { id: 0, text: "Exode 14 : 32 - 33", isCorrect: false },
        { id: 1, text: "Nombre 14 : 32 – 33", isCorrect: true },
        { id: 2, text: "Deutéronome 14 : 32 - 33", isCorrect: false },
				],
			},
			
			{
				id: 24,
				text: "Quelles sont les références de : « 18 Moïse dit : Fais-moi voir ta gloire ! 19 (L'Éternel) répondit : Je ferai passer devant ta face toute ma bonté et je proclamerai devant toi le nom de l'Éternel ; je fais grâce à qui je fais grâce, et j'ai compassion de qui j'ai compassion. »",
      options: [
        { id: 0, text: "Exode 3 : 18 – 19", isCorrect: false },
        { id: 1, text: "Exode 33 : 18 – 19", isCorrect: true },
        { id: 2, text: "Deutéronome 33 : 18 – 19", isCorrect: false },
				],
			},
			
			{
				id: 25,
				text: "La rébellion des israélites après le rapport des douze espions est rapportée en : ",
      options: [
        { id: 0, text: "Exode 14 : 1 - 9", isCorrect: false },
        { id: 1, text: "Nombre 14 : 1- 9", isCorrect: true },
        { id: 2, text: "Deutéronome 14 : 1 - 9", isCorrect: false },
				],
			},
			
			{
				id: 26,
				text: "Dans quel chapitre L'Éternel dit à Moïse : Taille deux tables de pierre comme les premières, et j'écrirai sur ces tables les paroles qui étaient sur les premières tables que tu as brisées. ? ",
      options: [
        { id: 0, text: "Exode 24", isCorrect: false },
        { id: 1, text: "Exode 34", isCorrect: true },
        { id: 2, text: "Deutéronome 34", isCorrect: false },			],
			},
			
			{
				id: 27,
				text: "Le premier recensement d’Israël est relaté en : ",
      options: [
        { id: 0, text: "Exode 21", isCorrect: false },
        { id: 1, text: "Levitique 21", isCorrect: false },
        { id: 2, text: "Nombre 1", isCorrect: true },
				],
			},
			
			{
				id: 28,
				text: "Où trouve-t-on ce texte biblique  : « 21 L'Éternel dit : Voici un endroit près de moi ; tu te tiendras sur le rocher. 22 Quand ma gloire passera, je te mettrai dans un creux du rocher et je te couvrirai de ma main jusqu'à ce que j'aie passé. »",
      options: [
        { id: 0, text: "Exode 4 : 21 - 22", isCorrect: false },
        { id: 1, text: "Exode 24 : 21 - 22", isCorrect: false },
        { id: 2, text: "Exode 33 : 21 - 22", isCorrect: true },
				],
			},
			
			{
				id: 29,
				 text: "L’histoire de l’appel d’Abraham est racontée en ",
      options: [
        { id: 0, text: "Genèse 8", isCorrect: false },
        { id: 1, text: "Genèse 10", isCorrect: false },
        { id: 2, text: "Genèse 12", isCorrect: true },
				],
			},
			
			{
				id: 30,
				text: "Où trouve-t-on ce texte biblique : « 35 Quand l'arche partait, Moïse disait : Lève-toi, Éternel ! et que tes ennemis soient dispersés ! Que ceux qui te haïssent fuient devant ta face ! 36 Et quand on la reposait, il disait : Reviens, Éternel, aux myriades des milliers d'Israël ! » ",
      options: [
        { id: 0, text: "Exode 12 : 35 – 36", isCorrect: false },
        { id: 1, text: "Lévitique 12 : 35 – 36", isCorrect: false },
        { id: 2, text: "Nombre 10 :35 – 36", isCorrect: true },
				],
			},
			
			{
				id: 31,
				text: "La révolte de Qoré est relatée en :",
      options: [
        { id: 0, text: "Nombre 14", isCorrect: false },
        { id: 1, text: "Nombre 16", isCorrect: true },
        { id: 2, text: "Nombre 24", isCorrect: false },
				],
			},
			
			{
				id: 32,
				text: "L’histoire de la tour de Babel se trouve en :",
      options: [
        { id: 0, text: "Genèse 10", isCorrect: false },
        { id: 1, text: "Genèse 11", isCorrect: true },
        { id: 2, text: "Genèse 12", isCorrect: false },
				],
			},
		
			{
				id: 33,
				text: "Où trouve-t-on ce texte biblique : « Abram crut en l'Éternel qui le lui compta comme justice. » ?",
      options: [
        { id: 0, text: "Genèse 12 : 6", isCorrect: false },
        { id: 1, text: "Genèse 15 : 6", isCorrect: true },
        { id: 2, text: "Genèse 35 : 6", isCorrect: false },
				],
			},
		
			{
				id: 34,
				text: "Où trouve-t-on ce texte biblique : « Maintenant, notre gosier est desséché : plus rien ! Nos yeux ne voient que de la manne. 7 La manne ressemblait à de la graine de coriandre et avait l'apparence du bdellium. » ",
      options: [
        { id: 0, text: "Exode 11 : 6 - 7  ", isCorrect: false },
        { id: 1, text: "Lévitique 11 : 6 – 7 ", isCorrect: false },
        { id: 2, text: "Nombre 11 : 6 – 7", isCorrect: true },
				],
			},
			
			{
				id: 35,
				text: "La contestation des israélites contre Moïse et Aaron à cause du manque d’eau est relatée en:",
      options: [
        { id: 0, text: "Nombre 20", isCorrect: true },
        { id: 1, text: "Nombre 22", isCorrect: false },
        { id: 2, text: "Nombre 24", isCorrect: false },
				],
			},
			
			{
				id: 36,
				text: "L’histoire de la construction de l’arche de Noé est racontée en:",
      options: [
        { id: 0, text: "Genèse 6 ", isCorrect: true },
        { id: 1, text: "Genèse 7 ", isCorrect: false },
        { id: 2, text: "Genèse 9 ", isCorrect: false },
				],
			},
			
			{
				id: 37,
				text: "L’histoire du roi d’Edom qui refuse le droit de passage à Israël, (Qadesh) se trouve en:",
      options: [
        { id: 0, text: "Exode 20 : 14 – 21", isCorrect: false },
        { id: 1, text: "Lévitique 20 : 14 - 21", isCorrect: false },
        { id: 2, text: "Nombre 20 : 14 -21", isCorrect: true },
				],
			},
			
			{
				id: 38,
				 text: "Où trouve-t-on ce texte biblique : « 1 L'Éternel dit à Moïse et à Aaron dans le pays d'Égypte : 2 Ce mois-ci sera pour vous le premier des mois ; il sera pour vous le premier des mois de l'année. » ?",
      options: [
        { id: 0, text: "Exode 4 : 1 – 2", isCorrect: false },
        { id: 1, text: "Exode 12 : 1 - 2", isCorrect: true },
        { id: 2, text: "Nombre 12 : 1 -2", isCorrect: false },
				],
			},
			
			{
				id: 39,
				text: "A qui a – t – il été dit : « 21 L'Éternel dit : Voici un endroit près de moi ; tu te tiendras sur le rocher. 22 Quand ma gloire passera, je te mettrai dans un creux du rocher et je te couvrirai de ma main jusqu'à ce que j'aie passé. »: ",
      options: [
        { id: 0, text: "Abraham", isCorrect: false },
        { id: 1, text: "Jacob", isCorrect: false },
        { id: 2, text: "Moïse", isCorrect: true },
				],
			},
			
			{
				id: 40,
				text: "La mort d’Aaron est relatée en : ",
      options: [
        { id: 0, text: "Nombre 20 : 22 – 29", isCorrect: true },
        { id: 1, text: "Nombre 26 :  22 – 29", isCorrect: false },
        { id: 2, text: "Nombre 36 : 22 – 29", isCorrect: false },
				],
			},


		]
	},

];

export { Jeux, GameMap };
