import React, { useEffect, useState } from "react";
import { Jeux, GameMap } from "../api/data";
import { auth, db } from "../firebase";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectOpenArticle } from "../features/articleSlice";
import { Toaster, toast } from "react-hot-toast";
import Unhappy from "../lotties/unhappy.json";
import Lottie from "lottie-react";
import ContinuousAudioPlayer from "../components/ContinuousAudioPlayer";
import Modal from "../components/Modal";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import GameStatus from "./GameStatus";

function GameGround() {
	const [loading, setLoading] = useState(false);
	const [isMainData, setMainData] = useState([]);

	const selectedArticle = useSelector(selectOpenArticle);

	useEffect(() => {
		setLoading(true);
		setMainData(Jeux.filter((element) => element.id === selectedArticle?.id));
		setLoading(false);
	}, []);

	console.log(isMainData);
	const variants = {
		hidden: { opacity: 0, scale: 0.8 },
		visible: { opacity: 1, scale: 1 },
	};

	//============================= game ====================================//
	const [score, setScore] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showResults, setShowResults] = useState(false);
	const [openQuestion, setOpenQuestion] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isValidated, setValidated] = useState(false);

	const closeModal = () => {
		setIsModalOpen(false);
		setShowResults(false);
		setCurrentQuestion(0);
		setScore(0);
	};

	const optionClicked = (isCorrect) => {
		// Increment the score
		if (isCorrect) {
			const refreshToast = toast.loading("Vérification...");
			toast.success("Bravo !", {
				id: refreshToast,
			});
			setScore(score + 1);
		} else {
			const refreshToast = toast.loading("Vérification...");
			toast.success("Hooo Nonn, Soyez attentif !", {
				id: refreshToast,
			});
		}

		if (currentQuestion + 1 < selectedArticle.questions.length) {
			setCurrentQuestion(currentQuestion + 1);
		} else {
			setValidated(true);
			setIsModalOpen(true);
			setShowResults(true);
		}
	};

	///reprise oprions

	const reprise = () => {
		setIsModalOpen(false);
		setShowResults(false);
		setCurrentQuestion(0);
		setScore(0);
	};

	
	//////// continuer le jeu  //////////////////////////////////

	const navigate = useNavigate();

	const moviesCollectionRef = collection(db, "gameboard");
	

	const continuer = async () => {
		try {
			if (score > 10) {
				await addDoc(moviesCollectionRef, {
					score: score,
					niveau: selectedArticle?.jeuPosition,
					titre: selectedArticle?.sectionTitle,
					userId: auth?.currentUser?.uid,
				});
				setScore(0);
				navigate("/gameBoard");
			}
		} catch (err) {
			console.error("Vous devez avoir internet puis ressayer d'enregistrer votre palmarès");
			navigate("/gameGround")
		}
	};

	return (
		<div>
			{loading && <p>Chargement .... </p>}
			<ContinuousAudioPlayer />
			<div className="flex flex-col">
				<div className="flex justify-center font-bold border border-white text-sky-800 text-base sm:text-3xl rounded-md py-2">
					Restez conscentrer pour les 1000 Questions Bibliques
				</div>
				<div className=" mt-2 flex flex-col justify-center border border-white text-xs sm:text-xl text-blue-900 rounded-md py-2">
					<p className="flex justify-between items-center px-4">
						<p className="flex items-center space-x-2">
							<p className=" font-bold text-black">Niveau:</p>
							<p className=" font-bold text-blue-800">
								{selectedArticle?.jeuPosition}
							</p>
						</p>
						<p className="flex items-center space-x-2">
							<p className=" font-bold text-black">Question N° </p>
							<p className="text-blue-700 font-bold">{currentQuestion + 1}</p>
						</p>
						<p className="flex items-center space-x-2">
							<p className=" font-bold text-black">Score:</p>
							<p className=" font-bold text-blue-800">
								{score}/{selectedArticle.questions.length}
							</p>
						</p>
					</p>
				</div>

				<div className=" mt-2 flex justify-between sm:border border-white rounded-md p-4 items-center mb-6  space-x-3">
					<p className="flex justify-center sm:w-1/3">
						<motion.img
							initial="hidden"
							animate="visible"
							variants={variants}
							transition={{ duration: 0.5 }}
							src={selectedArticle.sectionImgSrc}
							alt=""
							className=" rounded-md hidden sm:flex"
						/>{" "}
					</p>
					<motion.p
						initial="hidden"
						animate="visible"
						variants={variants}
						transition={{ duration: 0.5 }}
						className="flex flex-col  sm:w-2/3">
						<p>
							<img
								src={selectedArticle.sectionImgSrc}
								alt=""
								className=" rounded-md sm:hidden"
							/>{" "}
						</p>

						{openQuestion && (
							<>
								<div className="flex w-full p-4 rounded-md flex-col mb-4">
									<Toaster />

									{selectedArticle.questions &&
										selectedArticle.questions.map((question, index) => {
											return (
												<>
													{index === currentQuestion && (
														<>
															<motion.div
																initial={{ opacity: 0, y: 50 }}
																animate={{ opacity: 1, y: 0 }}
																transition={{ duration: 1 }}
																key={question.id}
																className=" flex flex-1  justify-between">
																<div className="flex flex-col flex-1 ml-4 justify-center ">
																	<p className="text-lg font-bold sm:text-2xl text-center mb-4">
																		{question.text}
																	</p>
																	<p className="flex justify-between px-4 ">
																		{question.options.map((option) => {
																			return (
																				<p
																					key={option.id}
																					onClick={() =>
																						optionClicked(option.isCorrect)
																					}
																					className="mt-1 text-[10px] mr-2 text-center sm:text-sm py-1 px-1 rounded-lg items-center justify-center shadow-md bg-white/80  cursor-pointer hover:scale-110 ease-in-out duration-200 w-fit">
																					{option.text}
																				</p>
																			);
																		})}
																	</p>
																</div>
															</motion.div>
														</>
													)}
												</>
											);
										})}
								</div>
							</>
						)}

						<Modal isOpen={isModalOpen} closeModal={closeModal}>
							{showResults && (
								<>
									{score < 10 ? (
										<>
											<div className="flex flex-col bg-slate-100 rounded-md shadow-lg p-2">
												<p className="text-center sm:text-xl font-bold">
													{" "}
													Votre score pour cette Session
												</p>
												<div className="flex text-sm sm:text-xl flex-col ml-6">
													<ul className="flex ">
														<li className="font-bold">Nombre de questions:</li>
														<li className="flex ml-4 font-bold text-red-600">
															{selectedArticle.questions.length}
														</li>
													</ul>
													<ul className="flex ">
														<li className="font-bold">
															Minimume réquis pour passer:
														</li>
														<li className="flex ml-4 font-bold text-green-600">
															30
														</li>
													</ul>
													<ul className="flex ">
														<li className="font-bold">
															Nombre de points obtenus:
														</li>
														<li className="flex ml-4 font-bold text-red-600">
															{score}
														</li>
													</ul>
												</div>
												<div className="flex flex-col items-center w-full">
													<p className="text-xl sm:text-2xl bg-orange-200 px-2 py-1 my-2 rounded-md">
														Désolé
													</p>
													<p className="text-xs sm:text-2xl text-justify text-blue-700 sm:text-md px-2">
														« Si tu te laisses abattre au jour de l'adversité,
														ta force est bien peu de chose. » (Proverbe 24 :
														10); donc persévère….
													</p>
												</div>
												<div className="flex relative justify-between px-4  items-center ">
													<div className=" justify-center right-30 ">
														<Lottie
															animationData={Unhappy}
															loop={true}
															className=" object-cover "
														/>
													</div>
													<div
														onClick={reprise}
														className=" bg-orange-300 px-4 py-2 cursor-pointer rounded-md shadow-md shadow-green-200">
														Reprendre
													</div>
												</div>
											</div>
										</>
									) : (
										<>
											<div className="flex flex-col bg-slate-100 rounded-md shadow-lg p-2 text-xs">
												<p className="text-center sm:text-xl font-bold">
													{" "}
													Votre score pour cette Session
												</p>
												{/*Winner Game status*/}
												<div onClick={continuer}>
													<GameStatus
														id={selectedArticle?.id}
														nombre={selectedArticle?.questions.length}
														niveau={selectedArticle?.jeuPosition}
														titre={selectedArticle?.sectionTitle}
														score={score}
														statut={isValidated}
													/>
												</div>
											</div>
										</>
									)}
								</>
							)}
						</Modal>
					</motion.p>
				</div>
			</div>
		</div>
	);
}

export default GameGround;
