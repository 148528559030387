import React from 'react'
import { TbBrandFacebook, TbBrandInstagram } from "react-icons/tb";

function Footer() {
  return (
    <div className='flex justify-between text-xs sm:text-sm border-t-2 pt-1 -mt-2 z-30'>
      <div className='flex'>Concept: M. Dessi KOFFI</div>
      
      <div className='flex space-x-3 '>
      <TbBrandFacebook />
      <TbBrandInstagram />
      </div>
      
      <div className='flex'>
        <p className='text-sm'>Dev: Gérard G.</p>
      </div>
    </div>
  )
}

export default Footer