import React from 'react'
import {motion} from 'framer-motion'

function Modal({ isOpen, closeModal, children }) {

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'block' : 'hidden'
      }`}
    >
      <div className="modal-overlay fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <motion.div 
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ duration: 0.5 }}

        className="modal-content py-4 text-left px-6">{children}</motion.div>
      </div>
    </div>
  )
}

export default Modal