import React from "react";
import bible from "../img/bible1.gif";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { selectArticle } from "../features/articleSlice";

function MyGame({ gameId, jeuName, jeuPosition, sectQuest, statutJeu }) {
	const variants = {
		hidden: { opacity: 0, scale: 0.8 },
		visible: { opacity: 1, scale: 1 },
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const openGameSection = () => {
		dispatch(
			selectArticle({
				gameId,
				jeuName,
				jeuPosition,
				sectQuest,
				statutJeu,
			})
		);
		
	};

	return (
		<motion.div
			initial="hidden"
			animate="visible"
			variants={variants}
			transition={{ duration: 0.5 }}
			onClick={openGameSection}
			className="flex items-center gap-x-2">
			<div>
				<img
					src={bible}
					alt=""
					className=" w-20 h-16 rounded-2xl shadow-md shadow-slate-100 p-1"
				/>
			</div>
			<div className=" text-xs">
				<p>{jeuName} </p>
				<p>{jeuPosition} </p>
			</div>
		</motion.div>
	);
}

export default MyGame;
